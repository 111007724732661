import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reviewService from "./reviewService";

const initialState = {
	review: null,
	currentReview: null,
	isReviewLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	aboutSliderCardMessage: ""
};

export const getReviewById = createAsyncThunk("review/getById", async (data, thunkAPI) => {
	try {
		return await reviewService.getReviewById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllReview = createAsyncThunk("review/get/all", async (_, thunkAPI) => {
	try {
		return await reviewService.getAllReview();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addReview = createAsyncThunk("review/add", async (data, thunkAPI) => {
	try {
		return await reviewService.createReview(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editReview = createAsyncThunk("review/edit", async (data, thunkAPI) => {
	try {
		const newData = await reviewService.updateReview(data);
		if (newData) {
			thunkAPI.dispatch(getAllReview());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeReview = createAsyncThunk("review/remove", async (data, thunkAPI) => {
	try {
		const newData = await reviewService.deleteReview(data);
		if (newData) {
			thunkAPI.dispatch(getAllReview());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteReviews = createAsyncThunk("review/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await reviewService.bulkDeleteReview(data);
		if (newData) {
			thunkAPI.dispatch(getAllReview());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "review",
	initialState,
	reducers: {
		resetReview: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addReview.pending, (state) => {
				state.isReviewLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addReview.fulfilled, (state, action) => {
				state.isReviewLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addReview.rejected, (state, action) => {
				state.isReviewLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editReview.pending, (state) => {
				state.isReviewLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editReview.fulfilled, (state, action) => {
				state.isReviewLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editReview.rejected, (state, action) => {
				state.isReviewLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getReviewById.pending, (state) => {
				state.isReviewLoading = true;
			})
			.addCase(getReviewById.fulfilled, (state, action) => {
				state.isReviewLoading = false;
				state.currentReview = action.payload.data;
			})
			.addCase(getReviewById.rejected, (state, action) => {
				state.isReviewLoading = false;
				state.isError = true;
			})
			.addCase(getAllReview.pending, (state) => {
				state.isReviewLoading = true;
			})
			.addCase(getAllReview.fulfilled, (state, action) => {
				state.isReviewLoading = false;
				state.review = action.payload.data;
				state.currentReview = null;
				state.successMessage = ""
			})
			.addCase(getAllReview.rejected, (state, action) => {
				state.isReviewLoading = false;
				state.isError = true;
			})
			.addCase(removeReview.pending, (state) => {
				state.isReviewLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeReview.fulfilled, (state, action) => {
				state.isReviewLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeReview.rejected, (state, action) => {
				state.isReviewLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteReviews.pending, (state) => {
				state.isReviewLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteReviews.fulfilled, (state, action) => {
				state.isReviewLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteReviews.rejected, (state, action) => {
				state.isReviewLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetReview } = adminSlice.actions;
export default adminSlice.reducer;
