import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import healthStepService from "./healthStepService";

const initialState = {
	healthStep: null,
	currentHealthStep: null,
	isHealthStepLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	healthStepMessage: ""
};

export const getHealthStepById = createAsyncThunk("healthstep/getById", async (data, thunkAPI) => {
	console.log('data', data);
	try {
		return await healthStepService.getHealthStepById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllHealthStep = createAsyncThunk("healthstep/get/all", async (_, thunkAPI) => {
	try {
		return await healthStepService.getAllHealthStep();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addHealthStep = createAsyncThunk("healthstep/add", async (data, thunkAPI) => {
	try {
		return await healthStepService.createHealthStep(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editHealthStep = createAsyncThunk("healthstep/edit", async (data, thunkAPI) => {
	try {
		const newData = await healthStepService.updateHealthStep(data);
		if (newData) {
			thunkAPI.dispatch(getAllHealthStep());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const removeHealthStep = createAsyncThunk("healthstep/remove", async (data, thunkAPI) => {
	try {
		const newData = await healthStepService.deleteHealthStep(data);
		if (newData) {
			thunkAPI.dispatch(getAllHealthStep());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteHealthSteps = createAsyncThunk("healthstep/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await healthStepService.bulkDeleteHealthStep(data);
		if (newData) {
			thunkAPI.dispatch(getAllHealthStep());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});



const adminSlice = createSlice({
	name: "health_step",
	initialState,
	reducers: {
		resetHealthStep: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addHealthStep.pending, (state) => {
				state.isHealthStepLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addHealthStep.fulfilled, (state, action) => {
				state.isHealthStepLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addHealthStep.rejected, (state, action) => {
				state.isHealthStepLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editHealthStep.pending, (state) => {
				state.isHealthStepLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editHealthStep.fulfilled, (state, action) => {
				state.isHealthStepLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editHealthStep.rejected, (state, action) => {
				state.isHealthStepLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getHealthStepById.pending, (state) => {
				state.isHealthStepLoading = true;
			})
			.addCase(getHealthStepById.fulfilled, (state, action) => {
				state.isHealthStepLoading = false;
				state.currentHealthStep = action.payload.data;
			})
			.addCase(getHealthStepById.rejected, (state, action) => {
				state.isHealthStepLoading = false;
				state.isError = true;
			})
			.addCase(getAllHealthStep.pending, (state) => {
				state.isHealthStepLoading = true;
			})
			.addCase(getAllHealthStep.fulfilled, (state, action) => {
				state.isHealthStepLoading = false;
				state.healthStep = action.payload.data;
				state.currentHealthStep = null;
				state.successMessage = ""
			})
			.addCase(getAllHealthStep.rejected, (state, action) => {
				state.isHealthStepLoading = false;
				state.isError = true;
			})
			.addCase(removeHealthStep.pending, (state) => {
				state.isHealthStepLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeHealthStep.fulfilled, (state, action) => {
				state.isHealthStepLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeHealthStep.rejected, (state, action) => {
				state.isHealthStepLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHealthSteps.pending, (state) => {
				state.isHealthStepLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHealthSteps.fulfilled, (state, action) => {
				state.isHealthStepLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteHealthSteps.rejected, (state, action) => {
				state.isHealthStepLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetHealthStep } = adminSlice.actions;
export default adminSlice.reducer;
