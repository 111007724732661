import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerReviewService from "./customerReviewService";

const initialState = {
	customersReviews: [],
	orgcustomers: [],
	currentCustomer: null,
	isCustomerReviewsLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
};

export const getCustomersReviews = createAsyncThunk("customers_review/get/all", async (_, thunkAPI) => {
	try {
		return await customerReviewService.getAllCustomerReviews();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const removeCustomersReviews = createAsyncThunk("customers_review/remove", async (data, thunkAPI) => {
	try {
		const newData = await customerReviewService.deleteCustomerReviews(data);

		if (newData) {
			thunkAPI.dispatch(getCustomersReviews());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteCustomersReviews = createAsyncThunk("customers_review/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await customerReviewService.bulkDeleteCustomerReview(data);
		if (newData) {
			thunkAPI.dispatch(getCustomersReviews());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "customer_review",
	initialState,
	reducers: {
		resetCustomerReview: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCustomersReviews.pending, (state) => {
				state.isCustomerReviewsLoading = true;
			})
			.addCase(getCustomersReviews.fulfilled, (state, action) => {
				state.isCustomerReviewsLoading = false;
				state.customersReviews = action.payload.data;
				state.successMessage = ""
			})
			.addCase(getCustomersReviews.rejected, (state, action) => {
				state.isCustomerReviewsLoading = false;
				state.isError = true;
			})
			.addCase(removeCustomersReviews.pending, (state) => {
				state.isCustomerReviewsLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeCustomersReviews.fulfilled, (state, action) => {
				state.isCustomerReviewsLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeCustomersReviews.rejected, (state, action) => {
				state.isCustomerReviewsLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteCustomersReviews.pending, (state) => {
				state.isCustomerReviewsLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteCustomersReviews.fulfilled, (state, action) => {
				state.isCustomerReviewsLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteCustomersReviews.rejected, (state, action) => {
				state.isCustomerReviewsLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetCustomerReview } = adminSlice.actions;
export default adminSlice.reducer;
