import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import faqService from "./faqService";

const initialState = {
  faqs: [],
  currentFaqs: null,
  isFAQLoading: false,
  successMessage: "",
  errorMessage: "",
  isError: false,
  isSuccess: false,
};

export const getFAQById = createAsyncThunk(
  "faqs/getById",
  async (data, thunkAPI) => {
    try {
      return await faqService.getFAQ(data);
    } catch (error) {
      const message = (error && error.response.data) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFaqs = createAsyncThunk("faqs/get/all", async (_, thunkAPI) => {
  try {
    return await faqService.getAllFaq();
  } catch (error) {
    const message = (error && error.response.data) || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const addFAQ = createAsyncThunk("faqs/add", async (data, thunkAPI) => {
  try {
    return await faqService.createFaq(data);
  } catch (error) {
    const message = (error && error.response.data) || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const editFAQ = createAsyncThunk("faqs/edit", async (data, thunkAPI) => {
  try {
    const newData = await faqService.updateFaq(data);

    if (newData) {
      thunkAPI.dispatch(getFaqs());
      return newData;
    } else {
      const message = "Something Went Wrong";

      return thunkAPI.rejectWithValue(message);
    }
  } catch (error) {
    const message = (error && error.response.data) || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const removeFAQ = createAsyncThunk(
  "faqs/remove",
  async (data, thunkAPI) => {
    try {
      const newData = await faqService.deleteFAQ(data);

      if (newData) {
        thunkAPI.dispatch(getFaqs());
        return newData;
      } else {
        const message = "Something Went Wrong";

        return thunkAPI.rejectWithValue(message);
      }
    } catch (error) {
      const message = (error && error.response.data) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bulkDeleteFAQs = createAsyncThunk("faqs/bulkRemove", async (data, thunkAPI) => {
  try {
    const newData = await faqService.bulkDeleteFAQ(data);
    if (newData) {
      thunkAPI.dispatch(getFaqs());
      return newData;
    } else {
      const message = "Something Went Wrong";
      return thunkAPI.rejectWithValue(message);
    }
  } catch (error) {
    const message = (error && error.response.data) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const adminSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    resetAdmin: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFAQ.pending, (state) => {
        state.isFAQLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(addFAQ.fulfilled, (state, action) => {
        state.isFAQLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.successMessage = action.payload.message;
      })
      .addCase(addFAQ.rejected, (state, action) => {
        state.isFAQLoading = false;
        state.errorMessage = "An Error Occured While Creating an Admin";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(editFAQ.pending, (state) => {
        state.isFAQLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(editFAQ.fulfilled, (state, action) => {
        state.isFAQLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.successMessage = action.payload.message;
      })
      .addCase(editFAQ.rejected, (state, action) => {
        state.isFAQLoading = false;
        state.errorMessage = "An Error Occured While Updating an Admin";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getFAQById.pending, (state) => {
        state.isFAQLoading = true;
      })
      .addCase(getFAQById.fulfilled, (state, action) => {
        state.isFAQLoading = false;
        state.currentFaqs = action.payload.faq;
      })
      .addCase(getFAQById.rejected, (state, action) => {
        state.isFAQLoading = false;
        state.isError = true;
      })
      .addCase(getFaqs.pending, (state) => {
        state.isFAQLoading = true;
      })
      .addCase(getFaqs.fulfilled, (state, action) => {
        state.isFAQLoading = false;
        state.faqs = action.payload.faq;
        state.currentFaqs = null;
        state.successMessage = "";
      })
      .addCase(getFaqs.rejected, (state, action) => {
        state.isFAQLoading = false;
        state.isError = true;
      })
      .addCase(removeFAQ.pending, (state) => {
        state.isFAQLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(removeFAQ.fulfilled, (state, action) => {
        state.isFAQLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.successMessage = action.payload.message;
      })
      .addCase(removeFAQ.rejected, (state, action) => {
        state.isFAQLoading = false;
        state.errorMessage = "An Error Occured While Removing an Admin";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(bulkDeleteFAQs.pending, (state) => {
        state.isFAQLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(bulkDeleteFAQs.fulfilled, (state, action) => {
        state.isFAQLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.successMessage = action.payload.message;
      })
      .addCase(bulkDeleteFAQs.rejected, (state, action) => {
        state.isFAQLoading = false;
        state.errorMessage = "An Error Occured While Removing an Admin";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { resetAdmin } = adminSlice.actions;
export default adminSlice.reducer;
