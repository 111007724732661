import axios from "../../../../utils/axios";

const createReview = async (data) => {
	const response = await axios.post(`/api/admin/landing/review-content`, data);
	return response.data;
};

const updateReview = async (data) => {
	const response = await axios.put(`/api/admin/landing/review-content/${data._id}`, data);
	return response.data;
};

const getAllReview = async () => {
	console.log('vv');
	const response = await axios.get(`/api/admin/landing/review-content`);
    console.log('ccc',response.data);
	return response.data;
};

const getReviewById = async (data) => {
	const response = await axios.get(`/api/admin/landing/review-content/${data.id}`);
	return response.data;
};
const deleteReview = async (data) => {
	const response = await axios.delete(`/api/admin/landing/review-content/${data.id}`);
	return response.data;
};

const bulkDeleteReview = async (data) => {
	const response = await axios.post(`/api/admin/landing/review-content`, data);
	return response.data;
};

const helpContentService = {
	createReview,
	updateReview,
	getAllReview,
	getReviewById,
	deleteReview,
	bulkDeleteReview
};

export default helpContentService;
