import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentCardService from "./ContentCardService";

const initialState = {
	aboutContentCard: null,
	currentAboutContentCard: null,
	isAboutContentCardLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	aboutContentCardMessage: ""
};

export const getAboutContentCardById = createAsyncThunk("aboutcontentcard/getById", async (data, thunkAPI) => {
	try {
		return await contentCardService.getContentCardById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllAboutContentCard = createAsyncThunk("aboutcontentcard/get/all", async (_, thunkAPI) => {
	try {
		return await contentCardService.getAllContentCard();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addAboutContentCard = createAsyncThunk("aboutcontentcard/add", async (data, thunkAPI) => {
	try {
		return await contentCardService.createContentCard(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editAboutContentCard = createAsyncThunk("aboutcontentcard/edit", async (data, thunkAPI) => {
	try {
		const newData = await contentCardService.updateContentCard(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutContentCard());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeAboutContentCard = createAsyncThunk("aboutcontentcard/remove", async (data, thunkAPI) => {
	try {
		const newData = await contentCardService.deleteContentCard(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutContentCard());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const bulkDeleteAboutContentCards = createAsyncThunk("aboutcontentcard/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await contentCardService.bulkDeleteContentCard(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutContentCard());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "about_content_card",
	initialState,
	reducers: {
		resetAboutContentCard: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addAboutContentCard.pending, (state) => {
				state.isAboutContentCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addAboutContentCard.fulfilled, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addAboutContentCard.rejected, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editAboutContentCard.pending, (state) => {
				state.isAboutContentCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editAboutContentCard.fulfilled, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editAboutContentCard.rejected, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getAboutContentCardById.pending, (state) => {
				state.isAboutContentCardLoading = true;
			})
			.addCase(getAboutContentCardById.fulfilled, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.currentAboutContentCard = action.payload.contentCard;
			})
			.addCase(getAboutContentCardById.rejected, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.isError = true;
			})
			.addCase(getAllAboutContentCard.pending, (state) => {
				state.isAboutContentCardLoading = true;
			})
			.addCase(getAllAboutContentCard.fulfilled, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.aboutContentCard = action.payload.contentCard;
				state.currentAboutContentCard = null;
				state.successMessage = ""
			})
			.addCase(getAllAboutContentCard.rejected, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.isError = true;
			})
			.addCase(removeAboutContentCard.pending, (state) => {
				state.isAboutContentCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeAboutContentCard.fulfilled, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeAboutContentCard.rejected, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutContentCards.pending, (state) => {
				state.isAboutContentCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutContentCards.fulfilled, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteAboutContentCards.rejected, (state, action) => {
				state.isAboutContentCardLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetAboutContentCard } = adminSlice.actions;
export default adminSlice.reducer;
