import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sliderCardService from "./SliderCardService";

const initialState = {
	aboutSliderCard: null,
	currentAboutSliderCard: null,
	isAboutSliderCardLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	aboutSliderCardMessage: ""
};

export const getAboutSliderCardById = createAsyncThunk("aboutslidercard/getById", async (data, thunkAPI) => {
	try {
		return await sliderCardService.getSliderCardById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllAboutSliderCard = createAsyncThunk("aboutslidercard/get/all", async (_, thunkAPI) => {
	try {
		return await sliderCardService.getAllSliderCard();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addAboutSliderCard = createAsyncThunk("aboutslidercard/add", async (data, thunkAPI) => {
	try {
		return await sliderCardService.createSliderCard(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editAboutSliderCard = createAsyncThunk("aboutslidercard/edit", async (data, thunkAPI) => {
	try {
		const newData = await sliderCardService.updateSliderCard(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutSliderCard());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeAboutSliderCard = createAsyncThunk("aboutcontentcard/remove", async (data, thunkAPI) => {
	try {
		const newData = await sliderCardService.deleteSliderCard(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutSliderCard());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteAboutSliderCards = createAsyncThunk("aboutcontentcard/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await sliderCardService.bulkDeleteSliderCard(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutSliderCard());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});



const adminSlice = createSlice({
	name: "about_slider_card",
	initialState,
	reducers: {
		resetAboutSliderCard: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addAboutSliderCard.pending, (state) => {
				state.isAboutSliderCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addAboutSliderCard.fulfilled, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addAboutSliderCard.rejected, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editAboutSliderCard.pending, (state) => {
				state.isAboutSliderCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editAboutSliderCard.fulfilled, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editAboutSliderCard.rejected, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getAboutSliderCardById.pending, (state) => {
				state.isAboutSliderCardLoading = true;
			})
			.addCase(getAboutSliderCardById.fulfilled, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.currentAboutSliderCard = action.payload.sliderCard;
			})
			.addCase(getAboutSliderCardById.rejected, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.isError = true;
			})
			.addCase(getAllAboutSliderCard.pending, (state) => {
				state.isAboutSliderCardLoading = true;
			})
			.addCase(getAllAboutSliderCard.fulfilled, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.aboutSliderCard = action.payload.sliderCard;
				state.currentAboutSliderCard = null;
				state.successMessage = ""
			})
			.addCase(getAllAboutSliderCard.rejected, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.isError = true;
			})
			.addCase(removeAboutSliderCard.pending, (state) => {
				state.isAboutSliderCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeAboutSliderCard.fulfilled, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeAboutSliderCard.rejected, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutSliderCards.pending, (state) => {
				state.isAboutSliderCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutSliderCards.fulfilled, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteAboutSliderCards.rejected, (state, action) => {
				state.isAboutSliderCardLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetAboutSliderCard } = adminSlice.actions;
export default adminSlice.reducer;
