import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import executiveMemberService from "./executiveMemberService";

const initialState = {
	executiveMember: null,
	currentAboutExecutiveMember: null,
	isAboutExecutiveMemberLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false
};

export const getAboutExecutiveMemberById = createAsyncThunk("aboutExecutiveMember/getById", async (data, thunkAPI) => {
	try {
		return await executiveMemberService.getExecutiveMemberById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllAboutExecutiveMember = createAsyncThunk("aboutExecutiveMember/get/all", async (_, thunkAPI) => {
	try {
		return await executiveMemberService.getAllExecutiveMember();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addAboutExecutiveMember = createAsyncThunk("aboutExecutiveMember/add", async (data, thunkAPI) => {
	try {
		return await executiveMemberService.createExecutiveMember(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editAboutExecutiveMemnber = createAsyncThunk("aboutExecutiveMember/edit", async (data, thunkAPI) => {
	try {
		const newData = await executiveMemberService.updateExecutiveMember(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutExecutiveMember());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeAboutExecutiveMemnber = createAsyncThunk("aboutExecutiveMember/remove", async (data, thunkAPI) => {
	try {
		const newData = await executiveMemberService.deleteExecutiveMember(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutExecutiveMember());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteAboutExecutiveMembers = createAsyncThunk("aboutExecutiveMember/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await executiveMemberService.bulkDeleteExecutiveMember(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutExecutiveMember());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "executive_member",
	initialState,
	reducers: {
		resetAbouteExecutiveMember: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addAboutExecutiveMember.pending, (state) => {
				state.isAboutExecutiveMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addAboutExecutiveMember.fulfilled, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addAboutExecutiveMember.rejected, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editAboutExecutiveMemnber.pending, (state) => {
				state.isAboutExecutiveMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editAboutExecutiveMemnber.fulfilled, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editAboutExecutiveMemnber.rejected, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getAboutExecutiveMemberById.pending, (state) => {
				state.isAboutExecutiveMemberLoading = true;
			})
			.addCase(getAboutExecutiveMemberById.fulfilled, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.currentAboutExecutiveMember = action.payload.executiveteamMember;
			})
			.addCase(getAboutExecutiveMemberById.rejected, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.isError = true;
			})
			.addCase(getAllAboutExecutiveMember.pending, (state) => {
				state.isAboutExecutiveMemberLoading = true;
			})
			.addCase(getAllAboutExecutiveMember.fulfilled, (state, action) => {
				console.log('action.payload', action.payload);
				state.isAboutExecutiveMemberLoading = false;
				state.executiveMember = action.payload.executiveTeamMember;
				state.currentAboutExecutiveMember = null;
				state.successMessage = ""
			})
			.addCase(getAllAboutExecutiveMember.rejected, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.isError = true;
			})
			.addCase(removeAboutExecutiveMemnber.pending, (state) => {
				state.isAboutExecutiveMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeAboutExecutiveMemnber.fulfilled, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeAboutExecutiveMemnber.rejected, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutExecutiveMembers.pending, (state) => {
				state.isAboutExecutiveMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutExecutiveMembers.fulfilled, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteAboutExecutiveMembers.rejected, (state, action) => {
				state.isAboutExecutiveMemberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetAbouteExecutiveMember } = adminSlice.actions;
export default adminSlice.reducer;
