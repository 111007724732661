import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import dashboardService from "./dashboardService";

const initialState = {
	dashboard: null,
	isDashboardLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
};

export const getAllDashboardContent = createAsyncThunk("dashboard/get/all", async (_, thunkAPI) => {
	try {
		return await dashboardService.getAllDashboardContent();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllOrgDashboardContent = createAsyncThunk("orgdashboard/get/all", async (data, thunkAPI) => {
	try {
		console.log('data',data);
		return await dashboardService.getOrgnizationAllDashboardContent(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "content_management",
	initialState,
	reducers: {
		resetContentManagement: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllDashboardContent.pending, (state) => {
				state.isDashboardLoading = true;
			})
			.addCase(getAllDashboardContent.fulfilled, (state, action) => {
				state.isDashboardLoading = false;
                state.dashboard = action.payload;
			})
			.addCase(getAllDashboardContent.rejected, (state, action) => {
				state.isDashboardLoading = false;
				state.isError = true;
			})
			.addCase(getAllOrgDashboardContent.pending, (state) => {
				state.isDashboardLoading = true;
			})
			.addCase(getAllOrgDashboardContent.fulfilled, (state, action) => {
				state.isDashboardLoading = false;
                state.dashboard = action.payload;
			})
			.addCase(getAllOrgDashboardContent.rejected, (state, action) => {
				state.isDashboardLoading = false;
				state.isError = true;
			});
	},
});

export const {resetDashboard} = adminSlice.actions;
export default adminSlice.reducer;
