import axios from "../../../utils/axios";

const createOrganization = async (data) => {
	const response = await axios.post(`/api/admin/organization/`, data);
	return response.data;
};

const updateOrganization = async (data) => {
	const response = await axios.put(`/api/admin/organization/${data._id}`, data);
	return response.data;
};


const OrderOrganization = async (data) => {
	const response = await axios.get(`/api/admin/org/${data.id}`, data);
	return response.data;
};


const updateOrganizationStatus = async (data) => {
	const response = await axios.put(`/api/admin/organization/status/${data._id}`, data);
	return response.data;
};

const getAllOrganization = async () => {
	const response = await axios.get(`/api/admin/organization/`);
    console.log(response.data);
	return response.data;
};

const getOrganization = async (data) => {
	const response = await axios.get(`/api/admin/organization/${data.id}`);
	return response.data;
};

const deleteOrganization = async (data) => {
	const response = await axios.delete(`/api/admin/organization/${data.id}`);
	return response.data;
};

const bulkDeleteOrganization = async (data) => {
	const response = await axios.post(`/api/admin/organization/bulkDelete`, data);
	return response.data;
};

const OrganizationService = {
	createOrganization,
	updateOrganization,
	getAllOrganization,
	getOrganization,
    updateOrganizationStatus,
	deleteOrganization,
	OrderOrganization,
	bulkDeleteOrganization
};

export default OrganizationService;
