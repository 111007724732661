import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "./productService";

const initialState = {
  products: null,
  currentProduct: null,
  isProductError: false,
  isProductSuccess: false,
  isProductLoading: false,
  productMessage: "",
  successMessage: ""
};

export const getAllProducts = createAsyncThunk(
  "product/getall",
  async (_, thunkAPI) => {
    try {
      return await productService.getProducts();
    } catch (error) {
      const message = (error && error.response.error) || error.toString();

      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProduct = createAsyncThunk(
  "product/getById",
  async (data, thunkAPI) => {
    try {
      return await productService.getProductById(data);
    } catch (error) {
      const message = (error && error.response.error) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createProduct = createAsyncThunk(
  "product/create",
  async (data, thunkAPI) => {
    try {
      return await productService.createProduct(data);
    } catch (error) {
      const message = (error && error.response.error) || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const removeProduct = createAsyncThunk("product/remove", async (data, thunkAPI) => {
  try {
    const newData = await productService.removeProduct(data);
    if (newData) {
      thunkAPI.dispatch(getAllProducts());
      return newData;
    } else {
      const message = "Something Went Wrong";
      return thunkAPI.rejectWithValue(message);
    }
  } catch (error) {
    const message = (error && error.response.data) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const bulkDeleteProducts = createAsyncThunk("product/bulkRemove", async (data, thunkAPI) => {
  try {
    const newData = await productService.bulkDeleteProduct(data);
    if (newData) {
      thunkAPI.dispatch(getAllProducts());
      return newData;
    } else {
      const message = "Something Went Wrong";
      return thunkAPI.rejectWithValue(message);
    }
  } catch (error) {
    const message = (error && error.response.data) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    resetProducts: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.isProductSuccess = true;
        state.currentProduct = null;
        state.products = action.payload;
        state.successMessage = ""
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.isProductLoading = false;
        state.isProductError = true;
        state.isProductSuccess = false;
        state.productMessage = action.payload;
      })
      .addCase(getProduct.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.isProductSuccess = true;
        state.currentProduct = action.payload;
        state.successMessage = ""
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.isProductLoading = false;
        state.isProductError = true;
        state.isProductSuccess = false;
        state.productMessage = action.payload;
      })
      .addCase(createProduct.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.isProductSuccess = true;
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.isProductLoading = false;
        state.isProductError = true;
        state.isProductSuccess = false;
      })
      .addCase(removeProduct.pending, (state) => {
        state.isProductLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(removeProduct.fulfilled, (state, action) => {
        console.log('action.payload.message',action.payload.message);
        state.isProductLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.successMessage = action.payload.message;
      })
      .addCase(removeProduct.rejected, (state, action) => {
        state.isProductLoading = false;
        state.errorMessage = "An Error Occured While Removing an Admin";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(bulkDeleteProducts.pending, (state) => {
        state.isProductLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(bulkDeleteProducts.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.successMessage = action.payload.message;
      })
      .addCase(bulkDeleteProducts.rejected, (state, action) => {
        state.isProductLoading = false;
        state.errorMessage = "An Error Occured While Removing an Admin";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { resetProducts } = productSlice.actions;
export default productSlice.reducer;
