import axios from "../../../../utils/axios";

const createFaqCategory = async (data) => {
	const response = await axios.post(`/api/faqCategory`, data);
	return response.data;
};

const updateFaqCategory = async (data) => {
	const response = await axios.put(`/api/faqCategory/${data._id}`, data);
	return response.data;
};

const getAllFaqCategory = async () => {
	const response = await axios.get(`/api/faqCategory`);
	return response.data;
};

const getFAQCategory = async (data) => {
	const response = await axios.get(`/api/faqCategory/${data.id}`);
	return response.data;
};

const deleteFAQCategory = async (data) => {
	const response = await axios.delete(`/api/faqCategory/${data.id}`);
	return response.data;
};

const bulkDeleteFAQCategory = async (data) => {
	const response = await axios.post(`/api/faqCategory/bulkDelete`, data);
	return response.data;
};

const faqCategoryService = {
	createFaqCategory,
	updateFaqCategory,
	getAllFaqCategory,
	getFAQCategory,
	deleteFAQCategory,
	bulkDeleteFAQCategory
};

export default faqCategoryService;
