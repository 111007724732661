import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gutWellnessStartService from "./gutWellnessStartService";

const initialState = {
	gutWellnessStart: null,
	currentGutWellnessStart: null,
	isAboutTeamMemberLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	gutWellnessStartMessage: ""
};

export const getGutWellnessStartById = createAsyncThunk("getGutWellnessStart/getById", async (data, thunkAPI) => {
	try {
		return await gutWellnessStartService.getGutWellnessStartById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllGutWellnessStart = createAsyncThunk("getGutWellnessStart/get/all", async (params, thunkAPI) => {
	try {
		return await gutWellnessStartService.getAllGutWellnessStart(params);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addGutWellnessStart = createAsyncThunk("getGutWellnessStart/add", async (data, thunkAPI) => {
	try {
		return await gutWellnessStartService.createGutWellnessStart(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editGutWellnessStart = createAsyncThunk("getGutWellnessStart/edit", async (data, thunkAPI) => {
	try {
		const newData = await gutWellnessStartService.updateGutWellnessStart(data);
		if (newData) {
			thunkAPI.dispatch(getAllGutWellnessStart());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const removGutWellnessStart = createAsyncThunk("getGutWellnessStart/remove", async (data, thunkAPI) => {
	try {
		const newData = await gutWellnessStartService.deleteGutWellnessStart(data);
		if (newData) {
			thunkAPI.dispatch(getAllGutWellnessStart());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteGutWellnessStarts = createAsyncThunk("getGutWellnessStart/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await gutWellnessStartService.bulkDeleteGutWellnessStart(data);
		if (newData) {
			thunkAPI.dispatch(getAllGutWellnessStart());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "gutWellness_start",
	initialState,
	reducers: {
		resetGutWellnessStart: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addGutWellnessStart.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addGutWellnessStart.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addGutWellnessStart.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editGutWellnessStart.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editGutWellnessStart.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editGutWellnessStart.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.errorMessage = "An Error Occured While Updating.";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getGutWellnessStartById.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
			})
			.addCase(getGutWellnessStartById.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.currentGutWellnessStart = action.payload.data;
			})
			.addCase(getGutWellnessStartById.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isError = true;
			})
			.addCase(getAllGutWellnessStart.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
			})
			.addCase(getAllGutWellnessStart.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.gutWellnessStart = action.payload.data;
				state.currentGutWellnessStart = null;
				state.successMessage = ""
			})
			.addCase(getAllGutWellnessStart.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isError = true;
			})
			.addCase(removGutWellnessStart.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removGutWellnessStart.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removGutWellnessStart.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteGutWellnessStarts.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteGutWellnessStarts.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteGutWellnessStarts.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetGutWellnessStart } = adminSlice.actions;
export default adminSlice.reducer;
