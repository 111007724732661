import axios from "../../../utils/axios";

const getOrders = async () => {
  const response = await axios.get(`/api/admin/orders`);
  return response.data.data;
};

const getOrganizationOrders = async (data) => {
  console.log('datadata', data);
  const response = await axios.get(`/api/admin/orders/org/${data}`);
  return response.data.data;
};

const getOrder = async (data) => {
  const response = await axios.get(`/api/admin/orders/${data.id}`);
  return response.data.order;
};

const updateOrderStatus = async (data) => {
  const response = await axios.put(
    `/api/admin/orders/updatestatus/${data.id}`,
    data.data
  );
  return response.data.data;
};

const updatePaymentStatus = async (data) => {
  console.log("ssserviceee", data);
  const response = await axios.post(
    `/api/admin/orders/updatepaymentstatus`,
    data
  );
  return response.data;
};

const updateKitStatus = async (data) => {
  const response = await axios.put(
    `/api/admin/orders/updatekitstatus/${data.id}`,
    data
  );
  return response.data.data;
};

const deleteOrder = async (data) => {
  const response = await axios.delete(`/api/admin/orders/${data.id}`);
  return response.data;
};

const bulkDeleteOrder = async (data) => {
  const response = await axios.post(`/api/admin/orders/bulkDelete`, data);
  return response.data;
};

const getUnReadOrders = async (data) => {
  const response = await axios.get(`/api/admin/orders/unread`);
  return response.data;
};

const getUnReadOrganizationOrders = async (data) => {
  const response = await axios.get(`/api/admin/orders/unread/${data.id}`);
  return response.data;
};

const orderService = {
  getOrders,
  updateOrderStatus,
  getOrder,
  updateKitStatus,
  updatePaymentStatus,
  deleteOrder,
  bulkDeleteOrder,
  getOrganizationOrders,
  getUnReadOrders,
  getUnReadOrganizationOrders
};

export default orderService;
