import axios from "../../../utils/axios";

const getAllEnquires = async () => {
	const response = await axios.get(`/api/admin/enquiries`);
	return response.data;
};

const getEnquiry = async (data) => {
	const response = await axios.get(`/api/admin/enquiries/${data.id}`);
	return response.data;
};

const getUnReadEnquiry = async (data) => {
	const response = await axios.get(`/api/admin/enquiries/unread`);
	return response.data;
};

const deleteEnquiry = async (data) => {
	const response = await axios.delete(`/api/admin/enquiries/${data.id}`);
	return response.data;
};

const updateEnquiry = async (data) => {
	const response = await axios.put(`/api/admin/enquiries/${data.id}`);
	return response.data;
};

const bulkDeleteEnquiry = async (data) => {
	const response = await axios.post(`/api/admin/enquiries/bulkDelete`, data);
	return response.data;
};

const enquiryService = {
	getAllEnquires,
	getEnquiry,
	getUnReadEnquiry,
	deleteEnquiry,
	updateEnquiry,
	bulkDeleteEnquiry
};

export default enquiryService;
