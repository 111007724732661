import axios from "../../../utils/axios";

const createBenefits = async (data) => {
	const response = await axios.post(`/api/admin/benefits`, data);
	return response.data;
};

const updateBenefits = async (data) => {
	const response = await axios.put(`/api/admin/benefits/${data._id}`, data);
	return response.data;
};

const getAllBenefits = async () => {
	const response = await axios.get(`/api/admin/benefits`);
	console.log(response.data);
	return response.data;
};

const getBenefitsById = async (data) => {
	const response = await axios.get(`/api/admin/benefits/${data.id}`);
	return response.data;
};

const deleteBenefit = async (data) => {
	const response = await axios.delete(`/api/admin/benefits/${data.id}`);
	return response.data;
};

const bulkDeleteBenefit = async (data) => {
	const response = await axios.post(`/api/admin/benefits/bulkDelete`, data);
	return response.data;
};

const benefitsService = {
	createBenefits,
	updateBenefits,
	getAllBenefits,
	getBenefitsById,
	deleteBenefit,
	bulkDeleteBenefit
};

export default benefitsService;
