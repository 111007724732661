import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subscriberService from "./subscriberService";

const initialState = {
	subscribers: [],
	readSubscribers: [],
	isSubscriberLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
};

export const getSubscribers = createAsyncThunk("subscribers/get/all", async (_, thunkAPI) => {
	try {
		return await subscriberService.getAllSubscribers();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const removeSubscribers = createAsyncThunk("subscriber/remove", async (data, thunkAPI) => {
	try {
		const newData = await subscriberService.deleteSubscriber(data);

		if (newData) {
			thunkAPI.dispatch(getSubscribers());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});
export const bulkDeleteSubscribers = createAsyncThunk("subscriber/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await subscriberService.bulkDeleteSubscriber(data);
		if (newData) {
			thunkAPI.dispatch(getSubscribers());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getUnReadSubscriber = createAsyncThunk("subscriber/get/unreadSubscriber", async (data, thunkAPI) => {
	try {
		const newData = await subscriberService.getUnReadSubscribers(data);
		return newData;
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const enquirySlice = createSlice({
	name: "subscriber",
	initialState,
	reducers: {
		resetAdmin: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSubscribers.pending, (state) => {
				state.isSubscriberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(getSubscribers.fulfilled, (state, action) => {
				state.isSubscriberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.subscribers = action.payload.data;
				state.successMessage = ""
			})
			.addCase(getSubscribers.rejected, (state, action) => {
				state.isSubscriberLoading = false;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(removeSubscribers.pending, (state) => {
				state.isSubscriberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeSubscribers.fulfilled, (state, action) => {
				state.isSubscriberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeSubscribers.rejected, (state, action) => {
				state.isSubscriberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteSubscribers.pending, (state) => {
				state.isSubscriberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteSubscribers.fulfilled, (state, action) => {
				state.isSubscriberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteSubscribers.rejected, (state, action) => {
				state.isSubscriberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getUnReadSubscriber.pending, (state) => {
				state.isSubscriberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(getUnReadSubscriber.fulfilled, (state, action) => {
				state.isSubscriberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.readSubscribers = action.payload.data;
			})
			.addCase(getUnReadSubscriber.rejected, (state, action) => {
				state.isSubscriberLoading = false;
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetAdmin } = enquirySlice.actions;
export default enquirySlice.reducer;
