import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bodMemberService from "./bodMemberService";

const initialState = {
	bodMember: null,
	currentAboutBODMember: null,
	isAboutBODMemberLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false
};

export const getAboutBODMemberById = createAsyncThunk("aboutBODMember/getById", async (data, thunkAPI) => {
	try {
		return await bodMemberService.getBODMemberById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllAboutBODMember = createAsyncThunk("aboutBODMember/get/all", async (_, thunkAPI) => {
	try {
		return await bodMemberService.getAllBODMember();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addAboutBODMember = createAsyncThunk("aboutBODMember/add", async (data, thunkAPI) => {
	try {
		return await bodMemberService.createBODMember(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editAboutBODMember = createAsyncThunk("aboutBODMember/edit", async (data, thunkAPI) => {
	try {
		const newData = await bodMemberService.updateBODMember(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutBODMember());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeAboutBODMember = createAsyncThunk("aboutBODMember/remove", async (data, thunkAPI) => {
	try {
		const newData = await bodMemberService.deleteBODMember(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutBODMember());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteAboutBODMembers = createAsyncThunk("aboutBODMember/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await bodMemberService.bulkDeleteBODMember(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutBODMember());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "bod_member",
	initialState,
	reducers: {
		resetAbouteBODMember: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addAboutBODMember.pending, (state) => {
				state.isAboutBODMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addAboutBODMember.fulfilled, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addAboutBODMember.rejected, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editAboutBODMember.pending, (state) => {
				state.isAboutBODMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editAboutBODMember.fulfilled, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editAboutBODMember.rejected, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getAboutBODMemberById.pending, (state) => {
				state.isAboutBODMemberLoading = true;
			})
			.addCase(getAboutBODMemberById.fulfilled, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.currentAboutBODMember = action.payload.bodTeamMember;
			})
			.addCase(getAboutBODMemberById.rejected, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.isError = true;
			})
			.addCase(getAllAboutBODMember.pending, (state) => {
				state.isAboutBODMemberLoading = true;
			})
			.addCase(getAllAboutBODMember.fulfilled, (state, action) => {
				console.log('action.payload', action.payload);
				state.isAboutBODMemberLoading = false;
				state.bodMember = action.payload.bodTeamMember;
				state.currentAboutBODMember = null;
				state.successMessage = ""
			})
			.addCase(getAllAboutBODMember.rejected, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.isError = true;
			})
			.addCase(removeAboutBODMember.pending, (state) => {
				state.isAboutBODMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeAboutBODMember.fulfilled, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeAboutBODMember.rejected, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutBODMembers.pending, (state) => {
				state.isAboutBODMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutBODMembers.fulfilled, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteAboutBODMembers.rejected, (state, action) => {
				state.isAboutBODMemberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetAbouteBODMember } = adminSlice.actions;
export default adminSlice.reducer;
