import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentManagementService from "./contentManagementService";

const initialState = {
	contentmanagement: null,
	currentContentManagement: null,
	isContentManagementLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	contentManagementMessage: ""
};

export const getContentManagementById = createAsyncThunk("contentmanagement/getById", async (data, thunkAPI) => {
	try {
		return await contentManagementService.getContentManagement(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllContentManagement = createAsyncThunk("contentmanagement/get/all", async (_, thunkAPI) => {
	try {
		return await contentManagementService.getAllContentManagement();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addContentManagement = createAsyncThunk("contentmanagement/add", async (data, thunkAPI) => {
	try {
		return await contentManagementService.createContentManagement(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editContentManagement = createAsyncThunk("contentmanagement/edit", async (data, thunkAPI) => {
	try {
		const newData = await contentManagementService.updateContentManagement(data);
		if (newData) {
			thunkAPI.dispatch(getAllContentManagement());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeContentManagement = createAsyncThunk("contentmanagement/remove", async (data, thunkAPI) => {
	try {
		const newData = await contentManagementService.deleteContentManagement(data);
		if (newData) {
			thunkAPI.dispatch(getAllContentManagement());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteContentManagements = createAsyncThunk("contentmanagement/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await contentManagementService.bulkDeleteContentManagement(data);
		if (newData) {
			thunkAPI.dispatch(getAllContentManagement());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});



const adminSlice = createSlice({
	name: "content_management",
	initialState,
	reducers: {
		resetContentManagement: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addContentManagement.pending, (state) => {
				state.isContentManagementLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addContentManagement.fulfilled, (state, action) => {
				state.isContentManagementLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(addContentManagement.rejected, (state, action) => {
				state.isContentManagementLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editContentManagement.pending, (state) => {
				state.isContentManagementLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editContentManagement.fulfilled, (state, action) => {
				state.isContentManagementLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(editContentManagement.rejected, (state, action) => {
				state.isContentManagementLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getContentManagementById.pending, (state) => {
				state.isContentManagementLoading = true;
			})
			.addCase(getContentManagementById.fulfilled, (state, action) => {
				state.isContentManagementLoading = false;
				state.currentContentManagement = action.payload.contentManagement;
			})
			.addCase(getContentManagementById.rejected, (state, action) => {
				state.isContentManagementLoading = false;
				state.isError = true;
			})
			.addCase(getAllContentManagement.pending, (state) => {
				state.isContentManagementLoading = true;
			})
			.addCase(getAllContentManagement.fulfilled, (state, action) => {
				state.isContentManagementLoading = false;
				state.contentmanagement = action.payload.contentManagement;
				state.currentContentManagement = null;
				state.successMessage = ''
			})
			.addCase(getAllContentManagement.rejected, (state, action) => {
				state.isContentManagementLoading = false;
				state.isError = true;
			})
			.addCase(removeContentManagement.pending, (state) => {
				state.isContentManagementLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeContentManagement.fulfilled, (state, action) => {
				state.isContentManagementLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeContentManagement.rejected, (state, action) => {
				state.isContentManagementLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteContentManagements.pending, (state) => {
				state.isContentManagementLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteContentManagements.fulfilled, (state, action) => {
				state.isContentManagementLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteContentManagements.rejected, (state, action) => {
				state.isContentManagementLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetContentManagement } = adminSlice.actions;
export default adminSlice.reducer;
