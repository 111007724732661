import axios from "../../../../utils/axios";

const createKitContent = async (data) => {
	const response = await axios.post(`/api/admin/landing/product-content`, data);
	return response.data;
};

const updateKitContent = async (data) => {
	const response = await axios.put(`/api/admin/landing/product-content/${data._id}`, data);
	return response.data;
};

const getAllKitContent = async () => {
	const response = await axios.get(`/api/admin/landing/product-content`);
	console.log(response.data);
	return response.data;
};

const getKitContentById = async (data) => {
	const response = await axios.get(`/api/admin/landing/product-content/${data.id}`);
	return response.data;
};

const deleteKitContent = async (data) => {
	const response = await axios.delete(`/api/admin/landing/product-content/${data.id}`);
	return response.data;
};

const bulkDeleteKitContent = async (data) => {
	const response = await axios.post(`/api/admin/landing/product-content`, data);
	return response.data;
};

const aboutContentCardService = {
	createKitContent,
	updateKitContent,
	getAllKitContent,
	getKitContentById,
	deleteKitContent,
	bulkDeleteKitContent
};

export default aboutContentCardService;
