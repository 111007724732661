import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import homeContentCardService from "./HomeContentCardService";

const initialState = {
	homeContentCard: null,
	currentHomeContentCard: null,
	isHomeContentCardLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	homeContentCardMessage: ""
};

export const getHomeContentCardById = createAsyncThunk("homecontentcard/getById", async (data, thunkAPI) => {
	try {
		return await homeContentCardService.getHomeContentCardById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllHomeContentCard = createAsyncThunk("homecontentcard/get/all", async (_, thunkAPI) => {
	try {
		return await homeContentCardService.getAllHomeContentCard();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addHomeContentCard = createAsyncThunk("homecontentcard/add", async (data, thunkAPI) => {
	try {
		return await homeContentCardService.createHomeContentCard(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editHomeContentCard = createAsyncThunk("homecontentcard/edit", async (data, thunkAPI) => {
	try {
		const newData = await homeContentCardService.updateHomeContentCard(data);
		if (newData) {
			thunkAPI.dispatch(getAllHomeContentCard());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeHomeContentCard = createAsyncThunk("homecontentcard/remove", async (data, thunkAPI) => {
	try {
		const newData = await homeContentCardService.deleteHomeContentCard(data);
		if (newData) {
			thunkAPI.dispatch(getAllHomeContentCard());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteHomeContentCards = createAsyncThunk("homecontentcard/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await homeContentCardService.bulkDeleteHomeContentCard(data);
		if (newData) {
			thunkAPI.dispatch(getAllHomeContentCard());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "home_content_card",
	initialState,
	reducers: {
		resetHomeContentCard: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addHomeContentCard.pending, (state) => {
				state.isHomeContentCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addHomeContentCard.fulfilled, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addHomeContentCard.rejected, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editHomeContentCard.pending, (state) => {
				state.isHomeContentCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editHomeContentCard.fulfilled, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editHomeContentCard.rejected, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getHomeContentCardById.pending, (state) => {
				state.isHomeContentCardLoading = true;
			})
			.addCase(getHomeContentCardById.fulfilled, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.currentHomeContentCard = action.payload.homeContentCard;
			})
			.addCase(getHomeContentCardById.rejected, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.isError = true;
			})
			.addCase(getAllHomeContentCard.pending, (state) => {
				state.isHomeContentCardLoading = true;
			})
			.addCase(getAllHomeContentCard.fulfilled, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.homeContentCard = action.payload.homeContentCard;
				state.currentHomeContentCard = null;
				state.successMessage = ""
			})
			.addCase(getAllHomeContentCard.rejected, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.isError = true;
			})
			.addCase(removeHomeContentCard.pending, (state) => {
				state.isHomeContentCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeHomeContentCard.fulfilled, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeHomeContentCard.rejected, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHomeContentCards.pending, (state) => {
				state.isHomeContentCardLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHomeContentCards.fulfilled, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteHomeContentCards.rejected, (state, action) => {
				state.isHomeContentCardLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetHomeContentCard } = adminSlice.actions;
export default adminSlice.reducer;
