import axios from "../../../utils/axios";

const createContactUs = async (data) => {
	const response = await axios.post(`/api/admin/contactUs`, data);
	return response.data;
};

const updateContactUs = async (data) => {
	const response = await axios.put(`/api/admin/contactUs/${data._id}`, data);
	return response.data;
};

const getAllContactUs = async () => {
	const response = await axios.get(`/api/admin/contactUs`);
    console.log(response.data);
	return response.data;
};

const getContactUsById = async (data) => {
	const response = await axios.get(`/api/admin/contactUs/${data.id}`);
	return response.data;
};

const deleteContactUs = async (data) => {
	const response = await axios.delete(`/api/admin/contactUs/${data.id}`);
	return response.data;
};

const contactUsService = {
	createContactUs,
	updateContactUs,
	getAllContactUs,
	getContactUsById,
	deleteContactUs
};

export default contactUsService;
