import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import teamMemberService from "./ourTeamService";

const initialState = {
	teamMember: null,
	currentAboutTeamMember: null,
	isAboutTeamMemberLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	teamMemberMessage: ""
};

export const getAboutTeamMemberById = createAsyncThunk("aboutTeamMember/getById", async (data, thunkAPI) => {
	try {
		return await teamMemberService.getTeamMemberById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllAboutTeamMember = createAsyncThunk("aboutTeamMember/get/all", async (_, thunkAPI) => {
	try {
		return await teamMemberService.getAllTeamMember();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addAboutTeamMember = createAsyncThunk("aboutTeamMember/add", async (data, thunkAPI) => {
	try {
		return await teamMemberService.createTeamMember(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editAboutTeamMemnber = createAsyncThunk("aboutTeamMember/edit", async (data, thunkAPI) => {
	try {
		const newData = await teamMemberService.updateTeamMember(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutTeamMember());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const removeAboutTeamMemnber = createAsyncThunk("aboutTeamMember/remove", async (data, thunkAPI) => {
	try {
		const newData = await teamMemberService.deleteTeamMember(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutTeamMember());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteAboutTeamMembers = createAsyncThunk("aboutTeamMember/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await teamMemberService.bulkDeleteTeamMember(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutTeamMember());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "team_member",
	initialState,
	reducers: {
		resetAboutTeamMember: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addAboutTeamMember.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addAboutTeamMember.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addAboutTeamMember.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editAboutTeamMemnber.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editAboutTeamMemnber.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editAboutTeamMemnber.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getAboutTeamMemberById.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
			})
			.addCase(getAboutTeamMemberById.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.currentAboutTeamMember = action.payload.member;
			})
			.addCase(getAboutTeamMemberById.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isError = true;
			})
			.addCase(getAllAboutTeamMember.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
			})
			.addCase(getAllAboutTeamMember.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.teamMember = action.payload.member;
				state.currentAboutTeamMember = null;
				state.successMessage = ""
			})
			.addCase(getAllAboutTeamMember.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isError = true;
			})
			.addCase(removeAboutTeamMemnber.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeAboutTeamMemnber.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeAboutTeamMemnber.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutTeamMembers.pending, (state) => {
				state.isAboutTeamMemberLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutTeamMembers.fulfilled, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteAboutTeamMembers.rejected, (state, action) => {
				state.isAboutTeamMemberLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetAboutTeamMember } = adminSlice.actions;
export default adminSlice.reducer;
