import axios from "../../../utils/axios";

const createPaymentGateway = async (data) => {
	const response = await axios.post(`/api/admin/paymentgateway/`, data);
	return response.data;
};

const updatePaymentGateway = async (data) => {
	const response = await axios.put(`/api/admin/paymentgateway/${data._id}`, data);
	return response.data;
};

const updatePaymentGatewayStatus = async (data) => {
	const response = await axios.put(`/api/admin/paymentgateway/status/${data._id}`, data);
	return response.data;
};

const getAllPaymentGateway = async () => {
	const response = await axios.get(`/api/admin/paymentgateway/`);
    console.log(response.data);
	return response.data;
};

const getPaymentGateway = async (data) => {
	const response = await axios.get(`/api/admin/paymentgateway/${data.id}`);
	return response.data;
};

const removePaymentGateway = async (data) => {
	const response = await axios.delete(`/api/admin/paymentgateway/${data.id}`);
	return response.data;
};

const bulkDeletePaymentGateway = async (data) => {
	const response = await axios.post(`/api/admin/paymentgateway/bulkDelete`, data);
	return response.data;
};

const paymentGatewayService = {
	createPaymentGateway,
	updatePaymentGateway,
	getAllPaymentGateway,
	getPaymentGateway,
    updatePaymentGatewayStatus,
	removePaymentGateway,
	bulkDeletePaymentGateway
};

export default paymentGatewayService;
