import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BlogService from "./blogService";

const initialState = {
	blog: null,
	currentBlog: null,
	isBlogLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	BlogMessage: ""
};

export const getBlogById = createAsyncThunk("blog/getById", async (data, thunkAPI) => {
	try {
		return await BlogService.getBlog(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllBlog = createAsyncThunk("blog/get/all", async (_, thunkAPI) => {
	try {
		return await BlogService.getAllBlog();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addBlog = createAsyncThunk("blog/add", async (data, thunkAPI) => {
	try {
		return await BlogService.createBlog(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editBlog = createAsyncThunk("blog/edit", async (data, thunkAPI) => {
	try {
		const newData = await BlogService.updateBlog(data);
		if (newData) {
			thunkAPI.dispatch(getAllBlog());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const editBlogStatus = createAsyncThunk("Blog/status", async (data, thunkAPI) => {
	try {
		const newData = await BlogService.updateBlogStatus(data);
		if (newData) {
			thunkAPI.dispatch(getAllBlog());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const removeBlog = createAsyncThunk("blog/remove", async (data, thunkAPI) => {
	try {
		const newData = await BlogService.deleteBlog(data);
		if (newData) {
			thunkAPI.dispatch(getAllBlog());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteBlogs = createAsyncThunk("blog/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await BlogService.bulkDeleteBlog(data);
		if (newData) {
			thunkAPI.dispatch(getAllBlog());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "blog",
	initialState,
	reducers: {
		resetBlog: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addBlog.pending, (state) => {
				state.isBlogLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addBlog.fulfilled, (state, action) => {
				state.isBlogLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(addBlog.rejected, (state, action) => {
				state.isBlogLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editBlog.pending, (state) => {
				state.isBlogLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editBlog.fulfilled, (state, action) => {
				state.isBlogLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(editBlog.rejected, (state, action) => {
				state.isBlogLoading = false;
				state.errorMessage = "An Error Occured While Updating an Blog ";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getBlogById.pending, (state) => {
				state.isBlogLoading = true;
			})
			.addCase(getBlogById.fulfilled, (state, action) => {
				state.isBlogLoading = false;
				state.currentBlog = action.payload.blog;
			})
			.addCase(getBlogById.rejected, (state, action) => {
				state.isBlogLoading = false;
				state.isError = true;
			})
			.addCase(getAllBlog.pending, (state) => {
				state.isBlogLoading = true;
			})
			.addCase(getAllBlog.fulfilled, (state, action) => {
				state.isBlogLoading = false;
				state.blog = action.payload.blog;
				state.currentBlog = null;
				state.successMessage = ""
			})
			.addCase(getAllBlog.rejected, (state, action) => {
				state.isBlogLoading = false;
				state.isError = true;
			}).addCase(editBlogStatus.pending, (state) => {
				state.isBlogLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editBlogStatus.fulfilled, (state, action) => {
				state.isBlogLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(editBlogStatus.rejected, (state, action) => {
				state.isBlogLoading = false;
				state.errorMessage = "An Error Occured While Updating an Blog ";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(removeBlog.pending, (state) => {
				state.isBlogLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeBlog.fulfilled, (state, action) => {
				state.isBlogLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeBlog.rejected, (state, action) => {
				state.isBlogLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteBlogs.pending, (state) => {
				state.isBlogLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteBlogs.fulfilled, (state, action) => {
				state.isBlogLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteBlogs.rejected, (state, action) => {
				state.isBlogLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetBlog } = adminSlice.actions;
export default adminSlice.reducer;
