import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import medicalAdvisorService from "./medicalAdvisorService";

const initialState = {
	medicalAdvisor: null,
	currentMedicalAdvisor: null,
	isMedicalAdvisorLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	medicalAdvisorMessage: ""
};

export const getAboutMedicalAdvisorById = createAsyncThunk("medicalAdvisor/getById", async (data, thunkAPI) => {
	console.log('data', data);
	try {
		return await medicalAdvisorService.getMedicalAdvisorById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllAboutMedicalAdvisor = createAsyncThunk("medicalAdvisor/get/all", async (_, thunkAPI) => {
	try {
		return await medicalAdvisorService.getAllMedicalAdvisor();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addAboutMedicalAdvisor = createAsyncThunk("medicalAdvisor/add", async (data, thunkAPI) => {
	try {
		return await medicalAdvisorService.createMedicalAdvisor(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editAboutMedicalAdvisor = createAsyncThunk("medicalAdvisor/edit", async (data, thunkAPI) => {
	try {
		const newData = await medicalAdvisorService.updateMedicalAdvisor(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutMedicalAdvisor());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeAboutMedicalAdvisor = createAsyncThunk("medicalAdvisor/remove", async (data, thunkAPI) => {
	try {
		const newData = await medicalAdvisorService.deleteMedicalAdvisor(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutMedicalAdvisor());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteAboutMedicalAdvisors = createAsyncThunk("medicalAdvisor/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await medicalAdvisorService.bulkDeleteMedicalAdvisor(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutMedicalAdvisor());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});



const adminSlice = createSlice({
	name: "medical_advisor",
	initialState,
	reducers: {
		resetAboutMedicalAdvisor: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addAboutMedicalAdvisor.pending, (state) => {
				state.isMedicalAdvisorLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addAboutMedicalAdvisor.fulfilled, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addAboutMedicalAdvisor.rejected, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editAboutMedicalAdvisor.pending, (state) => {
				state.isMedicalAdvisorLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editAboutMedicalAdvisor.fulfilled, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editAboutMedicalAdvisor.rejected, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getAboutMedicalAdvisorById.pending, (state) => {
				state.isMedicalAdvisorLoading = true;
			})
			.addCase(getAboutMedicalAdvisorById.fulfilled, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.currentMedicalAdvisor = action.payload.medicalAdvisor;
			})
			.addCase(getAboutMedicalAdvisorById.rejected, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.isError = true;
			})
			.addCase(getAllAboutMedicalAdvisor.pending, (state) => {
				state.isMedicalAdvisorLoading = true;
			})
			.addCase(getAllAboutMedicalAdvisor.fulfilled, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.medicalAdvisor = action.payload.medicalAdvisor;
				state.currentMedicalAdvisor = null;
				state.successMessage = ""
			})
			.addCase(getAllAboutMedicalAdvisor.rejected, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.isError = true;
			})
			.addCase(removeAboutMedicalAdvisor.pending, (state) => {
				state.isMedicalAdvisorLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeAboutMedicalAdvisor.fulfilled, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeAboutMedicalAdvisor.rejected, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutMedicalAdvisors.pending, (state) => {
				state.isMedicalAdvisorLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAboutMedicalAdvisors.fulfilled, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteAboutMedicalAdvisors.rejected, (state, action) => {
				state.isMedicalAdvisorLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetAboutMedicalAdvisor } = adminSlice.actions;
export default adminSlice.reducer;
