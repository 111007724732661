import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BlogCategoryService from "./blogCategoryService";

const initialState = {
	blogCategory: null,
	currentBlogCategory: null,
	isBlogCategoryLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	BlogMessage: ""
};

export const getBlogCategoryById = createAsyncThunk("blogCategory/getById", async (data, thunkAPI) => {
	try {
		return await BlogCategoryService.getCategoryBlogById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllCategoriesBlog = createAsyncThunk("blogCategory/get/all", async (_, thunkAPI) => {
	try {
		return await BlogCategoryService.getAllCategoryBlogs();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const addCategoryBlog = createAsyncThunk("blogCategory/add", async (data, thunkAPI) => {
	try {
		return await BlogCategoryService.createCategoryBlog(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editCategoryBlog = createAsyncThunk("blogCategory/edit", async (data, thunkAPI) => {
	try {
		const newData = await BlogCategoryService.updateCategoryBlog(data);
		if (newData) {
			thunkAPI.dispatch(getAllCategoriesBlog());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});



export const removeCategoryBlog = createAsyncThunk("blogCategory/remove", async (data, thunkAPI) => {
	try {
		const newData = await BlogCategoryService.deleteCategoryBlog(data);
		if (newData) {
			thunkAPI.dispatch(getAllCategoriesBlog());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteCategoryBlogs = createAsyncThunk("blogCategory/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await BlogCategoryService.bulkDeleteCategoryBlog(data);
		if (newData) {
			thunkAPI.dispatch(getAllCategoriesBlog());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "blog_category",
	initialState,
	reducers: {
		resetCategoryBlog: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addCategoryBlog.pending, (state) => {
				state.isBlogCategoryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addCategoryBlog.fulfilled, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(addCategoryBlog.rejected, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editCategoryBlog.pending, (state) => {
				state.isBlogCategoryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editCategoryBlog.fulfilled, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(editCategoryBlog.rejected, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.errorMessage = "An Error Occured While Updating an Blog ";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getBlogCategoryById.pending, (state) => {
				state.isBlogCategoryLoading = true;
			})
			.addCase(getBlogCategoryById.fulfilled, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.currentBlogCategory = action.payload.blogCategory;
			})
			.addCase(getBlogCategoryById.rejected, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.isError = true;
			})
			.addCase(getAllCategoriesBlog.pending, (state) => {
				state.isBlogCategoryLoading = true;
			})
			.addCase(getAllCategoriesBlog.fulfilled, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.blogCategory = action.payload.blogCategory;
				state.currentBlogCategory = null;
				state.successMessage = ""
			})
			.addCase(getAllCategoriesBlog.rejected, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.isError = true;
			})
			.addCase(removeCategoryBlog.pending, (state) => {
				state.isBlogCategoryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeCategoryBlog.fulfilled, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeCategoryBlog.rejected, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteCategoryBlogs.pending, (state) => {
				state.isBlogCategoryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteCategoryBlogs.fulfilled, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteCategoryBlogs.rejected, (state, action) => {
				state.isBlogCategoryLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetCategoryBlog } = adminSlice.actions;
export default adminSlice.reducer;
