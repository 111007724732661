import axios from "../../../../utils/axios";

const createHelpContentSlider = async (data) => {
	const response = await axios.post(`/api/admin/landing/help-content-card`, data);
	return response.data;
};

const updateHelpContentSlider = async (data) => {
	const response = await axios.put(`/api/admin/landing/help-content-card/${data._id}`, data);
	return response.data;
};

const getAllHelpContentSlider = async () => {
	console.log('vv');
	const response = await axios.get(`/api/admin/landing/help-content-card`);
	console.log('ccc', response.data);
	return response.data;
};

const getHelpContentByIdSlider = async (data) => {
	const response = await axios.get(`/api/admin/landing/help-content-card/${data.id}`);
	return response.data;
};

const deleteHelpContentSlider = async (data) => {
	const response = await axios.delete(`/api/admin/landing/help-content-card/${data.id}`);
	return response.data;
};

const bulkDeleteHelpContentSlider = async (data) => {
	const response = await axios.post(`/api/admin/landing/help-content-card`, data);
	return response.data;
};

const helpContentSliderService = {
	createHelpContentSlider,
	updateHelpContentSlider,
	getAllHelpContentSlider,
	getHelpContentByIdSlider,
	deleteHelpContentSlider,
	bulkDeleteHelpContentSlider
};

export default helpContentSliderService;
