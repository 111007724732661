import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import scientificAdvisorService from "./scientificAdvisorService";

const initialState = {
	scientificAdvisor: null,
	currentScientificAdvisor: null,
	isScientificAdvisorLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
};

export const getAboutScientificAdvisorById = createAsyncThunk("scientificAdvisor/getById", async (data, thunkAPI) => {
	try {
		return await scientificAdvisorService.getScientificAdvisorById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllAboutScientificAdvisor = createAsyncThunk("scientificAdvisor/get/all", async (_, thunkAPI) => {
	try {
		return await scientificAdvisorService.getAllScientificAdvisor();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addAboutScientificAdvisor = createAsyncThunk("scientificAdvisor/add", async (data, thunkAPI) => {
	try {
		return await scientificAdvisorService.addAboutScientificAdvisor(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editAboutScientificAdvisor = createAsyncThunk("scientificAdvisor/edit", async (data, thunkAPI) => {
	try {
		const newData = await scientificAdvisorService.updateScientificAdvisor(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutScientificAdvisor());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeScientificAdvisor = createAsyncThunk("scientificAdvisor/remove", async (data, thunkAPI) => {
	try {
		const newData = await scientificAdvisorService.deleteScientificAdvisor(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutScientificAdvisor());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteScientificAdvisors = createAsyncThunk("scientificAdvisor/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await scientificAdvisorService.bulkDeleteScientificAdvisor(data);
		if (newData) {
			thunkAPI.dispatch(getAllAboutScientificAdvisor());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "scientific_advisor",
	initialState,
	reducers: {
		resetAboutScientificAdvisor: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addAboutScientificAdvisor.pending, (state) => {
				state.isScientificAdvisorLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addAboutScientificAdvisor.fulfilled, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addAboutScientificAdvisor.rejected, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editAboutScientificAdvisor.pending, (state) => {
				state.isScientificAdvisorLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editAboutScientificAdvisor.fulfilled, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editAboutScientificAdvisor.rejected, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getAboutScientificAdvisorById.pending, (state) => {
				state.isScientificAdvisorLoading = true;
			})
			.addCase(getAboutScientificAdvisorById.fulfilled, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.currentScientificAdvisor = action.payload.scientificAdvisor;
			})
			.addCase(getAboutScientificAdvisorById.rejected, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.isError = true;
			})
			.addCase(getAllAboutScientificAdvisor.pending, (state) => {
				state.isScientificAdvisorLoading = true;
			})
			.addCase(getAllAboutScientificAdvisor.fulfilled, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.scientificAdvisor = action.payload.scientificAdvisor;
				state.currentScientificAdvisor = null;
				state.successMessage = ""
			})
			.addCase(getAllAboutScientificAdvisor.rejected, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.isError = true;
			})
			.addCase(removeScientificAdvisor.pending, (state) => {
				state.isScientificAdvisorLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeScientificAdvisor.fulfilled, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeScientificAdvisor.rejected, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteScientificAdvisors.pending, (state) => {
				state.isScientificAdvisorLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteScientificAdvisors.fulfilled, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteScientificAdvisors.rejected, (state, action) => {
				state.isScientificAdvisorLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetAboutScientificAdvisor } = adminSlice.actions;
export default adminSlice.reducer;
