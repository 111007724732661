import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactUsService from "./contactUsService";

const initialState = {
	contactUs: null,
	currentContactUs: null,
	isContactUsLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	contactUsMessage: ""
};

export const getContactUsById = createAsyncThunk("contactUs/getById", async (data, thunkAPI) => {
	try {
		return await contactUsService.getContactUsById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllContactUs = createAsyncThunk("contactUs/get/all", async (_, thunkAPI) => {
	try {
		return await contactUsService.getAllContactUs();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addContactUs = createAsyncThunk("contactUs/add", async (data, thunkAPI) => {
	try {
		return await contactUsService.createContactUs(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editContactUs = createAsyncThunk("contactUs/edit", async (data, thunkAPI) => {
	try {
		const newData = await contactUsService.updateContactUs(data);
		if (newData) {
			thunkAPI.dispatch(getAllContactUs());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeContactUs = createAsyncThunk("contactUs/remove", async (data, thunkAPI) => {
	try {
		const newData = await contactUsService.deleteContactUs(data);
		if (newData) {
			thunkAPI.dispatch(getAllContactUs());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "contact_us",
	initialState,
	reducers: {
		resetContactUs: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addContactUs.pending, (state) => {
				state.isContactUsLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addContactUs.fulfilled, (state, action) => {
				state.isContactUsLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Contact Us Us Successfully Created";
			})
			.addCase(addContactUs.rejected, (state, action) => {
				state.isContactUsLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editContactUs.pending, (state) => {
				state.isContactUsLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editContactUs.fulfilled, (state, action) => {
				state.isContactUsLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Contact Us Successfully Updated";
			})
			.addCase(editContactUs.rejected, (state, action) => {
				state.isContactUsLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getContactUsById.pending, (state) => {
				state.isContactUsLoading = true;
			})
			.addCase(getContactUsById.fulfilled, (state, action) => {
				state.isContactUsLoading = false;
				state.currentContactUs = action.payload.contactUs;
			})
			.addCase(getContactUsById.rejected, (state, action) => {
				state.isContactUsLoading = false;
				state.isError = true;
			})
			.addCase(getAllContactUs.pending, (state) => {
				state.isContactUsLoading = true;
			})
			.addCase(getAllContactUs.fulfilled, (state, action) => {
				state.isContactUsLoading = false;
				state.contactUs = action.payload.contactUs;
				state.currentContactUs = null;
				state.successMessage = ""
			})
			.addCase(getAllContactUs.rejected, (state, action) => {
				state.isContactUsLoading = false;
				state.isError = true;
			})
			.addCase(removeContactUs.pending, (state) => {
				state.isContactUsLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeContactUs.fulfilled, (state, action) => {
				state.isContactUsLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeContactUs.rejected, (state, action) => {
				state.isContactUsLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetContactUs } = adminSlice.actions;
export default adminSlice.reducer;
