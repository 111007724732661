import axios from "../../../utils/axios";

const upload = async (data) => {
	const response = await axios.post(`/api/admin/reports`, data);
	return response.data.products;
};

const getReport = async (data) => {
	const response = await axios.get(`api/admin/reports/${data.id}`);
	console.log("payres", response,data);
	return response.data;
};

const reportService = {
	upload,
	getReport
};

export default reportService;
