import axios from "../../../../utils/axios";

const createSliderCard = async (data) => {
	const response = await axios.post(`/api/admin/about/slider-card`, data);
	return response.data;
};

const updateSliderCard = async (data) => {
	const response = await axios.put(`/api/admin/about/slider-card/${data._id}`, data);
	return response.data;
};

const getAllSliderCard = async () => {
	const response = await axios.get(`/api/admin/about/slider-card`);
	console.log(response.data);
	return response.data;
};

const getSliderCardById = async (data) => {
	const response = await axios.get(`/api/admin/about/slider-card/${data.id}`);
	return response.data;
};
const deleteSliderCard = async (data) => {
	const response = await axios.delete(`/api/admin/about/slider-card/${data.id}`);
	return response.data;
};
const bulkDeleteSliderCard = async (data) => {
	const response = await axios.post(`/api/admin/about/slider-card`, data);
	return response.data;
};

const aboutContentCardService = {
	createSliderCard,
	updateSliderCard,
	getAllSliderCard,
	getSliderCardById,
	deleteSliderCard,
	bulkDeleteSliderCard
};

export default aboutContentCardService;
