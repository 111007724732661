import {alpha} from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
	return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
	lighter: "#C8FACD",
	light: "#5BE584",
	main: "#00AB55",
	dark: "#007B55",
	darker: "#005249",
};
const SECONDARY = {
	lighter: "#D6E4FF",
	light: "#84A9FF",
	main: "#3366FF",
	dark: "#1939B7",
	darker: "#091A7A",
};
const INFO = {
	lighter: "#D0F2FF",
	light: "#74CAFF",
	main: "#1890FF",
	dark: "#0C53B7",
	darker: "#04297A",
};
const SUCCESS = {
	lighter: "#E9FCD4",
	light: "#AAF27F",
	main: "#54D62C",
	dark: "#229A16",
	darker: "#08660D",
};
const WARNING = {
	lighter: "#FFF7CD",
	light: "#FFE16A",
	main: "#FFC107",
	dark: "#B78103",
	darker: "#7A4F01",
};
const ERROR = {
	lighter: "#FFE7D9",
	light: "#FFA48D",
	main: "#FF4842",
	dark: "#B72136",
	darker: "#7A0C2E",
};

const STATUS = {
	initialized: "#000",
	order_placed: {main: "#3730a3", light: "rgba(55, 48, 163, 0.1)"},
	accepted: {main: "#15803d", light: "rgba(21, 128, 61, 0.1)"},
	cancelled: {main: "#be123c", light: "rgba(190, 18, 60, 0.1)"},
	kit_activated: {main: "#7e22ce", light: "rgba(126, 34, 206, 0.1)"},
	processing: {main: "#86198f", light: "rgba(134, 25, 143, 0.1)"},
	completed: {main: "#3f6212", light: "rgba(63, 98, 18, 0.1)"},
};

const GREY = {
	0: "#FFFFFF",
	100: "#F9FAFB",
	200: "#F4F6F8",
	300: "#DFE3E8",
	400: "#C4CDD5",
	500: "#919EAB",
	600: "#637381",
	700: "#454F5B",
	800: "#212B36",
	900: "#161C24",
	500_8: alpha("#919EAB", 0.08),
	500_12: alpha("#919EAB", 0.12),
	500_16: alpha("#919EAB", 0.16),
	500_24: alpha("#919EAB", 0.24),
	500_32: alpha("#919EAB", 0.32),
	500_48: alpha("#919EAB", 0.48),
	500_56: alpha("#919EAB", 0.56),
	500_80: alpha("#919EAB", 0.8),
};

const GRADIENTS = {
	primary: createGradient(PRIMARY.light, PRIMARY.main),
	info: createGradient(INFO.light, INFO.main),
	success: createGradient(SUCCESS.light, SUCCESS.main),
	warning: createGradient(WARNING.light, WARNING.main),
	error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
	violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
	blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
	green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
	yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
	red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const COMMON = {
	common: {black: "#000", white: "#fff"},
	primary: {...PRIMARY, contrastText: "#fff"},
	secondary: {...SECONDARY, contrastText: "#fff"},
	info: {...INFO, contrastText: "#fff"},
	success: {...SUCCESS, contrastText: GREY[800]},
	warning: {...WARNING, contrastText: GREY[800]},
	error: {...ERROR, contrastText: "#fff"},
	status: {...STATUS, contrastText: GREY[800]},
	grey: GREY,
	gradients: GRADIENTS,
	chart: CHART_COLORS,
	divider: GREY[500_24],
	action: {
		hover: GREY[500_8],
		selected: GREY[500_16],
		disabled: GREY[500_80],
		disabledBackground: GREY[500_24],
		focus: GREY[500_24],
		hoverOpacity: 0.08,
		disabledOpacity: 0.48,
	},
};

const palette = {
	light: {
		...COMMON,
		mode: "light",
		text: {primary: GREY[800], secondary: GREY[600], disabled: GREY[500]},
		background: {paper: "#fff", default: "#fff", neutral: GREY[200]},
		action: {active: GREY[600], ...COMMON.action},
	},
	dark: {
		...COMMON,
		mode: "dark",
		text: {primary: "#fff", secondary: GREY[500], disabled: GREY[600]},
		background: {paper: GREY[800], default: GREY[900], neutral: GREY[500_16]},
		action: {active: GREY[500], ...COMMON.action},
	},
	typography: {
		fontFamily: ["Lato", "sans-serif"].join(", "),
	},

	palette: {
		primary: {
			main: "#3CAA48",
		},

		secondary: {
			main: "#1A5735",
		},

		success: {
			main: "#a3e635",
		},

		error: {
			main: "#f87171",
		},

		warning: {
			main: "#fbbf24",
		},

		common: {
			black: "#000",
			white: "#fff",
			greyLight: "#C4C4C4",
			greyDark: "#787878",
		},

		action: {
			disabled: "#ccc",
			disabledBackground: 'grey[600]',
			disabledOpacity: 0.5,
		},
	},
};

export default palette;
