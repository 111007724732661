import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import homeContentService from "./HomeContentService";

const initialState = {
	homeContent: null,
	currentHomeContent: null,
	isHomeContentLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	homeContentMessage: ""
};

export const getHomeContentById = createAsyncThunk("homecontent/getById", async (data, thunkAPI) => {
	try {
		return await homeContentService.getHomeContentById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllHomeContent = createAsyncThunk("homecontent/get/all", async (_, thunkAPI) => {
	try {
		return await homeContentService.getAllHomeContent();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addHomeContent = createAsyncThunk("homecontent/add", async (data, thunkAPI) => {
	try {
		return await homeContentService.createHomeContent(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editHomeContent = createAsyncThunk("homecontent/edit", async (data, thunkAPI) => {
	try {
		const newData = await homeContentService.updateHomeContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllHomeContent());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeHomeContent = createAsyncThunk("homecontent/remove", async (data, thunkAPI) => {
	try {
		const newData = await homeContentService.deleteHomeContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllHomeContent());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteHomeContents = createAsyncThunk("homecontent/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await homeContentService.bulkDeleteHomeContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllHomeContent());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "home_content",
	initialState,
	reducers: {
		resetHomeContent: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addHomeContent.pending, (state) => {
				state.isHomeContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addHomeContent.fulfilled, (state, action) => {
				state.isHomeContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addHomeContent.rejected, (state, action) => {
				state.isHomeContentLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editHomeContent.pending, (state) => {
				state.isHomeContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editHomeContent.fulfilled, (state, action) => {
				state.isHomeContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editHomeContent.rejected, (state, action) => {
				state.isHomeContentLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getHomeContentById.pending, (state) => {
				state.isHomeContentLoading = true;
			})
			.addCase(getHomeContentById.fulfilled, (state, action) => {
				state.isHomeContentLoading = false;
				state.currentHomeContent = action.payload.homeContent;
			})
			.addCase(getHomeContentById.rejected, (state, action) => {
				state.isHomeContentLoading = false;
				state.isError = true;
			})
			.addCase(getAllHomeContent.pending, (state) => {
				state.isHomeContentLoading = true;
			})
			.addCase(getAllHomeContent.fulfilled, (state, action) => {
				state.isHomeContentLoading = false;
				state.homeContent = action.payload.homeContent;
				state.currentHomeContent = null;
				state.successMessage = ""
			})
			.addCase(getAllHomeContent.rejected, (state, action) => {
				state.isHomeContentLoading = false;
				state.isError = true;
			})
			.addCase(removeHomeContent.pending, (state) => {
				state.isHomeContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeHomeContent.fulfilled, (state, action) => {
				state.isHomeContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeHomeContent.rejected, (state, action) => {
				state.isHomeContentLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHomeContents.pending, (state) => {
				state.isHomeContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHomeContents.fulfilled, (state, action) => {
				state.isHomeContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteHomeContents.rejected, (state, action) => {
				state.isHomeContentLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetHomeContent } = adminSlice.actions;
export default adminSlice.reducer;
