import React from "react";
import { Navigate } from "react-router";

const PrivateRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem("isAdmin") === "true";

  if (!isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;