import axios from "../../../../utils/axios";

const createContentCard = async (data) => {
	const response = await axios.post(`/api/admin/about/content-card`, data);
	return response.data;
};

const updateContentCard = async (data) => {
	const response = await axios.put(`/api/admin/about/content-card/${data._id}`, data);
	return response.data;
};

const getAllContentCard = async () => {
	const response = await axios.get(`/api/admin/about/content-card`);
    console.log(response.data);
	return response.data;
};

const getContentCardById = async (data) => {
	const response = await axios.get(`/api/admin/about/content-card/${data.id}`);
	return response.data;
};
const deleteContentCard = async (data) => {
	const response = await axios.delete(`/api/admin/about/content-card/${data.id}`);
	return response.data;
};

const bulkDeleteContentCard = async (data) => {
	const response = await axios.post(`/api/admin/about/content-card`, data);
	return response.data;
};

const aboutContentCardService = {
	createContentCard,
	updateContentCard,
	getAllContentCard,
	getContentCardById,
	deleteContentCard,
	bulkDeleteContentCard
};

export default aboutContentCardService;
