import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import promocodeService from "./promocodeService";

const initialState = {
	promocodes: [],
	isPromoCodeLoading: false,
	currentPromocode: null,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	// isAddSuccess: false,
	isAddFailure: false
};

export const getPromoCodes = createAsyncThunk("promocodes/get/all", async (_, thunkAPI) => {
	try {
		return await promocodeService.getAllCodes();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const addPromoCode = createAsyncThunk("promocodes/add", async (data, thunkAPI) => {
	try {
		const newData = await promocodeService.addCode(data);
		if (newData) {
			thunkAPI.dispatch(getPromoCodes());

			return newData;
		} else {
			const message = "Something went wrong!";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error);
		return thunkAPI.rejectWithValue(message);
	}
});

export const getPromoCodeById = createAsyncThunk("promocode/getById", async (data, thunkAPI) => {
	try {
		return await promocodeService.getpromocodes(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const updatePromoCode = createAsyncThunk("promocodes/update", async (data, thunkAPI) => {
	try {
		const newData = await promocodeService.updateCode(data);
		if (newData) {
			thunkAPI.dispatch(getPromoCodes());
			return newData;
		} else {
			const message = "Something went wrong!";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const deletePromoCode = createAsyncThunk("promocodes/delete", async (data, thunkAPI) => {
	try {
		const newData = await promocodeService.deleteCode(data);

		if (newData) {
			thunkAPI.dispatch(getPromoCodes());

			return newData;
		} else {
			const message = "Something went wrong!";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeletePromocodes = createAsyncThunk("promocodes/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await promocodeService.bulkDeletePromocode(data);
		if (newData) {
			thunkAPI.dispatch(getPromoCodes());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const promocodeSlice = createSlice({
	name: "promocode",
	initialState,
	reducers: {
		resetPromocode: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addPromoCode.pending, (state) => {
				state.isPromoCodeLoading = true;
				state.isAddError = false;
				state.isSuccess = false;
			})
			.addCase(addPromoCode.fulfilled, (state, action) => {
				console.log('action', action);
				state.isPromoCodeLoading = false;
				state.isSuccess = true;
				state.isAddError = false;
				state.successMessage = action.payload.message
				state.errorMessage = action.payload.errorCode;
			})
			.addCase(addPromoCode.rejected, (state, action) => {
				console.log('action', action);
				state.isPromoCodeLoading = false;
				state.isAddError = true;
				state.isSuccess = false;
				state.errorMessage = action.payload.errorCode;
			})
			.addCase(getPromoCodes.pending, (state) => {
				state.isPromoCodeLoading = true;
			})
			.addCase(getPromoCodes.fulfilled, (state, action) => {
				state.isPromoCodeLoading = false;
				state.isSuccess = false;
				state.promocodes = action.payload.data;
				state.successMessage = '';
				state.errorMessage = ""
			})
			.addCase(getPromoCodes.rejected, (state, action) => {
				state.isPromoCodeLoading = false;
				state.isError = true;
			})

			.addCase(getPromoCodeById.pending, (state) => {
				state.isPromoCodeLoading = true;
			})
			.addCase(getPromoCodeById.fulfilled, (state, action) => {
				state.isSuccess = false;
				state.isPromoCodeLoading = false;
				state.currentPromocode = action.payload.promocode;
			})
			.addCase(getPromoCodeById.rejected, (state, action) => {
				state.isPromoCodeLoading = false;
				state.isError = true;
			})

			.addCase(updatePromoCode.pending, (state) => {
				state.isPromoCodeLoading = true;
				state.isAddError = false;
				state.isSuccess = false;
			})
			.addCase(updatePromoCode.fulfilled, (state, action) => {
				state.isPromoCodeLoading = false;
				state.isSuccess = true;
				state.isAddError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(updatePromoCode.rejected, (state, action) => {
				state.isPromoCodeLoading = false;
				state.isAddError = true;
				state.isSuccess = false;
			})
			.addCase(deletePromoCode.pending, (state) => {
				state.isPromoCodeLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(deletePromoCode.fulfilled, (state, action) => {
				state.isPromoCodeLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message
			})
			.addCase(deletePromoCode.rejected, (state, action) => {
				state.isPromoCodeLoading = false;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeletePromocodes.pending, (state) => {
				state.isPromoCodeLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeletePromocodes.fulfilled, (state, action) => {
				state.isPromoCodeLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message
			})
			.addCase(bulkDeletePromocodes.rejected, (state, action) => {
				state.isPromoCodeLoading = false;
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetPromocode } = promocodeSlice.actions;
export default promocodeSlice.reducer;
