import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import adminService from "./adminService";

const initialState = {
	admins: [],
	currentAdmin: null,
	isAdminLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	isEditView: false
};

// Verify User Email
export const createAdmin = createAsyncThunk("admin/createAdmin", async (data, thunkAPI) => {
	try {
		// return await adminService.addAdmin(data);
		const response = await adminService.addAdmin(data);
		return response.data;

	} catch (error) {
		const message = (error && error.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const updateAdmin = createAsyncThunk("admin/edit", async (data, thunkAPI) => {
	try {
		return await adminService.editAdmin(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAdminById = createAsyncThunk("admin/getById", async (data, thunkAPI) => {
	try {
		return await adminService.getAdmin(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAdmins = createAsyncThunk("admin/get/all", async (_, thunkAPI) => {
	try {
		return await adminService.getAllAdmin();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const removeAdmin = createAsyncThunk("admin/remove", async (data, thunkAPI) => {
	try {
		const newData = await adminService.deleteAdmin(data);

		if (newData) {
			thunkAPI.dispatch(getAdmins());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteAdmins = createAsyncThunk("admin/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await adminService.bulkDeleteAdmin(data);
		if (newData) {
			thunkAPI.dispatch(getAdmins());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "admin",
	initialState,
	reducers: {
		// setSuccess: (state) => {
		// 	state.isSuccess = true;
		// 	state.isError = false;
		// 	state.isEdit = false;
		// },
		// setError: (state) => {
		// 	state.isSuccess = false;
		// 	state.isError = true;
		// 	state.isEdit = false;
		// },
		// setEdit: (state) => {
		// 	state.isSuccess = false;
		// 	state.isError = false;
		// 	state.isEdit = true;
		// },

		resetAdmin: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(createAdmin.pending, (state) => {
				state.isAdminLoading = true;
				state.isError = false;
				state.isSuccess = false;
				state.currentAdmin = null
			})
			.addCase(createAdmin.fulfilled, (state, action) => {
				state.isAdminLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
				state.currentAdmin = null;
			})
			.addCase(createAdmin.rejected, (state, action) => {
				state.isAdminLoading = false;
				state.isError = true;
				state.isSuccess = false;
				state.errorMessage = action.payload.message;
				state.currentAdmin = null;
			})
			.addCase(updateAdmin.pending, (state) => {
				state.isAdminLoading = true;
				state.isError = false;
				state.isSuccess = false;
				state.isEditView = false;
			})
			.addCase(updateAdmin.fulfilled, (state, action) => {
				console.log('actionaction',action);
				state.isAdminLoading = false;
				state.isSuccess = false;
				state.isError = false;
				state.isEditView = true;
				state.successMessage = "Admin User Successfully Updated";
			})
			.addCase(updateAdmin.rejected, (state, action) => {

				state.isAdminLoading = false;
				state.errorMessage = "An Error Occured While Updating an Admin";
				state.isError = true;
				state.isSuccess = false;
				state.isEditView = false;
			})
			.addCase(getAdminById.pending, (state) => {
				state.isAdminLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(getAdminById.fulfilled, (state, action) => {
				state.isAdminLoading = false;
				state.currentAdmin = action.payload.data;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(getAdminById.rejected, (state, action) => {
				state.isAdminLoading = false;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getAdmins.pending, (state) => {
				state.isAdminLoading = true;
				state.isError = false;
				state.isSuccess = false;
				state.isEditView = false;
				state.errorMessage = '';
				state.currentAdmin = null
			})
			.addCase(getAdmins.fulfilled, (state, action) => {
				state.isAdminLoading = false;
				state.admins = action.payload.data;
				state.isError = false;
				state.isSuccess = false;
				state.isEditView = false;
				state.successMessage = '';
				state.errorMessage = '';
				state.currentAdmin = null;
			})
			.addCase(getAdmins.rejected, (state, action) => {
				state.isAdminLoading = false;
				state.isSuccess = false;
				state.isEditView = false;
				state.errorMessage = '';
				state.currentAdmin = null
			})
			.addCase(removeAdmin.pending, (state) => {
				state.isAdminLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeAdmin.fulfilled, (state, action) => {
				state.isAdminLoading = false;
				state.isSuccess = false;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeAdmin.rejected, (state, action) => {
				state.isAdminLoading = false;
				state.errorMessage = action.payload.message;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAdmins.pending, (state) => {
				state.isAdminLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteAdmins.fulfilled, (state, action) => {
				state.isAdminLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteAdmins.rejected, (state, action) => {
				state.isAdminLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});
// export const { setSuccess, setError, setEdit } = adminSlice.actions;

export const { resetAdmin } = adminSlice.actions;
export default adminSlice.reducer;
