// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  changePassword: (token) => path(ROOTS_AUTH, `/reset-password/${token}`),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  customer: {
    root: path(ROOTS_DASHBOARD, "/customers"),
    list: path(ROOTS_DASHBOARD, "/customers/list"),
    new: path(ROOTS_DASHBOARD, "/customers/new"),
    edit: (customerId) =>
      path(ROOTS_DASHBOARD, `/customers/${customerId}/edit`),
  },

  product: {
    root: path(ROOTS_DASHBOARD, "/products"),
    list: path(ROOTS_DASHBOARD, "/products/list"),
    newProduct: path(ROOTS_DASHBOARD, "/products/new"),
    editById: (productId) =>
      path(ROOTS_DASHBOARD, `/products/${productId}/edit`),
  },

  payment_gateway: {
    root: path(ROOTS_DASHBOARD, "/payment_gateway"),
    list: path(ROOTS_DASHBOARD, "/payment_gateway/list"),
    newPaymentgateway: path(ROOTS_DASHBOARD, "/payment_gateway/new"),
    editById: (productId) =>
      path(ROOTS_DASHBOARD, `/payment_gateway/${productId}/edit`),
  },

  organization: {
    root: path(ROOTS_DASHBOARD, "/organization"),
    list: path(ROOTS_DASHBOARD, "/organization/list"),
    newOrganization: path(ROOTS_DASHBOARD, "/organization/new"),
    editById: (productId) =>
      path(ROOTS_DASHBOARD, `/organization/${productId}/edit`),
  },

  order: {
    root: path(ROOTS_DASHBOARD, "/orders"),
    list: path(ROOTS_DASHBOARD, "/orders/list/all"),
    view: (orderId) => path(ROOTS_DASHBOARD, `/orders/${orderId}`),
    // editById: (orderId) => path(ROOTS_DASHBOARD, `/orders/${orderId}/edit`),
  },

  blog: {

    root: path(ROOTS_DASHBOARD, "/blog"),
    list: path(ROOTS_DASHBOARD, "/blog/list"),

    blogCategoryList: path(ROOTS_DASHBOARD, "/blog/category"),
    blogCategoryCreate: path(ROOTS_DASHBOARD, "/blog/category/new"),
    blogCategoryEditById: (blogCategoryId) =>
      path(ROOTS_DASHBOARD, `/blog/category/${blogCategoryId}/edit`),

    blogList: path(ROOTS_DASHBOARD, "/blog/blogs"),
    blogCreate: path(ROOTS_DASHBOARD, "/blog/blogs/new"),
    blogEditById: (blogId) => path(ROOTS_DASHBOARD, `/blog/blogs/${blogId}/edit`)




    // root: path(ROOTS_DASHBOARD, "/blog"),
    // list: path(ROOTS_DASHBOARD, "/blog/list"),
    // newBlog: path(ROOTS_DASHBOARD, "/blog/new"),
    // editById: (blogId) => path(ROOTS_DASHBOARD, `/blog/${blogId}/edit`),
  },

  report: {
    root: path(ROOTS_DASHBOARD, "/reports"),
    upload: path(ROOTS_DASHBOARD, "/reports/upload"),
    view: (reportId) => path(ROOTS_DASHBOARD, `/reports/${reportId}`),
    view: (reportId) => path(ROOTS_DASHBOARD, `/reports/${reportId}/*`),
  },

  enquiry: {
    root: path(ROOTS_DASHBOARD, "/enquiry"),
    list: path(ROOTS_DASHBOARD, "/enquiry/list"),
    view: (enquiryId) => path(ROOTS_DASHBOARD, `/enquiry/${enquiryId}`),
  },

  subscriber: {
    root: path(ROOTS_DASHBOARD, "/subscribers"),
    list: path(ROOTS_DASHBOARD, "/subscribers/list"),
  },

  promocode: {
    root: path(ROOTS_DASHBOARD, "/promocodes"),
    list: path(ROOTS_DASHBOARD, "/promocodes/list"),
    new: path(ROOTS_DASHBOARD, "/promocodes/new"),
    // view: (enquiryId) => path(ROOTS_DASHBOARD, `/newsletter/${enquiryId}`),
  },

  // ADMIN CONTROL
  admin: {
    root: path(ROOTS_DASHBOARD, "/admin"),
    list: path(ROOTS_DASHBOARD, "/admin/list"),
    create: path(ROOTS_DASHBOARD, "/admin/new"),
    edit: (adminId) => path(ROOTS_DASHBOARD, `/admin/${adminId}/edit`),
    account: path(ROOTS_DASHBOARD, "/admin/account"),
  },
  promocode: {
    root: path(ROOTS_DASHBOARD, "/promocodes"),
    list: path(ROOTS_DASHBOARD, "/promocodes/list"),
    new: path(ROOTS_DASHBOARD, "/promocodes/new"),
    editById: (promocodeId) =>
      path(ROOTS_DASHBOARD, `/promocodes/${promocodeId}/edit`),
    // view: (enquiryId) => path(ROOTS_DASHBOARD, `/newsletter/${enquiryId}`),
  },

  content_management: {
    root: path(ROOTS_DASHBOARD, "/content_management"),
    list: path(ROOTS_DASHBOARD, "/content_management/list"),
    newContentmanagement: path(ROOTS_DASHBOARD, "/content_management/new"),
    editById: (contentId) =>
      path(ROOTS_DASHBOARD, `/content_management/${contentId}/edit`),
  },
  home_content: {
    root: path(ROOTS_DASHBOARD, "/home_content"),
    contents: path(ROOTS_DASHBOARD, "/home_content/contents"),
    list: path(ROOTS_DASHBOARD, "/home_content/list"),
    newHomeContentList: path(ROOTS_DASHBOARD, "/home_content/create_list"),
    newHomeContentCard: path(ROOTS_DASHBOARD, "/home_content/create_card"),
    editById: (homeContentId) =>
      path(ROOTS_DASHBOARD, `/home_content/${homeContentId}/edit_list`),
    editCardById: (homeContentCardId) =>
      path(ROOTS_DASHBOARD, `/home_content/${homeContentCardId}/edit_card`),
    card: path(ROOTS_DASHBOARD, "/home_content/card"),
  },
  contact_us: {
    root: path(ROOTS_DASHBOARD, "/contact_us"),
    list: path(ROOTS_DASHBOARD, "/contact_us/list"),
    newContactUs: path(ROOTS_DASHBOARD, "/contact_us/new"),
    editById: (contactUsId) =>
      path(ROOTS_DASHBOARD, `/contact_us/${contactUsId}/edit`),
  },
  benefits: {
    root: path(ROOTS_DASHBOARD, "/benefits"),
    list: path(ROOTS_DASHBOARD, "/benefits/list"),
    newBenefits: path(ROOTS_DASHBOARD, "/benefits/new"),
    editById: (benefitId) =>
      path(ROOTS_DASHBOARD, `/benefits/${benefitId}/edit`),
  },
  about: {
    root: path(ROOTS_DASHBOARD, "/about"),
    list: path(ROOTS_DASHBOARD, "/about/list"),

    contentCardList: path(ROOTS_DASHBOARD, "/about/content_card"),
    contentCardCreate: path(ROOTS_DASHBOARD, "/about/content_card/new"),
    contentCardEditById: (contentCardId) =>
      path(ROOTS_DASHBOARD, `/about/content_card/${contentCardId}/edit`),

    contentCardList: path(ROOTS_DASHBOARD, "/about/content_card"),
    contentCardCreate: path(ROOTS_DASHBOARD, "/about/content_card/new"),
    contentCardEditById: (contentCardId) => path(ROOTS_DASHBOARD, `/about/content_card/${contentCardId}/edit`),

    sliderCardList: path(ROOTS_DASHBOARD, "/about/slider_card"),
    sliderCardCreate: path(ROOTS_DASHBOARD, "/about/slider_card/new"),
    sliderCardEditById: (sliderCardId) => path(ROOTS_DASHBOARD, `/about/slider_card/${sliderCardId}/edit`),

    teamMembersList: path(ROOTS_DASHBOARD, "/about/our_team_members"),
    memberCreate: path(ROOTS_DASHBOARD, "/about/our_team_member/new"),
    memberEditById: (memberId) => path(ROOTS_DASHBOARD, `/about/our_team_member/${memberId}/edit`),

    teamMembersdataList: path(ROOTS_DASHBOARD, "/about/team_members"),
    memberCreatedata: path(ROOTS_DASHBOARD, "/about/team_member/new"),
    memberEditDataById: (memberId) => path(ROOTS_DASHBOARD, `/about/team_member/${memberId}/edit`),

    executiveMembersList: path(ROOTS_DASHBOARD, "/about/executive_members"),
    executiveMemberCreate: path(ROOTS_DASHBOARD, "/about/executive_member/new"),
    executiveMemberEditById: (executiveMemberId) => path(ROOTS_DASHBOARD, `/about/executive_member/${executiveMemberId}/edit`),

    BODMembersList: path(ROOTS_DASHBOARD, "/about/board_of_directors"),
    BODMemberCreate: path(ROOTS_DASHBOARD, "/about/board_of_director/new"),
    BODMemberEditById: (BODMemberEditById) => path(ROOTS_DASHBOARD, `/about/board_of_director/${BODMemberEditById}/edit`),

    scientificAdvisorsList: path(ROOTS_DASHBOARD, "/about/scientific_advisors"),
    scientificAdvisorCreate: path(ROOTS_DASHBOARD, "/about/scientific_advisor/new"),
    scientificAdvisorEditById: (scientificAdvisorId) => path(ROOTS_DASHBOARD, `/about/scientific_advisor/${scientificAdvisorId}/edit`),

    medicalAdvisorsList: path(ROOTS_DASHBOARD, "/about/medical_advisors"),
    medicalAdvisorCreate: path(ROOTS_DASHBOARD, "/about/medical_advisor/new"),
    medicalAdvisorEditById: (medicalAdvisorId) => path(ROOTS_DASHBOARD, `/about/medical_advisor/${medicalAdvisorId}/edit`),

    newAboutUs: path(ROOTS_DASHBOARD, "/about/new"),
    editById: (aboutId) => path(ROOTS_DASHBOARD, `/about/${aboutId}/edit`),
  },
  landing: {
    root: path(ROOTS_DASHBOARD, "/landing"),
    list: path(ROOTS_DASHBOARD, "/landing/list"),

    healthyContentList: path(ROOTS_DASHBOARD, "/landing/healthy_content"),
    healthyContentCreate: path(ROOTS_DASHBOARD, "/landing/healthy_content/new"),
    healthyContentEditById: (healthyContentId) => path(ROOTS_DASHBOARD, `/landing/healthy_content/${healthyContentId}/edit`),

    healthStepList: path(ROOTS_DASHBOARD, "/landing/health_step"),
    healthStepCreate: path(ROOTS_DASHBOARD, "/landing/health_step/new"),
    healthStepEditById: (healthStepId) => path(ROOTS_DASHBOARD, `/landing/health_step/${healthStepId}/edit`),

    gutWellnessStartList: path(ROOTS_DASHBOARD, "/landing/gutWellness_start"),
    gutWellnessStartCreate: path(ROOTS_DASHBOARD, "/landing/gutWellness_start/new"),
    gutWellnessStartEditById: (gutWellnessStartId) => path(ROOTS_DASHBOARD, `/landing/gutWellness_start/${gutWellnessStartId}/edit`),

    helpContentList: path(ROOTS_DASHBOARD, "/landing/help_content"),
    helpContentCreate: path(ROOTS_DASHBOARD, "/landing/help_content/new"),
    helpContentEditById: (helpContentId) => path(ROOTS_DASHBOARD, `/landing/help_content/${helpContentId}/edit`),

    helpContentSliderList: path(ROOTS_DASHBOARD, "/landing/help_content/slider"),
    helpContentSliderCreate: path(ROOTS_DASHBOARD, "/landing/help_content/slider/new"),
    helpContentSliderEditById: (helpContentSliderId) => path(ROOTS_DASHBOARD, `/landing/help_content/slider/${helpContentSliderId}/edit`),

    kitContentList: path(ROOTS_DASHBOARD, "/landing/kit_content"),
    kitContentCreate: path(ROOTS_DASHBOARD, "/landing/kit_content/new"),
    kitContentEditById: (kitContentId) => path(ROOTS_DASHBOARD, `/landing/kit_content/${kitContentId}/edit`),

    reviewList: path(ROOTS_DASHBOARD, "/landing/review"),
    reviewCreate: path(ROOTS_DASHBOARD, "/landing/review/new"),
    reviewEditById: (reviewId) => path(ROOTS_DASHBOARD, `/landing/review/${reviewId}/edit`),

    newAboutUs: path(ROOTS_DASHBOARD, "/landing/new"),
    editById: (aboutId) => path(ROOTS_DASHBOARD, `/landing/${aboutId}/edit`),
  },

  customer_reviews: {
    root: path(ROOTS_DASHBOARD, "/customer_reviews"),
    list: path(ROOTS_DASHBOARD, "/customer_reviews/list")
  },

  faq: {
    root: path(ROOTS_DASHBOARD, "/faq"),
    list: path(ROOTS_DASHBOARD, "/faq/list"),

    faqCategoryList: path(ROOTS_DASHBOARD, "/faq/category"),
    faqCategoryCreate: path(ROOTS_DASHBOARD, "/faq/category/new"),
    faqCategoryEditById: (faqCategoryId) =>
      path(ROOTS_DASHBOARD, `/faq/category/${faqCategoryId}/edit`),

    faqList: path(ROOTS_DASHBOARD, "/faq/faqs"),
    faqCreate: path(ROOTS_DASHBOARD, "/faq/faqs/new"),
    faqEditById: (faqId) => path(ROOTS_DASHBOARD, `/faq/faqs/${faqId}/edit`)
  },


  content_management: {
    root: path(ROOTS_DASHBOARD, "/content_management"),
    list: path(ROOTS_DASHBOARD, "/content_management/list"),
    newContentmanagement: path(ROOTS_DASHBOARD, "/content_management/new"),
    editById: (contentId) =>
      path(ROOTS_DASHBOARD, `/content_management/${contentId}/edit`),
  },
};
