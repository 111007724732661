import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";

// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

// config
import { PATH_AFTER_LOGIN } from "../config";

// components
import LoadingScreen from "../components/LoadingScreen";
import SuperAdminGuard from "src/guards/SuperAdminGuard";

import PrivateRoute from "../hooks/privateRoute.js";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation();

	return (
		<Suspense
			fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
		>
			<Component {...props} />
		</Suspense>
	);
};

export default function Router() {
	return useRoutes([
		{
			path: "/",
			element: (
				<GuestGuard>
					<Navigate to="/auth/login" replace />,
				</GuestGuard>
			),
		},

		{
			path: "auth",
			children: [
				{
					path: "login",
					element: (
						<GuestGuard>
							<Login />
						</GuestGuard>
					),
				},
				{ path: "verify/:token", element: <CreatePassword /> },
				{ path: "reset-password", element: <ResetPassword /> },
				{ path: "reset-password/:token", element: <CreatePassword /> },
			],
		},

		// Dashboard Routes
		{
			path: "dashboard",
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),

			children: [
				// DASHBOARD
				{ element: <Navigate to={PATH_AFTER_LOGIN} replace /> },
				{ index: true, element: <GeneralAnalytics /> },

				// PAYMENT GATEWAY
				{
					path: "payment_gateway",
					children: [
						{
							element: (
								<Navigate to="/dashboard/payment_gateway/list" replace />
							),
							index: true,
						},
						{
							path: "new",
							// element: <PaymentgatewayCreate />
							element: (
								<PrivateRoute>
									<PaymentgatewayCreate />
								</PrivateRoute>
							),
						},
						{
							path: "list",
							// element: <PaymentgatewayList /> 
							element: (
								<PrivateRoute>
									<PaymentgatewayList />
								</PrivateRoute>
							),
						},

						{
							path: ":paymentGatewayId/edit",
							// element: <PaymentgatewayCreate />,
							element: (
								<PrivateRoute>
									<PaymentgatewayCreate />
								</PrivateRoute>
							),
						},
					],
				},
				// Content Maangement
				{
					path: "content_management",
					children: [
						{
							element: (
								<Navigate to="/dashboard/content_management/list" replace />
							),
							index: true,
						},
						{
							path: "new",
							// element: <ContentmanagementCreate />
							element: (
								<PrivateRoute>
									<ContentmanagementCreate />
								</PrivateRoute>
							),
						},
						{
							path: "list",
							// element: <ContentmanagementList />
							element: (
								<PrivateRoute>
									<ContentmanagementList />
								</PrivateRoute>
							),
						},
						{
							path: ":contentManagementId/edit",
							// element: <ContentmanagementCreate />,
							element: (
								<PrivateRoute>
									<ContentmanagementCreate />
								</PrivateRoute>
							),
						},
					],
				},
				// Home
				{
					path: "home_content",
					children: [
						{
							element: (
								<Navigate to="/dashboard/home_content/content" replace />
							),
							index: true,
						},
						{
							path: "create_list",
							// element: <HomeContentCreate />
							element: (
								<PrivateRoute>
									<HomeContentCreate />
								</PrivateRoute>
							),
						},
						{
							path: "list",
							// element: <HomeContentList />
							element: (
								<PrivateRoute>
									<HomeContentList />
								</PrivateRoute>
							),
						},
						{
							path: "create_card",
							// element: <HomeContentCardCreate />
							element: (
								<PrivateRoute>
									<HomeContentCardCreate />
								</PrivateRoute>
							),
						},
						{
							path: "card",
							// element: <HomeContentCardList />
							element: (
								<PrivateRoute>
									<HomeContentCardList />
								</PrivateRoute>
							),
						},
						{
							path: "contents",
							// element: <HomeContentContent />
							element: (
								<PrivateRoute>
									<HomeContentContent />
								</PrivateRoute>
							),
						},
						{
							path: ":homeContentId/edit_list",
							// element: <HomeContentCreate />,
							element: (
								<PrivateRoute>
									<HomeContentCreate />
								</PrivateRoute>
							),
						},
						{
							path: ":homeContentCardId/edit_card",
							// element: <HomeContentCardCreate />,
							element: (
								<PrivateRoute>
									<HomeContentCardCreate />
								</PrivateRoute>
							),
						},
					],
				},
				// CONTACT_US
				{
					path: "contact_us",
					children: [
						{
							element: <Navigate to="/dashboard/contact_us/list" replace />,
							index: true,
						},
						{
							path: "new",
							// element: <ContactUsCreate />
							element: (
								<PrivateRoute>
									<ContactUsCreate />
								</PrivateRoute>
							),
						},
						{
							path: "list",
							// element: <ContactUsList />
							element: (
								<PrivateRoute>
									<ContactUsList />
								</PrivateRoute>
							),
						},
						{
							path: ":contactUsId/edit",
							// element: <ContactUsCreate />
							element: (
								<PrivateRoute>
									<ContactUsCreate />
								</PrivateRoute>
							),
						},
					],
				},
				// BENEFITS
				{
					path: "benefits",
					children: [
						{
							element: <Navigate to="/dashboard/benefits/list" replace />,
							index: true,
						},
						{
							path: "new",
							// element: <BenefitCreate />
							element: (
								<PrivateRoute>
									<BenefitCreate />
								</PrivateRoute>
							),
						},
						{
							path: "list",
							// element: <BenefitList />
							element: (
								<PrivateRoute>
									<BenefitList />
								</PrivateRoute>
							),
						},
						{
							path: ":benefitId/edit",
							// element: <BenefitCreate />
							element: (
								<PrivateRoute>
									<BenefitCreate />
								</PrivateRoute>
							),
						},
					],
				},
				// ABOUT
				{
					path: "about",
					children: [
						{
							element: <Navigate to="/dashboard/about/list" replace />,
							index: true,
						},
						{
							path: "list",
							// element: <AboutHomeList />
							element: (
								<PrivateRoute>
									<AboutHomeList />
								</PrivateRoute>
							),
						},
						{
							path: "content_card",
							// element: <ContentCardList />
							element: (
								<PrivateRoute>
									<ContentCardList />
								</PrivateRoute>
							),
						},
						{
							path: "content_card/new",
							// element: <ContentCardCreate />
							element: (
								<PrivateRoute>
									<ContentCardCreate />
								</PrivateRoute>
							),
						},
						{
							path: "content_card/:contentCardId/edit",
							// element: <ContentCardCreate />,
							element: (
								<PrivateRoute>
									<ContentCardCreate />
								</PrivateRoute>
							),
						},

						{
							path: "slider_card",
							// element: <SliderCardList />
							element: (
								<PrivateRoute>
									<SliderCardList />
								</PrivateRoute>
							),
						},
						{
							path: "slider_card/new",
							// element: <SliderCardCreate />
							element: (
								<PrivateRoute>
									<SliderCardCreate />
								</PrivateRoute>
							),
						},
						{
							path: "slider_card/:sliderCardId/edit",
							// element: <SliderCardCreate />,
							element: (
								<PrivateRoute>
									<SliderCardCreate />
								</PrivateRoute>
							),
						},

						{
							path: "team_members",
							// element: <TeamMemberList />
							element: (
								<PrivateRoute>
									<TeamMemberList />
								</PrivateRoute>
							),
						},
						{
							path: "team_member/new",
							// element: <TeamMemberCreate />
							element: (
								<PrivateRoute>
									<TeamMemberCreate />
								</PrivateRoute>
							),
						},
						{
							path: "team_member/:memberId/edit",
							// element: <TeamMemberCreate />,
							element: (
								<PrivateRoute>
									<TeamMemberCreate />
								</PrivateRoute>
							),
						},
						{
							path: "executive_members",
							// element: <ExecutiveMemberList />
							element: (
								<PrivateRoute>
									<ExecutiveMemberList />
								</PrivateRoute>
							),
						},
						{
							path: "executive_member/new",
							// element: <ExecutiveMemberCreate />
							element: (
								<PrivateRoute>
									<ExecutiveMemberCreate />
								</PrivateRoute>
							),
						},
						{
							path: "executive_member/:executiveMemberId/edit",
							// element: <ExecutiveMemberCreate />,
							element: (
								<PrivateRoute>
									<ExecutiveMemberCreate />
								</PrivateRoute>
							),
						},
						{
							path: "board_of_directors",
							// element: <BodMemberList />
							element: (
								<PrivateRoute>
									<BodMemberList />
								</PrivateRoute>
							),
						},
						{
							path: "board_of_director/new",
							// element: <BodMemberCreate />
							element: (
								<PrivateRoute>
									<BodMemberCreate />
								</PrivateRoute>
							),
						},
						{
							path: "board_of_director/:BODMemberEditById/edit",
							// element: <BodMemberCreate />,
							element: (
								<PrivateRoute>
									<BodMemberCreate />
								</PrivateRoute>
							),
						},

						{
							path: "our_team_members",
							// element: <OurTeamMemberList />
							element: (
								<PrivateRoute>
									<OurTeamMemberList />
								</PrivateRoute>
							),
						},
						{
							path: "our_team_member/new",
							// element: <OurTeamMemberCreate />
							element: (
								<PrivateRoute>
									<OurTeamMemberCreate />
								</PrivateRoute>
							),
						},
						{
							path: "our_team_member/:memberId/edit",
							// element: <OurTeamMemberCreate />,
							element: (
								<PrivateRoute>
									<OurTeamMemberCreate />
								</PrivateRoute>
							),
						},

						{
							path: "scientific_advisors",
							//  element: <ScientificAdvisorList /> 
							element: (
								<PrivateRoute>
									<ScientificAdvisorList />
								</PrivateRoute>
							),
						},
						{
							path: "scientific_advisor/new",
							// element: <ScientificAdvisorCreate />,
							element: (
								<PrivateRoute>
									<ScientificAdvisorCreate />
								</PrivateRoute>
							),
						},
						{
							path: "scientific_advisor/:scientificAdvisorId/edit",
							// element: <ScientificAdvisorCreate />,
							element: (
								<PrivateRoute>
									<ScientificAdvisorCreate />
								</PrivateRoute>
							),
						},

						{
							path: "medical_advisors",
							// element: <MedicalAdvisorList />
							element: (
								<PrivateRoute>
									<MedicalAdvisorList />
								</PrivateRoute>
							),
						},
						{
							path: "medical_advisor/new",
							// element: <MedicalAdvisorCreate />
							element: (
								<PrivateRoute>
									<MedicalAdvisorCreate />
								</PrivateRoute>
							),
						},
						{
							path: "medical_advisor/:medicalAdvisorId/edit",
							// element: <MedicalAdvisorCreate />
							element: (
								<PrivateRoute>
									<MedicalAdvisorCreate />
								</PrivateRoute>
							),
						},
					],
				},
				// LANDING
				{
					path: "landing",
					children: [
						{ element: <Navigate to="/dashboard/landing/list" replace />, index: true },
						{
							path: "list",
							// element: <LandingList />
							element: (
								<PrivateRoute>
									<LandingList />
								</PrivateRoute>
							),
						},
						{
							path: "healthy_content",
							// element: <HealthyContentList />
							element: (
								<PrivateRoute>
									<HealthyContentList />
								</PrivateRoute>
							),
						},
						{
							path: "healthy_content/new",
							// element: <HealthyContentCreate />
							element: (
								<PrivateRoute>
									<HealthyContentCreate />
								</PrivateRoute>
							),
						},
						{
							path: "healthy_content/:healthyContentId/edit",
							// element: <HealthyContentCreate />
							element: (
								<PrivateRoute>
									<HealthyContentCreate />
								</PrivateRoute>
							),
						},

						{
							path: "health_step",
							// element: <GutHealthStepsList />
							element: (
								<PrivateRoute>
									<GutHealthStepsList />
								</PrivateRoute>
							),
						},
						{
							path: "health_step/new",
							// element: <GutHealthStepsCreate />
							element: (
								<PrivateRoute>
									<GutHealthStepsCreate />
								</PrivateRoute>
							),
						},
						{
							path: "health_step/:healthStepId/edit",
							// element: <GutHealthStepsCreate />
							element: (
								<PrivateRoute>
									<GutHealthStepsCreate />
								</PrivateRoute>
							),
						},

						{
							path: "gutWellness_start",
							// element: <GutWellnessStartList />
							element: (
								<PrivateRoute>
									<GutWellnessStartList />
								</PrivateRoute>
							),
						},
						{
							path: "gutWellness_start/new",
							// element: <GutWellnessStartCreate />
							element: (
								<PrivateRoute>
									<GutWellnessStartCreate />
								</PrivateRoute>
							),
						},
						{
							path: "gutWellness_start/:gutWellnessStartId/edit",
							// element: <GutWellnessStartCreate />
							element: (
								<PrivateRoute>
									<GutWellnessStartCreate />
								</PrivateRoute>
							),
						},

						{
							path: "help_content",
							// element: <HelpContentList />
							element: (
								<PrivateRoute>
									<HelpContentList />
								</PrivateRoute>
							),
						},
						{
							path: "help_content/new",
							// element: <HelpContentCreate />
							element: (
								<PrivateRoute>
									<HelpContentCreate />
								</PrivateRoute>
							),
						},
						{
							path: "help_content/:helpContentId/edit",
							// element: <HelpContentCreate />
							element: (
								<PrivateRoute>
									<HelpContentCreate />
								</PrivateRoute>
							),
						},

						{
							path: "help_content/slider",
							// element: <HelpContentSliderList />
							element: (
								<PrivateRoute>
									<HelpContentSliderList />
								</PrivateRoute>
							),
						},
						{
							path: "help_content/slider/new",
							// element: <HelpContentSliderCreate />
							element: (
								<PrivateRoute>
									<HelpContentSliderCreate />
								</PrivateRoute>
							),
						},
						{
							path: "help_content/slider/:helpContentSliderId/edit",
							// element: <HelpContentSliderCreate />
							element: (
								<PrivateRoute>
									<HelpContentSliderCreate />
								</PrivateRoute>
							),
						},

						{
							path: "kit_content",
							// element: <KitContentList />
							element: (
								<PrivateRoute>
									<KitContentList />
								</PrivateRoute>
							),
						},
						{
							path: "kit_content/new",
							// element: <KitContentCreate />
							element: (
								<PrivateRoute>
									<KitContentCreate />
								</PrivateRoute>
							),
						},
						{
							path: "kit_content/:kitContentId/edit",
							// element: <KitContentCreate />
							element: (
								<PrivateRoute>
									<KitContentCreate />
								</PrivateRoute>
							),
						},

						{
							path: "review",
							// element: <ReviewList />
							element: (
								<PrivateRoute>
									<ReviewList />
								</PrivateRoute>
							),
						},
						{
							path: "review/new",
							// element: <ReviewCreate />
							element: (
								<PrivateRoute>
									<ReviewCreate />
								</PrivateRoute>
							),
						},
						{
							path: "review/:reviewId/edit",
							// element: <ReviewCreate />
							element: (
								<PrivateRoute>
									<ReviewCreate />
								</PrivateRoute>
							),
						},
					],
				},
				// CUSTOMER REVIEWS
				{
					path: "customer_reviews",
					children: [
						{
							element: <Navigate to="/dashboard/customer_reviews/list" replace />,
							index: true,
						},
						{
							path: "list",
							// element: <CustomerReviewList />
							element: (
								<PrivateRoute>
									<CustomerReviewList />
								</PrivateRoute>
							),
						},
					],
				},
				// FAQ
				{
					path: "faq",
					children: [
						{
							element: <Navigate to="/dashboard/faq/list" replace />,
							index: true,
						},
						{
							path: "list",
							element: (
								<PrivateRoute>
									<FAQHomeList />
								</PrivateRoute>
							),
							//  element: <FAQHomeList /> 
						},
						{
							path: "category",
							element: (
								<PrivateRoute>
									<FaqCategoryList />
								</PrivateRoute>
							),
							// element: <FaqCategoryList />
						},
						{
							path: "category/new",
							element: (
								<PrivateRoute>
									<FaqCategoryCreate />
								</PrivateRoute>
							),
							// element: <FaqCategoryCreate />
						},
						{
							path: "category/:faqCategoryId/edit",
							// element: <FaqCategoryCreate />,
							element: (
								<PrivateRoute>
									<FaqCategoryCreate />
								</PrivateRoute>
							),
						},

						{
							path: "faqs",
							element: (
								<PrivateRoute>
									<FaqList />
								</PrivateRoute>
							),
							// element: <FaqList />
						},
						{
							path: "faqs/new",
							// element: <FaqCreate />
							element: (
								<PrivateRoute>
									<FaqCreate />
								</PrivateRoute>
							),
						},
						{
							path: "faqs/:faqId/edit",
							// element: <FaqCreate />,
							element: (
								<PrivateRoute>
									<FaqCreate />
								</PrivateRoute>
							),
						},
					],
				},

				// PRODUCTS
				{
					path: "products",
					children: [
						{
							element: <Navigate to="/dashboard/products/list" replace />, index: true
						},
						{
							path: "new",
							// element: <ProductCreate />
							element: (
								<PrivateRoute>
									<ProductCreate />
								</PrivateRoute>
							),
						},
						{
							path: "list",
							// element: <ProductList />
							element: (
								<PrivateRoute>
									<ProductList />
								</PrivateRoute>
							),
						},
						{
							path: ":productId/edit",
							// element: <ProductCreate />
							element: (
								<PrivateRoute>
									<ProductCreate />
								</PrivateRoute>
							),
						},
					],
				},

				// Organization
				{
					path: "organization",
					children: [
						{
							element: <Navigate to="/dashboard/organization/list" replace />,
							index: true,
						},
						{
							path: "new",
							// element: <OrganizationCreate />
							element: (
								<PrivateRoute>
									<OrganizationCreate />
								</PrivateRoute>
							),
						},
						{
							path: "list",
							// element: <OrganizationList />
							element: (
								<PrivateRoute>
									<OrganizationList />
								</PrivateRoute>
							),
						},
						{
							path: ":organizationId/edit",
							// element: <OrganizationCreate />
							element: (
								<PrivateRoute>
									<OrganizationCreate />
								</PrivateRoute>
							),
						},
					],
				},

				// CUSTOMERS
				{
					path: "customers",
					children: [
						{
							element: <Navigate to="/dashboard/customers/list" replace />,
							index: true,
						},
						{ path: "new", element: <UserCreate /> },
						{ path: "list", element: <UserList /> },
						{ path: ":userId/edit", element: <UserCreate /> },
					],
				},

				// ORDERS
				{
					path: "orders",
					children: [
						{
							element: <Navigate to="/dashboard/orders/list/all" replace />,
							index: true,
						},
						{ path: "list/:orgId", element: <OrderList /> },
						{ path: "OrganizationSearch/:id", element: <OrganizationSearch /> },
						{ 
							path: ":orderId",element: (
								<PrivateRoute>
									<OrderView /> 
								</PrivateRoute>
							)
						},
					],
				},

				// PROMOCODES
				{
					path: "promocodes",
					children: [
						{
							element: <Navigate to="/dashboard/promocodes/list" replace />,
							index: true,
						},
						{
							path: "list",
							// element: <PromoCodeList />
							element: (
								<PrivateRoute>
									<PromoCodeList />
								</PrivateRoute>
							),
						},
						{
							path: "new",
							// element: <PromoCodeCreate />
							element: (
								<PrivateRoute>
									<PromoCodeCreate />
								</PrivateRoute>
							),
						},
						{
							path: ":promocodeId/edit",
							// element: <PromoCodeCreate />
							element: (
								<PrivateRoute>
									<PromoCodeCreate />
								</PrivateRoute>
							),
						},
					],
				},
				// BLOG
				{
					path: "blog",
					children: [
						{
							element: <Navigate to="/dashboard/blog/list" replace />,
							index: true,
						},
						{
							path: "list",
							// element: <BlogHomeList />
							element: (
								<PrivateRoute>
									<BlogHomeList />
								</PrivateRoute>
							),
						},
						{
							path: "category",
							// element: <BlogCategoryList />
							element: (
								<PrivateRoute>
									<BlogCategoryList />
								</PrivateRoute>
							),
						},
						{
							path: "category/new",
							// element: <BlogCategoryCreate />
							element: (
								<PrivateRoute>
									<BlogCategoryCreate />
								</PrivateRoute>
							),
						},
						{
							path: "category/:blogCategoryId/edit",
							// element: <BlogCategoryCreate />,
							element: (
								<PrivateRoute>
									<BlogCategoryCreate />
								</PrivateRoute>
							),
						},

						{
							path: "blogs",
							// element: <BlogList />
							element: (
								<PrivateRoute>
									<BlogList />
								</PrivateRoute>
							),
						},
						{
							path: "blogs/new",
							// element: <BlogCreate />
							element: (
								<PrivateRoute>
									<BlogCreate />
								</PrivateRoute>
							),
						},
						{
							path: "blogs/:blogId/edit",
							// element: <BlogCreate />,
							element: (
								<PrivateRoute>
									<BlogCreate />
								</PrivateRoute>
							),
						},

						// { path: "new", element: <BlogCreate /> },
						// { path: "list", element: <BlogList /> },
						// { path: ":blogId/edit", element: <BlogCreate /> },
					],
				},

				// ENQUIRY
				{
					path: "enquiry",
					children: [
						{
							element: <Navigate to="/dashboard/enquiry/list" replace />,
							index: true,
						},
						{
							path: "list",
							// element: <EnquiryList />
							element: (
								<PrivateRoute>
									<EnquiryList />
								</PrivateRoute>
							),
						},
						{
							path: ":enquiryId",
							// element: <EnquiryView />
							element: (
								<PrivateRoute>
									<EnquiryView />
								</PrivateRoute>
							),
						},
					],
				},

				// SUBSCRIBE
				{
					path: "subscribers",
					children: [
						{
							element: <Navigate to="/dashboard/subscribers/list" replace />,
							index: true,
						},
						{
							path: "list",
							// element: <SubscribersList />
							element:
								<PrivateRoute>
									<SubscribersList />
								</PrivateRoute>
						},

					],
				},

				// PROMOCODES
				{
					path: "promocodes",
					children: [
						{
							element: <Navigate to="/dashboard/promocodes/list" replace />,
							index: true,
						},
						{
							path: "list",
							element: (
								<PrivateRoute>
									<PromoCodeList />
								</PrivateRoute>
							),
							// element: <PromoCodeList />
						},
						{
							path: "new",
							// element: <PromoCodeCreate />
							element: (
								<PrivateRoute>
									<PromoCodeCreate />
								</PrivateRoute>
							),
						},
					],
				},

				// REPORTS
				{
					path: "reports",
					children: [
						{
							element: <Navigate to="/dashboard/reports/upload" replace />,
							index: true,
						},
						{
							path: "upload",
							// element: <ReportsCreate />
							element: (		
								<PrivateRoute>
									<ReportsCreate />
								</PrivateRoute>
							),
						},
						{
							path: "new",
							// element: <ComingSoon />
							element: (		
								<PrivateRoute>
									<ComingSoon />
								</PrivateRoute>
							),
						},
						{
							path: ":reportId",
							element: <ReportsView />
							// element: (		
							// 	<PrivateRoute>
							// 		<ReportsView />
							// 	</PrivateRoute>
							// ),
						},
						{
							path: ":reportId/*",
							element: <ReportsView />
							// element: (		
							// 	<PrivateRoute>
							// 		<ReportsView />
							// 	</PrivateRoute>
							// ),
						},
					],
				},

				// ADMIN ROUTES
				{
					path: "admin",
					children: [
						{
							element: <Navigate to="/dashboard/admin/list" replace />,
							index: true,
						},
						{
							path: "new",
							element: (
								// <SuperAdminGuard>
								// 	<AdminCreate />
								// </SuperAdminGuard>
								<PrivateRoute>
									<AdminCreate />
								</PrivateRoute>
							),
						},
						{
							path: ":adminId/edit",
							element: (
								// <SuperAdminGuard>
								// 	<AdminCreate />
								// </SuperAdminGuard>
								<PrivateRoute>
									<AdminCreate />
								</PrivateRoute>
							),
						},
						{
							path: "list",
							element: (
								// <SuperAdminGuard>
								// 	<AdminList />
								// </SuperAdminGuard>
								<PrivateRoute>
									<AdminList />
								</PrivateRoute>
							),
						},
					],
				},
			],
		},

		// Main Routes
		{
			path: "*",
			element: <LogoOnlyLayout />,
			children: [
				{ path: "coming-soon", element: <ComingSoon /> },
				{ path: "maintenance", element: <Maintenance /> },
				{ path: "500", element: <Page500 /> },
				{ path: "404", element: <NotFound /> },
				{ path: "403", element: <Forbidden /> },
				// {path: "*", element: <Navigate to="/404" replace />},
			],
		},

		{ path: "*", element: <Navigate to="/404" replace /> },
	]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const ResetPassword = Loadable(
	lazy(() => import("../pages/auth/ResetPassword"))
);
const CreatePassword = Loadable(
	lazy(() => import("../pages/auth/CreatePassword"))
);

// Dashboard - General
// DASHBOARD
const GeneralAnalytics = Loadable(
	lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);

// Dashboard - Management
// PRODUCTS
const ProductCreate = Loadable(
	lazy(() => import("../pages/dashboard/products/ProductCreate"))
);
const ProductList = Loadable(
	lazy(() => import("../pages/dashboard/products/ProductList"))
);

// PAYMENT GATEWAY
const PaymentgatewayCreate = Loadable(
	lazy(() => import("../pages/dashboard/paymentgateway/PaymentgatewayCreate"))
);
const PaymentgatewayList = Loadable(
	lazy(() => import("../pages/dashboard/paymentgateway/PaymentgatewayList"))
);

// CONTENT MANAGEMENT
const ContentmanagementCreate = Loadable(
	lazy(() =>
		import("../pages/dashboard/contentmanagement/ContentmanagementCreate")
	)
);
const ContentmanagementList = Loadable(
	lazy(() =>
		import("../pages/dashboard/contentmanagement/ContentmanagementList")
	)
);

// HOME MANAGEMENT
const HomeContentCreate = Loadable(
	lazy(() =>
		import("../pages/dashboard/homeContent/listView/HomeContentCreate")
	)
);
const HomeContentList = Loadable(
	lazy(() => import("../pages/dashboard/homeContent/listView/HomeContentList"))
);
const HomeContentContent = Loadable(
	lazy(() => import("../pages/dashboard/homeContent/HomeContent"))
);
const HomeContentCardCreate = Loadable(
	lazy(() =>
		import("../pages/dashboard/homeContent/cardView/HomeContentCardCreate")
	)
);
const HomeContentCardList = Loadable(
	lazy(() =>
		import("../pages/dashboard/homeContent/cardView/HomeContentCardList")
	)
);

// CONTACTUS MANAGEMENT
const ContactUsCreate = Loadable(
	lazy(() => import("../pages/dashboard/contactUs/ContactUsCreate"))
);
const ContactUsList = Loadable(
	lazy(() => import("../pages/dashboard/contactUs/ContactUsList"))
);

// BENEFITS MANAGEMENT
const BenefitCreate = Loadable(
	lazy(() => import("../pages/dashboard/benefits/BenefitCreate"))
);
const BenefitList = Loadable(
	lazy(() => import("../pages/dashboard/benefits/BenefitList"))
);

// ABOUT MANAGEMENT
const AboutHomeList = Loadable(
	lazy(() => import("../pages/dashboard/about/aboutHomeList"))
);
const ContentCardList = Loadable(
	lazy(() => import("../pages/dashboard/about/ContentCard/ContentCardList"))
);
const ContentCardCreate = Loadable(
	lazy(() => import("../pages/dashboard/about/ContentCard/ContentCardCreate"))
);

const SliderCardList = Loadable(
	lazy(() => import("../pages/dashboard/about/SliderCard/sliderCardList"))
);
const SliderCardCreate = Loadable(
	lazy(() => import("../pages/dashboard/about/SliderCard/sliderCardCreate"))
);

const OurTeamMemberList = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/OurTeamMembers/ourTeamMemberList")
	)
);
const OurTeamMemberCreate = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/OurTeamMembers/ourTeamMemberCreate")
	)
);

const TeamMemberList = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/TeamMembers/teamMemberList")
	)
);
const TeamMemberCreate = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/TeamMembers/teamMemberCreate")
	)
);

const ExecutiveMemberList = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/ExecutiveMembers/executiveMemberList")
	)
);
const ExecutiveMemberCreate = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/ExecutiveMembers/executiveMemberCreate")
	)
);

const BodMemberList = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/BODMembers/bodMemberList")
	)
);
const BodMemberCreate = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/BODMembers/bodMemberCreate")
	)
);

const ScientificAdvisorList = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/ScientificAdvisor/scientificAdvisorList")
	)
);
const ScientificAdvisorCreate = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/ScientificAdvisor/scientificAdvisorCreate")
	)
);

const MedicalAdvisorList = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/MedicalAdvisor/medicalAdvisorList")
	)
);
const MedicalAdvisorCreate = Loadable(
	lazy(() =>
		import("../pages/dashboard/about/MedicalAdvisor/medicalAdvisorCreate")
	)
);



// LANDING MANAGEMENT
const LandingList = Loadable(lazy(() => import("../pages/dashboard/landing/landingList")));

const HealthyContentList = Loadable(lazy(() => import("../pages/dashboard/landing/HealthyContent/healthyContentList")));
const HealthyContentCreate = Loadable(lazy(() => import("../pages/dashboard/landing/HealthyContent/healthyContentCreate")));

const GutHealthStepsList = Loadable(lazy(() => import("../pages/dashboard/landing/GutHealthSteps/gutHealthStepsList")));
const GutHealthStepsCreate = Loadable(lazy(() => import("../pages/dashboard/landing/GutHealthSteps/gutHealthStepsCreate")));

const GutWellnessStartList = Loadable(lazy(() => import("../pages/dashboard/landing/GutWellnessStart/gutWellnessStartList")));
const GutWellnessStartCreate = Loadable(lazy(() => import("../pages/dashboard/landing/GutWellnessStart/gutWellnessStartCreate")));

const HelpContentList = Loadable(lazy(() => import("../pages/dashboard/landing/HelpContent/helpContentList")));
const HelpContentCreate = Loadable(lazy(() => import("../pages/dashboard/landing/HelpContent/helpContentCreate")));

const HelpContentSliderList = Loadable(lazy(() => import("../pages/dashboard/landing/HelpContent/helpContentSlider/helpContentSliderList")));
const HelpContentSliderCreate = Loadable(lazy(() => import("../pages/dashboard/landing/HelpContent/helpContentSlider/helpContentSliderCreate")));

const KitContentList = Loadable(lazy(() => import("../pages/dashboard/landing/KitContent/kitContentList")));
const KitContentCreate = Loadable(lazy(() => import("../pages/dashboard/landing/KitContent/kitContentCreate")));

const ReviewList = Loadable(lazy(() => import("../pages/dashboard/landing/review/reviewList")));
const ReviewCreate = Loadable(lazy(() => import("../pages/dashboard/landing/review/reviewCreate")));

const FAQHomeList = Loadable(
	lazy(() => import("../pages/dashboard/faq/FAQHomeList"))
);
const FaqCategoryList = Loadable(lazy(() => import("../pages/dashboard/faq/FaqCategory/faqCategoryList")));
const FaqCategoryCreate = Loadable(lazy(() => import("../pages/dashboard/faq/FaqCategory/faqCategoryCreate")));

const FaqList = Loadable(lazy(() => import("../pages/dashboard/faq/FAQ/faqList")));
const FaqCreate = Loadable(lazy(() => import("../pages/dashboard/faq/FAQ/faqCreate")));

const CustomerReviewList = Loadable(lazy(() => import("../pages/dashboard/customerReview/customerReviewList")));
// Organization
const OrganizationCreate = Loadable(
	lazy(() => import("../pages/dashboard/organization/OrganizationCreate"))
);
const OrganizationList = Loadable(
	lazy(() => import("../pages/dashboard/organization/OrganizationList"))
);

// BLOGS
// const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
// const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// Blog GATEWAY

const BlogHomeList = Loadable(
	lazy(() => import("../pages/dashboard/blog/BlogHomeList"))
);


const BlogCreate = Loadable(
	lazy(() => import("../pages/dashboard/blog/blogs/BlogCreate"))
);

const BlogList = Loadable(
	lazy(() => import("../pages/dashboard/blog/blogs/BlogList"))
);

const BlogCategoryCreate = Loadable(
	lazy(() => import("../pages/dashboard/blog/blogCategory/BlogCategoryCreate"))
);

// const BlogCategoryList = Loadable(
// 	lazy(() => import("../pages/dashboard/blog/blogCategory/BlogCategoryList"))
// );

const BlogCategoryList = Loadable(
	lazy(() => import("../pages/dashboard/blog/blogCategory/BlogCategoryList"))
);

// USERS
const UserList = Loadable(
	lazy(() => import("../pages/dashboard/user/UserList"))
);
const UserCreate = Loadable(
	lazy(() => import("../pages/dashboard/user/UserCreate"))
);
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/user/UserAccount')));

// ORDERS
const OrderList = Loadable(
	lazy(() => import("../pages/dashboard/orders/OrderList"))
);
const OrderView = Loadable(
	lazy(() => import("../pages/dashboard/orders/OrderView"))
);
const OrganizationSearch = Loadable(
	lazy(() => import("../pages/dashboard/orders/OrganizationSearch"))
);
//  const organizationSearchList = Loadable(lazy(() => import('../pages/orders/OrganizationSearch')));
// const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
// const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// REPORTS
const ReportsCreate = Loadable(
	lazy(() => import("../pages/dashboard/reports/ReportsCreate"))
);

const ReportsView = Loadable(
	lazy(() => import("../pages/dashboard/reports/ReportsData"))
);

// ENQUIRY
const EnquiryList = Loadable(
	lazy(() => import("../pages/dashboard/enquiry/EnquiryList"))
);
const EnquiryView = Loadable(
	lazy(() => import("../pages/dashboard/enquiry/EnquiryView"))
);

// SUBSCRIBERS
const SubscribersList = Loadable(
	lazy(() => import("../pages/dashboard/subscribers/SubscribersList"))
);

// PROMO CODES
const PromoCodeList = Loadable(
	lazy(() => import("../pages/dashboard/promocodes/PromoCodeList"))
);
const PromoCodeCreate = Loadable(
	lazy(() => import("../pages/dashboard/promocodes/PromoCodeCreate"))
);

const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const Forbidden = Loadable(lazy(() => import("../pages/Page403")));

// ADMIN
const AdminList = Loadable(
	lazy(() => import("../pages/dashboard/admin/AdminList"))
);
const AdminCreate = Loadable(
	lazy(() => import("../pages/dashboard/admin/AdminCreate"))
);
