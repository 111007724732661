import axios from "../../../../utils/axios";

const createMedicalAdvisor = async (data) => {
	const response = await axios.post(`/api/admin/about/medical-advisor`, data);
	return response.data;
};

const updateMedicalAdvisor = async (data) => {
	const response = await axios.put(`/api/admin/about/medical-advisor/${data._id}`, data);
	return response.data;
};

const getAllMedicalAdvisor = async () => {
	const response = await axios.get(`/api/admin/about/medical-advisor`);
	return response.data;
};

const getMedicalAdvisorById = async (data) => {
	const response = await axios.get(`/api/admin/about/medical-advisor/${data.id}`);
	return response.data;
};
const deleteMedicalAdvisor = async (data) => {
	const response = await axios.delete(`/api/admin/about/medical-advisor/${data.id}`);
	return response.data;
};

const bulkDeleteMedicalAdvisor = async (data) => {
	const response = await axios.post(`/api/admin/about/medical-advisor`, data);
	return response.data;
};


const medicalAdvisorService = {
	createMedicalAdvisor,
	updateMedicalAdvisor,
	getAllMedicalAdvisor,
	getMedicalAdvisorById,
	deleteMedicalAdvisor,
	bulkDeleteMedicalAdvisor
};

export default medicalAdvisorService;
