import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import SuperAdminGuard from 'src/guards/SuperAdminGuard';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
	<ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
	...theme.typography.overline,
	paddingTop: theme.spacing(3),
	paddingLeft: theme.spacing(2),
	paddingBottom: theme.spacing(1),
	color: theme.palette.text.primary,
	transition: theme.transitions.create('opacity', {
		duration: theme.transitions.duration.shorter,
	}),
}));

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
	isCollapse: PropTypes.bool,
	navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
	const stringsToRemove = ["orders", "Customers", "dashboard"];
	const [updateConfig, setUpdateConfig] = useState([]);
	const [isAdminRole, setIsAdminRole] = useState(false);

	useEffect(() => {
		const role = localStorage.getItem('isAdmin');
		console.log('role', role);
		setIsAdminRole(role);
	}, []);

	useEffect(() => {
		if (isAdminRole === 'true') {
			setUpdateConfig(navConfig);
		} else {
			const updatedConfig = navConfig.map((data) => {
				const updatedItems = data.items?.filter((item) => stringsToRemove.includes(item.title));
				return {
					...data,
					items: updatedItems,
				};
			});
			setUpdateConfig(updatedConfig);
		}

	}, [isAdminRole]);

	return (
		<Box {...other}>
			{updateConfig.map((group) => (
				<List key={group.subheader} disablePadding sx={{ px: 2 }}>
					{group.allowSuperAdmin ? (
						<SuperAdminGuard>
							{isAdminRole === 'true' ?

								<ListSubheaderStyle
									sx={{
										...(isCollapse && {
											opacity: 0,
										}),
									}}
								>
									{group.subheader}
								</ListSubheaderStyle>
								: ""}
							{group.items.map((list) => (
								<NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
							))}
						</SuperAdminGuard>
					) : (
						<>
							{isAdminRole === 'true' ?
								<ListSubheaderStyle
									sx={{
										...(isCollapse && {
											opacity: 0,
										}),
									}}
								>
									{group.subheader}
								</ListSubheaderStyle>
								: ""}


							{group.items.map((list) => (
								<NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
							))}
						</>
					)}
					{/* <>
						<ListSubheaderStyle
							sx={{
								...(isCollapse && {
									opacity: 0,
								}),
							}}
						>
							{group.subheader}
						</ListSubheaderStyle>

						{group.items.map((list) => (
							<NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
						))}
					</> */}
				</List>
			))}
		</Box>
	);
}
