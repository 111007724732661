import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "./orderService";

const initialState = {
	orders: null,
	newOrders: [],
	newOrgOrders: [],
	currentOrder: null,
	isOrderError: false,
	isOrderSuccess: false,
	isOrderLoading: false,
	orderMessage: "",
};

export const getAllOrders = createAsyncThunk("order/getAll", async (_, thunkAPI) => {
	try {
		return await orderService.getOrders();
	} catch (error) {
		const message = (error && error.response.error) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});
export const getAllOrganizationOrders = createAsyncThunk("order/getAll/OrgId", async (data, thunkAPI) => {
	try {
		return await orderService.getOrganizationOrders(data);
	} catch (error) {
		const message = (error && error.response.error) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});


export const getOrganizationId = createAsyncThunk("order/organizationId/id", async (data, thunkAPI) => {
	try {
		return await orderService.getOrganizationId(data);
	} catch (error) {
		const message = (error && error.response.error) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getOrderById = createAsyncThunk("order/get/id", async (data, thunkAPI) => {
	try {
		return await orderService.getOrder(data);
	} catch (error) {
		const message = (error && error.response.error) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const updateStatus = createAsyncThunk("order/update/status", async (postData, thunkAPI) => {
	try {
		const data = await orderService.updateOrderStatus(postData);

		if (data) {
			thunkAPI.dispatch(getAllOrders());

			// TODO #WILL DISPATCH IN ORDERS LIST PAGE (FIX)
			if (postData.id) thunkAPI.dispatch(getOrderById({ id: postData.id }));

			return data;
		} else {
			const message = "Something went wrong!";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.error) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const removeOrder = createAsyncThunk("order/remove", async (data, thunkAPI) => {
	try {
		const newData = await orderService.deleteOrder(data);
		if (newData) {
			thunkAPI.dispatch(getAllOrders());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteOrders = createAsyncThunk("order/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await orderService.bulkDeleteOrder(data);

		if (newData) {
			thunkAPI.dispatch(getAllOrders());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const updateKitStatus = createAsyncThunk(
	"order/update/kitstatus",
	async (postData, thunkAPI) => {
		try {
			const data = await orderService.updateKitStatus(postData);

			if (data) {
				thunkAPI.dispatch(getAllOrders());

				// TODO #WILL DISPATCH IN ORDERS LIST PAGE (FIX)
				if (postData?.id) thunkAPI.dispatch(getOrderById({ id: postData.id }));

				return data;
			} else {
				const message = "Something went wrong!";

				return thunkAPI.rejectWithValue(message);
			}
		} catch (error) {
			const message = (error && error.response.error) || error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const updatePaymentStatus = createAsyncThunk("order/update/payment_status", async (postData, thunkAPI) => {
	try {
		const data = await orderService.updatePaymentStatus(postData);
		if (data) {
			if (postData.orderId) thunkAPI.dispatch(getOrderById({ id: postData.orderId }));
			return data;
		} else {
			const message = "Something went wrong!";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.error) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getUnReadOrder = createAsyncThunk("order/get/unreadorders", async (data, thunkAPI) => {
	try {
		const newData = await orderService.getUnReadOrders(data);
		return newData;
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const getUnReadOrganizationOrder = createAsyncThunk("order/get/unreadorders/:id", async (data, thunkAPI) => {
	try {
		const newData = await orderService.getUnReadOrganizationOrders(data);
		return newData;
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const orderSlice = createSlice({
	name: "order",
	initialState,
	reducers: {
		resetOrders: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllOrders.pending, (state) => {
				state.isOrderLoading = true;
			})
			.addCase(getAllOrders.fulfilled, (state, action) => {
				state.isOrderLoading = false;
				state.isOrderSuccess = false;
				state.orders = action.payload;
				state.orderMessage = "";
			})
			.addCase(getAllOrders.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.isOrderError = true;
				state.isOrderSuccess = false;
				state.orderMessage = action.payload;
			})
			.addCase(getAllOrganizationOrders.pending, (state) => {
				state.isOrderLoading = true;
			})
			.addCase(getAllOrganizationOrders.fulfilled, (state, action) => {
				console.log('actttto', action);
				state.isOrderLoading = false;
				state.isOrderSuccess = false;
				state.orders = action.payload;
				state.orderMessage = "";
			})
			.addCase(getAllOrganizationOrders.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.isOrderError = true;
				state.isOrderSuccess = false;
				state.orderMessage = action.payload;
			})
			.addCase(getOrderById.pending, (state) => {
				state.isOrderLoading = true;
			})
			.addCase(getOrderById.fulfilled, (state, action) => {
				state.isOrderLoading = false;
				state.currentOrder = action.payload;
			})
			.addCase(getOrderById.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.isOrderError = true;
				state.isOrderSuccess = false;
				state.orderMessage = action.payload;
			})
			.addCase(updateStatus.pending, (state) => {
				state.isOrderLoading = true;
			})
			.addCase(updateStatus.fulfilled, (state, action) => {
				state.isOrderLoading = false;
				state.isOrderSuccess = false;
				state.isOrderError = false;
			})
			.addCase(updateStatus.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.isOrderError = true;
				state.isOrderSuccess = false;
			})
			.addCase(updateKitStatus.pending, (state) => {
				state.isOrderLoading = true;
			})
			.addCase(updateKitStatus.fulfilled, (state, action) => {
				console.log('action', action);
				state.isOrderLoading = false;
				state.isOrderSuccess = false;
				state.isOrderError = false;
				state.orderMessage = 'orders updated successfully.';
			})
			.addCase(updateKitStatus.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.isOrderError = true;
				state.isOrderSuccess = false;
			})
			.addCase(updatePaymentStatus.pending, (state) => {
				state.isOrderLoading = true;
			})
			.addCase(updatePaymentStatus.fulfilled, (state, action) => {
				state.isOrderLoading = false;
				state.isOrderSuccess = false;
				state.isOrderError = false;
			})
			.addCase(updatePaymentStatus.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.isOrderError = true;
				state.isOrderSuccess = false;
			})
			.addCase(removeOrder.pending, (state) => {
				state.isOrderLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeOrder.fulfilled, (state, action) => {
				state.isOrderLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.orderMessage = action.payload.message;
			})
			.addCase(removeOrder.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteOrders.pending, (state) => {
				state.isOrderLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteOrders.fulfilled, (state, action) => {
				state.isOrderLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.orderMessage = action.payload.message;
			})
			.addCase(bulkDeleteOrders.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getUnReadOrder.pending, (state) => {
				state.isOrderLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(getUnReadOrder.fulfilled, (state, action) => {
				state.isOrderLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.newOrders = action.payload.data;
			})
			.addCase(getUnReadOrder.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getUnReadOrganizationOrder.pending, (state) => {
				state.isOrderLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(getUnReadOrganizationOrder.fulfilled, (state, action) => {
				state.isOrderLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.newOrgOrders = action.payload.pending_order;
			})
			.addCase(getUnReadOrganizationOrder.rejected, (state, action) => {
				state.isOrderLoading = false;
				state.isError = true;
				state.isSuccess = false;
			})
	},
});

export const { resetOrders } = orderSlice.actions;
export default orderSlice.reducer;
