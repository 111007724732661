import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, ListItemText, Badge } from '@mui/material';
//
import Iconify from '../../Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';
import { useDispatch, useSelector } from "src/redux/store";
import { isExternalLink } from '..';

import { getUnreadEnquiry } from "src/redux/slices/enquiry/enquirySlice";
import { getUnReadSubscriber } from "src/redux/slices/subscribers/subscriberSlice";
import { getUnReadOrder, getUnReadOrganizationOrder } from "src/redux/slices/orders/orderSlice";

// ----------------------------------------------------------------------

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }) {
  const { title, path, icon, info, children } = item;
  const [org, setOrg] = useState()

  const dispatch = useDispatch();
  const { readQEnquiries, isEnquiryLoading } = useSelector((state) => state.enquiry);
  const { readSubscribers, isSubscriberLoading } = useSelector((state) => state.subscriber);
  const { newOrders, newOrgOrders, isOrderLoading } = useSelector((state) => state.order);

  useEffect(() => {
    const localStorageValue = localStorage.getItem('organization');
    setOrg(localStorageValue);
    dispatch(getUnreadEnquiry());
    dispatch(getUnReadSubscriber());
    dispatch(getUnReadOrder());
    dispatch(getUnReadOrganizationOrder({ id: localStorageValue }));
  }, []);

  const renderContent = (
    // console.log('title', title === 'enquiry' ? 1 : 2)
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle disableTypography primary={title} isCollapse={isCollapse} />
      {title === 'enquiry' ? <Badge badgeContent={readQEnquiries} color="primary"></Badge> : ''}
      {title === 'subscribers' ? <Badge badgeContent={readSubscribers} color="primary"></Badge> : ''}
      {title === 'orders' ? !org ? <Badge badgeContent={newOrders} color="primary"></Badge> : <Badge badgeContent={newOrgOrders} color="primary"></Badge> : ""}
      {
        !isCollapse && (
          <>
            {info && info}
            {children && <ArrowIcon open={open} />}
          </>
        )
      }
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemSub({ item, open = false, active = false, onOpen }) {
  const { title, path, info, children } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={title} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener" subItem>
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
