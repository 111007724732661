import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import paymentGatewayService from "./paymentGatewayService";

const initialState = {
	paymentgateway: null,
	currentPaymentGateway: null,
	isPaymentGatewayLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	paymentGatewayMessage: ""
};

export const getPaymentGatewayById = createAsyncThunk("paymentgateway/getById", async (data, thunkAPI) => {
	try {
		return await paymentGatewayService.getPaymentGateway(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllPaymentGateway = createAsyncThunk("paymentgateway/get/all", async (_, thunkAPI) => {
	try {
		return await paymentGatewayService.getAllPaymentGateway();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addPaymentGateway = createAsyncThunk("paymentgateway/add", async (data, thunkAPI) => {
	try {
		return await paymentGatewayService.createPaymentGateway(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editPaymentGateway = createAsyncThunk("paymentgateway/edit", async (data, thunkAPI) => {
	try {
		const newData = await paymentGatewayService.updatePaymentGateway(data);
		if (newData) {
			thunkAPI.dispatch(getAllPaymentGateway());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const editPaymentGatewayStatus = createAsyncThunk("paymentgateway/status", async (data, thunkAPI) => {
	console.log('req.body.isDefault',data);
	try {
		const newData = await paymentGatewayService.updatePaymentGatewayStatus(data);
		if (newData) {
			thunkAPI.dispatch(getAllPaymentGateway());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const removePaymentGateway = createAsyncThunk("paymentgateway/remove", async (data, thunkAPI) => {
	try {
		const newData = await paymentGatewayService.removePaymentGateway(data);
		if (newData) {
			thunkAPI.dispatch(getAllPaymentGateway());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeletePaymentGateways = createAsyncThunk("paymentgateway/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await paymentGatewayService.bulkDeletePaymentGateway(data);

		if (newData) {
			thunkAPI.dispatch(getAllPaymentGateway());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "payment_gateway",
	initialState,
	reducers: {
		resetPaymentGateway: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addPaymentGateway.pending, (state) => {
				state.isPaymentGatewayLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addPaymentGateway.fulfilled, (state, action) => {

				state.isPaymentGatewayLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(addPaymentGateway.rejected, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editPaymentGateway.pending, (state) => {
				state.isPaymentGatewayLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editPaymentGateway.fulfilled, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(editPaymentGateway.rejected, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getPaymentGatewayById.pending, (state) => {
				state.isPaymentGatewayLoading = true;
			})
			.addCase(getPaymentGatewayById.fulfilled, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.currentPaymentGateway = action.payload.paymentGateway;
			})
			.addCase(getPaymentGatewayById.rejected, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.isError = true;
			})
			.addCase(getAllPaymentGateway.pending, (state) => {
				state.isPaymentGatewayLoading = true;
			})
			.addCase(getAllPaymentGateway.fulfilled, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.paymentgateway = action.payload.paymentGateway;
				state.successMessage = "";
				state.currentPaymentGateway = null
			})
			.addCase(getAllPaymentGateway.rejected, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.isError = true;
			}).addCase(editPaymentGatewayStatus.pending, (state) => {
				state.isPaymentGatewayLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editPaymentGatewayStatus.fulfilled, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Payment Gateway User Successfully Updated";
			})
			.addCase(editPaymentGatewayStatus.rejected, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(removePaymentGateway.pending, (state) => {
				state.isPaymentGatewayLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removePaymentGateway.fulfilled, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removePaymentGateway.rejected, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeletePaymentGateways.pending, (state) => {
				state.isPaymentGatewayLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeletePaymentGateways.fulfilled, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeletePaymentGateways.rejected, (state, action) => {
				state.isPaymentGatewayLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetPaymentGateway } = adminSlice.actions;
export default adminSlice.reducer;
