import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// routes
import { PATH_AUTH } from '../../../routes/paths';

// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
	const { login } = useAuth();

	const isMountedRef = useIsMountedRef();

	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const LoginSchema = Yup.object().shape({
		email: Yup.string().email('Email must be a valid email address').required('Email is required'),
		password: Yup.string().required('Password is required'),
	});

	const defaultValues = {
		email: '',
		password: '',
		remember: true,
	};

	const methods = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		reset,
		setError,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = methods;

	const onSubmit = async (data) => {
		try {
			await login(data.email, data.password);
		} catch (error) {
			const resErrorMessage = error.error;
			console.log(resErrorMessage);
			setErrorMessage(resErrorMessage);
			if (isMountedRef.current) {
				setError('afterSubmit', { ...error, message: resErrorMessage });
			}
			reset();
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				{/* {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit?.message}</Alert>} */}
				{errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
				<RHFTextField name='email' label='Email address' />
				<RHFTextField
					name='password'
					label='Password'
					type={showPassword ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
									<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			<Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 2 }}>
				<RHFCheckbox name='remember' label='Remember me' />
				<Link component={RouterLink} variant='subtitle2' to={PATH_AUTH.resetPassword}>
					Forgot password?
				</Link>
			</Stack>

			<LoadingButton
				fullWidth
				size='large'
				type='submit'
				variant='contained'
				loading={isSubmitting}
			>
				Login
			</LoadingButton>
		</FormProvider>
	);
}
