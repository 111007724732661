import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import faqCategoryService from "./faqCategoryService";

const initialState = {
    faqCategory: [],
    currentFaqCategory: null,
    isFAQCategoryLoading: false,
    successMessage: "",
    errorMessage: "",
    isError: false,
    isSuccess: false,
};

export const getFAQCategoryById = createAsyncThunk("faqCategory/getById", async (data, thunkAPI) => {
    try {
        return await faqCategoryService.getFAQCategory(data);
    } catch (error) {
        const message = (error && error.response.data) || error.toString();

        return thunkAPI.rejectWithValue(message);
    }
});

export const getFaqsCategory = createAsyncThunk("faqCategory/get/all", async (_, thunkAPI) => {
    try {
        return await faqCategoryService.getAllFaqCategory();
    } catch (error) {
        const message = (error && error.response.data) || error.toString();

        return thunkAPI.rejectWithValue(message);
    }
});

export const addFAQCategory = createAsyncThunk("faqCategory/add", async (data, thunkAPI) => {
    try {
        return await faqCategoryService.createFaqCategory(data);
    } catch (error) {
        const message = (error && error.response.data) || error.toString();

        return thunkAPI.rejectWithValue(message);
    }
});

export const editFAQCategory = createAsyncThunk("faqCategory/edit", async (data, thunkAPI) => {
    try {
        const newData = await faqCategoryService.updateFaqCategory(data);

        if (newData) {
            thunkAPI.dispatch(getFaqsCategory());
            return newData;
        } else {
            const message = "Something Went Wrong";

            return thunkAPI.rejectWithValue(message);
        }
    } catch (error) {
        const message = (error && error.response.data) || error.toString();

        return thunkAPI.rejectWithValue(message);
    }
});

export const removeFAQCategory = createAsyncThunk("faqCategory/remove", async (data, thunkAPI) => {
    try {
        const newData = await faqCategoryService.deleteFAQCategory(data);

        if (newData) {
            thunkAPI.dispatch(getFaqsCategory());
            return newData;
        } else {
            const message = "Something Went Wrong";

            return thunkAPI.rejectWithValue(message);
        }
    } catch (error) {
        const message = (error && error.response.data) || error.toString();

        return thunkAPI.rejectWithValue(message);
    }
});

export const bulkDeleteFAQCategories = createAsyncThunk("faqCategory/bulkRemove", async (data, thunkAPI) => {
    try {
        const newData = await faqCategoryService.bulkDeleteFAQCategory(data);
        if (newData) {
            thunkAPI.dispatch(getFaqsCategory());
            return newData;
        } else {
            const message = "Something Went Wrong";
            return thunkAPI.rejectWithValue(message);
        }
    } catch (error) {
        const message = (error && error.response.data) || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const faqCategorySlice = createSlice({
    name: "faq_category",
    initialState,
    reducers: {
        resetFAQCategory: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(addFAQCategory.pending, (state) => {
                state.isFAQCategoryLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(addFAQCategory.fulfilled, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.successMessage = action.payload.message;
            })
            .addCase(addFAQCategory.rejected, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.errorMessage = "An Error Occured While Creating an Admin";
                state.isError = true;
                state.isSuccess = false;
            })
            .addCase(editFAQCategory.pending, (state) => {
                state.isFAQCategoryLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(editFAQCategory.fulfilled, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.successMessage = action.payload.message;
            })
            .addCase(editFAQCategory.rejected, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.errorMessage = "An Error Occured While Updating an Admin";
                state.isError = true;
                state.isSuccess = false;
            })
            .addCase(getFAQCategoryById.pending, (state) => {
                state.isFAQCategoryLoading = true;
            })
            .addCase(getFAQCategoryById.fulfilled, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.currentFaqCategory = action.payload.faq;
            })
            .addCase(getFAQCategoryById.rejected, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.isError = true;
            })
            .addCase(getFaqsCategory.pending, (state) => {
                state.isFAQCategoryLoading = true;
            })
            .addCase(getFaqsCategory.fulfilled, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.faqCategory = action.payload.faq;
                state.currentFaqCategory = null;
                state.successMessage = ''
            })
            .addCase(getFaqsCategory.rejected, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.isError = true;
            })
            .addCase(removeFAQCategory.pending, (state) => {
                state.isFAQCategoryLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(removeFAQCategory.fulfilled, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.successMessage = action.payload.message;
            })
            .addCase(removeFAQCategory.rejected, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.errorMessage = "An Error Occured While Removing an Admin";
                state.isError = true;
                state.isSuccess = false;
            })
            .addCase(bulkDeleteFAQCategories.pending, (state) => {
                state.isFAQCategoryLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(bulkDeleteFAQCategories.fulfilled, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.successMessage = action.payload.message;
            })
            .addCase(bulkDeleteFAQCategories.rejected, (state, action) => {
                state.isFAQCategoryLoading = false;
                state.errorMessage = "An Error Occured While Removing an Admin";
                state.isError = true;
                state.isSuccess = false;
            });
    },
});

export const { resetFAQCategory } = faqCategorySlice.actions;
export default faqCategorySlice.reducer;
