import axios from "../../../../utils/axios";

const createGutWellnessStart = async (data) => {
	const response = await axios.post(`/api/admin/landing/gut-wellness-start`, data);
	return response.data;
};

const updateGutWellnessStart = async (data) => {
	console.log('ccc', data._id);
	const response = await axios.put(`/api/admin/landing/gut-wellness-start/${data._id}`, data);
	return response.data;
};

const getAllGutWellnessStart = async (params) => {
	console.log('reduce', params);
	const response = await axios.get(`/api/admin/landing/gut-wellness-start`, { params });
	console.log(response.data);
	return response.data;
};

const getGutWellnessStartById = async (data) => {
	const response = await axios.get(`/api/admin/landing/gut-wellness-start/${data.id}`);
	return response.data;
};

const deleteGutWellnessStart = async (data) => {
	const response = await axios.delete(`/api/admin/landing/gut-wellness-start/${data.id}`);
	return response.data;
};

const bulkDeleteGutWellnessStart = async (data) => {
	const response = await axios.post(`/api/admin/landing/gut-wellness-start`, data);
	return response.data;
};

const gutWellnessStartService = {
	createGutWellnessStart,
	updateGutWellnessStart,
	getAllGutWellnessStart,
	getGutWellnessStartById,
	deleteGutWellnessStart,
	bulkDeleteGutWellnessStart
};

export default gutWellnessStartService;
