import axios from "../../../utils/axios";

const createBlog = async (data) => {
	const response = await axios.post(`/api/admin/blog/`, data);
	return response.data;
};

const updateBlog = async (data) => {
	const response = await axios.put(`/api/admin/blog/${data._id}`, data);
	return response.data;
};

const updateBlogStatus = async (data) => {
	const response = await axios.put(`/api/admin/blog/status/${data._id}`, data);
	return response.data;
};

const getAllBlog = async () => {
	const response = await axios.get(`/api/admin/blog/`);
	return response.data;
};

const getBlog = async (data) => {
	const response = await axios.get(`/api/admin/blog/${data.id}`);
	return response.data;
};

const deleteBlog = async (data) => {
	const response = await axios.delete(`/api/admin/blog/${data.id}`);
	return response.data;
};

const bulkDeleteBlog = async (data) => {
	const response = await axios.post(`/api/admin/blog/bulkDelete`, data);
	return response.data;
};

const blogService = {
	createBlog,
	updateBlog,
	getAllBlog,
	getBlog,
    updateBlogStatus,
	deleteBlog,
	bulkDeleteBlog
};

export default blogService;
