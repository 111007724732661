import axios from "../../../../utils/axios";

const createScientificAdvisor = async (data) => {
	const response = await axios.post(`/api/admin/about/scientific-advisor`, data);
	return response.data;
};

const updateScientificAdvisor = async (data) => {
	const response = await axios.put(`/api/admin/about/scientific-advisor/${data._id}`, data);
	return response.data;
};

const getAllScientificAdvisor = async () => {
	const response = await axios.get(`/api/admin/about/scientific-advisor`);
	return response.data;
};

const getScientificAdvisorById = async (data) => {
	const response = await axios.get(`/api/admin/about/scientific-advisor/${data.id}`);
	return response.data;
};
const deleteScientificAdvisor = async (data) => {
	const response = await axios.delete(`/api/admin/about/scientific-advisor/${data.id}`);
	return response.data;
};
const bulkDeleteScientificAdvisor = async (data) => {
	const response = await axios.post(`/api/admin/about/scientific-advisor`, data);
	return response.data;
};

const scientificAdvisorService = {
	createScientificAdvisor,
	updateScientificAdvisor,
	getAllScientificAdvisor,
	getScientificAdvisorById,
	deleteScientificAdvisor,
	bulkDeleteScientificAdvisor
};

export default scientificAdvisorService;
