import axios from "../../../utils/axios";

const createHomeContent = async (data) => {
	const response = await axios.post(`/api/admin/homecontent`, data);
	return response.data;
};

const updateHomeContent = async (data) => {
	const response = await axios.put(`/api/admin/homecontent/${data._id}`, data);
	return response.data;
};

const getAllHomeContent = async () => {
	const response = await axios.get(`/api/admin/homecontent`);
    console.log(response.data);
	return response.data;
};

const getHomeContentById = async (data) => {
	const response = await axios.get(`/api/admin/homecontent/${data.id}`);
	return response.data;
};

const deleteHomeContent = async (data) => {
	const response = await axios.delete(`/api/admin/homecontent/${data.id}`);
	return response.data;
};

const bulkDeleteHomeContent = async (data) => {
	const response = await axios.post(`/api/admin/homecontent/bulkDelete`, data);
	return response.data;
};

const homeContentService = {
	createHomeContent,
	updateHomeContent,
	getAllHomeContent,
	getHomeContentById,
	deleteHomeContent,
	bulkDeleteHomeContent
};

export default homeContentService;
