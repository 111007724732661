import axios from "../../../utils/axios";

const createCategoryBlog = async (data) => {
	const response = await axios.post(`/api/admin/blogCategory/`, data);
	return response.data;
};

const updateCategoryBlog = async (data) => {
	const response = await axios.put(`/api/admin/blogCategory/${data._id}`, data);
	return response.data;
};

const updateCategoryBlogStatus = async (data) => {
	const response = await axios.put(`/api/admin/blogCategory/status/${data._id}`, data);
	return response.data;
};

const getAllCategoryBlogs = async () => {
	const response = await axios.get(`/api/admin/blogCategory`);
	return response.data;
};

const getCategoryBlogById = async (data) => {
	const response = await axios.get(`/api/admin/blogCategory/${data.id}`);
	return response.data;
};

const deleteCategoryBlog = async (data) => {
	const response = await axios.delete(`/api/admin/blogCategory/${data.id}`);
	return response.data;
};

const bulkDeleteCategoryBlog = async (data) => {
	const response = await axios.post(`/api/admin/blogCategory/bulkDelete`, data);
	return response.data;
};

const blogCategoryService = {
	createCategoryBlog,
	updateCategoryBlog,
	getAllCategoryBlogs,
	getCategoryBlogById,
	deleteCategoryBlog,
	bulkDeleteCategoryBlog
};

export default blogCategoryService;
