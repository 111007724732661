import axios from "../../../../utils/axios";

const createHealthyContent = async (data) => {
	const response = await axios.post(`/api/admin/landing/healthy-content`, data);
	return response.data;
};

const updateHealthyContent = async (data) => {
	const response = await axios.put(`/api/admin/landing/healthy-content/${data._id}`, data);
	return response.data;
};

const getAllHealthyContent = async () => {
	const response = await axios.get(`/api/admin/landing/healthy-content`);
	return response.data;
};

const getHealthyContentById = async (data) => {
	const response = await axios.get(`/api/admin/landing/healthy-content/${data.id}`);
	return response.data;
};
const deleteHealthyContent = async (data) => {
	const response = await axios.delete(`/api/admin/landing/healthy-content/${data.id}`);
	return response.data;
};

const bulkDeleteHealthyContent = async (data) => {
	const response = await axios.post(`/api/admin/landing/healthy-content`, data);
	return response.data;
};

const healthyContentService = {
	createHealthyContent,
	updateHealthyContent,
	getAllHealthyContent,
	getHealthyContentById,
	deleteHealthyContent,
	bulkDeleteHealthyContent
};

export default healthyContentService;
