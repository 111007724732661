import axios from "../../../../utils/axios";

const createHelpContent = async (data) => {
	const response = await axios.post(`/api/admin/landing/help-content`, data);
	return response.data;
};

const updateHelpContent = async (data) => {
	const response = await axios.put(`/api/admin/landing/help-content/${data._id}`, data);
	return response.data;
};

const getAllHelpContent = async () => {
	console.log('vv');
	const response = await axios.get(`/api/admin/landing/help-content`);
    console.log('ccc',response.data);
	return response.data;
};

const getHelpContentById = async (data) => {
	const response = await axios.get(`/api/admin/landing/help-content/${data.id}`);
	return response.data;
};

const deleteHelpContent = async (data) => {
	const response = await axios.delete(`/api/admin/landing/help-content/${data.id}`);
	return response.data;
};

const bulkDeleteHelpContent = async (data) => {
	const response = await axios.post(`/api/admin/landing/help-content`, data);
	return response.data;
};

const helpContentService = {
	createHelpContent,
	updateHelpContent,
	getAllHelpContent,
	getHelpContentById,
	deleteHelpContent,
	bulkDeleteHelpContent
};

export default helpContentService;
