import axios from "../../../utils/axios";

const getAllSubscribers = async () => {
	const response = await axios.get(`/api/admin/subscribers`);
	return response.data;
};

const deleteSubscriber = async (data) => {
	const response = await axios.delete(`/api/admin/subscribers/${data.id}`);
	return response.data;
};

const bulkDeleteSubscriber = async (data) => {
	const response = await axios.post(`/api/admin/subscribers/bulkDelete`, data);
	return response.data;
};

const getUnReadSubscribers = async (data) => {
	const response = await axios.get(`/api/admin/subscribers/unread`);
	return response.data;
};
const subscriberService = {
	getAllSubscribers,
	deleteSubscriber,
	bulkDeleteSubscriber,
	getUnReadSubscribers
};

export default subscriberService;
