import { combineReducers } from "redux";

import productReducer from "./slices/products/productSlice";
import paymentGatewayReducer from "./slices/paymentgateway/paymentGatewaySlice"
import orderReducer from "./slices/orders/orderSlice";
import userReducer from "./slices/users/userSlice";
import adminReducer from "./slices/admin/adminSlice";
import customerReducer from "./slices/customer/customerSlice";
import customerReviewsReducer from "./slices/customerReviews/customerReviewSlice";
import enquiryReducer from "./slices/enquiry/enquirySlice";
import subscriberReducer from "./slices/subscribers/subscriberSlice";
import promoCodeReducer from "./slices/promocodes/promocodeSlice";
import organizationReducer from './slices/organization/organizationSlice'

import blogReducer from './slices/blog/blogSlice'

import contentManagementReducer from "./slices/contentmanagement/contentManagementSlice";
import homeContentReducer from "./slices/homeContent/HomeContentSlice";
import homeContentCardReducer from "./slices/homeContent/HomeContentCardSlice";
import contactUsReducer from "./slices/contactUs/contactUsSlice";
import benefitsReducer from "./slices/benefits/benefitsSlice";
import aboutCardReducer from "./slices/about/ContentCard/ContentCardSlice";
import aboutSliderReducer from "./slices/about/sliderCard/SliderCardSlice";
import aboutMedicalAdvisorReducer from "./slices/about/medicalAdvisor/medicalAdvisorSlice";
import aboutScientificAdvisorReducer from "./slices/about/scientificAdvisor/ScientificAdvisorSlice";
import aboutTeamMemberReducer from "./slices/about/ourTeam/ourTeamSlice";
import landingHealthyContentReducer from "./slices/landing/healthyContent/healthyContentSlice";
import landingGutWellnessStartReducer from "./slices/landing/gutWellnessStart/gutWellnessStartSlice";
import landingHealthStepReducer from "./slices/landing/healthStep/healthStepSlice";
import landingHelpContentReducer from "./slices/landing/helpContent/helpContentSlice";
import landingKitContentReducer from "./slices/landing/kitContent/kitContentSlice";
import landingHelpContentSliderReducer from "./slices/landing/helpContentSlider/helpContentSliderSlice";
import landingReviewReducer from "./slices/landing/review/reviewSlice";
import dashboardReducer from "./slices/dashboard/dashboardSlice";
import reportReducer from "./slices/reports/reportSlice";
import faqCategoryReducer from "./slices/faq/faqCategory/faqCategorySlice";
import faqReducer from "./slices/faq/faqList/faqSlice";
import ourTeamMemberReducer from "./slices/about/teamMember/teamMemberSlice";
import executiveMemberReducer from "./slices/about/executiveMember/executiveMemberSlice";
import bODMemberReducer from "./slices/about/bodMember/bodMemberSlice";
import BlogCategoryReducer from "./slices/blog/blogCategorySlice";


// ----------------------------------------------------------------------

const rootReducer = combineReducers({
	payment_gateway: paymentGatewayReducer,
	product: productReducer,
	order: orderReducer,
	user: userReducer,
	admin: adminReducer,
	customer: customerReducer,
	enquiry: enquiryReducer,
	subscriber: subscriberReducer,
	promocode: promoCodeReducer,
	organization: organizationReducer,
	blog: blogReducer,
	content_management: contentManagementReducer,
	home_content: homeContentReducer,
	home_content_card: homeContentCardReducer,
	contact_us: contactUsReducer,
	benefits: benefitsReducer,
	dashboard: dashboardReducer,
	about_content_card: aboutCardReducer,
	about_slider_card: aboutSliderReducer,
	medical_advisor: aboutMedicalAdvisorReducer,
	scientific_advisor: aboutScientificAdvisorReducer,
	team_member: aboutTeamMemberReducer,
	healthy_content: landingHealthyContentReducer,
	gutWellness_start: landingGutWellnessStartReducer,
	health_step: landingHealthStepReducer,
	help_content: landingHelpContentReducer,
	kit_content: landingKitContentReducer,
	help_content_slider: landingHelpContentSliderReducer,
	review: landingReviewReducer,
	customer_review: customerReviewsReducer,
	report: reportReducer,
	faq: faqReducer,
	faq_category: faqCategoryReducer,
	our_team_member: ourTeamMemberReducer,
	executive_member: executiveMemberReducer,
	bod_member: bODMemberReducer,
	blog_category: BlogCategoryReducer,
});

export default rootReducer;
