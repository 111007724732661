import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import helpContentSliderService from "./helpContentSliderService";

const initialState = {
	helpContentSlider: null,
	currentHelpContentSlider: null,
	isHelpContentSliderLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	aboutSliderCardMessage: ""
};

export const getHelpContentSliderById = createAsyncThunk("helpcontentSlider/getById", async (data, thunkAPI) => {
	try {
		return await helpContentSliderService.getHelpContentByIdSlider(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllHelpContentSlider = createAsyncThunk("helpcontentSlider/get/all", async (_, thunkAPI) => {
	try {
		return await helpContentSliderService.getAllHelpContentSlider();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addHelpContentSlider = createAsyncThunk("helpcontentSlider/add", async (data, thunkAPI) => {
	try {
		return await helpContentSliderService.createHelpContentSlider(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editHelpContentSlider = createAsyncThunk("helpcontentSlider/edit", async (data, thunkAPI) => {
	try {
		const newData = await helpContentSliderService.updateHelpContentSlider(data);
		if (newData) {
			thunkAPI.dispatch(getAllHelpContentSlider());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeHelpContentSlider = createAsyncThunk("helpcontentSlider/remove", async (data, thunkAPI) => {
	try {
		const newData = await helpContentSliderService.deleteHelpContentSlider(data);
		if (newData) {
			thunkAPI.dispatch(getAllHelpContentSlider());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteHelpContentSliders = createAsyncThunk("helpcontentSlider/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await helpContentSliderService.bulkDeleteHelpContentSlider(data);
		if (newData) {
			thunkAPI.dispatch(getAllHelpContentSlider());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "help_content_slider",
	initialState,
	reducers: {
		resetHelpContentSlider: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addHelpContentSlider.pending, (state) => {
				state.isHelpContentSliderLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addHelpContentSlider.fulfilled, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addHelpContentSlider.rejected, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editHelpContentSlider.pending, (state) => {
				state.isHelpContentSliderLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editHelpContentSlider.fulfilled, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editHelpContentSlider.rejected, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getHelpContentSliderById.pending, (state) => {
				state.isHelpContentSliderLoading = true;
			})
			.addCase(getHelpContentSliderById.fulfilled, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.currentHelpContentSlider = action.payload.data;
			})
			.addCase(getHelpContentSliderById.rejected, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.isError = true;
			})
			.addCase(getAllHelpContentSlider.pending, (state) => {
				state.isHelpContentSliderLoading = true;
			})
			.addCase(getAllHelpContentSlider.fulfilled, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.helpContentSlider = action.payload.data;
				state.currentHelpContentSlider = null;
				state.successMessage = ""
			})
			.addCase(getAllHelpContentSlider.rejected, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.isError = true;
			})
			.addCase(removeHelpContentSlider.pending, (state) => {
				state.isHelpContentSliderLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeHelpContentSlider.fulfilled, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeHelpContentSlider.rejected, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHelpContentSliders.pending, (state) => {
				state.isHelpContentSliderLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHelpContentSliders.fulfilled, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteHelpContentSliders.rejected, (state, action) => {
				state.isHelpContentSliderLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetHelpContentSlider } = adminSlice.actions;
export default adminSlice.reducer;
