import axios from "../../../../utils/axios";

const createExecutiveMember = async (data) => {
	const response = await axios.post(`/api/admin/about/executive-member`, data);
	return response.data;
};

const updateExecutiveMember = async (data) => {
	const response = await axios.put(`/api/admin/about/executive-member/${data._id}`, data);
	return response.data;
};

const getAllExecutiveMember = async () => {
	const response = await axios.get(`/api/admin/about/executive-member`);
	return response.data;
};

const getExecutiveMemberById = async (data) => {
	const response = await axios.get(`/api/admin/about/executive-member/${data.id}`);
	return response.data;
};
const deleteExecutiveMember = async (data) => {
	const response = await axios.delete(`/api/admin/about/executive-member/${data.id}`);
	return response.data;
};
const bulkDeleteExecutiveMember  = async (data) => {
	const response = await axios.post(`/api/admin/about/executive/bulkDelete`, data);
	return response.data;
};

const aboutExecutiveMemberService = {
	createExecutiveMember,
	updateExecutiveMember,
	getAllExecutiveMember,
	getExecutiveMemberById,
	deleteExecutiveMember,
	bulkDeleteExecutiveMember
};

export default aboutExecutiveMemberService;
