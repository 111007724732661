import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import enquiryService from "./enquiryService";

const initialState = {
	enquiries: [],
	readQEnquiries: [],
	currentEnquiry: null,
	isEnquiryLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
};

export const getEnquiries = createAsyncThunk("enquiries/get/all", async (_, thunkAPI) => {
	try {
		return await enquiryService.getAllEnquires();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getEnquiry = createAsyncThunk("enquiries/get/", async (data, thunkAPI) => {
	try {
		return await enquiryService.getEnquiry(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const getUnreadEnquiry = createAsyncThunk("enquiries/get/unread", async (data, thunkAPI) => {
	try {
		const newData = await enquiryService.getUnReadEnquiry(data);
		return newData
		// if (newData) {
		// 	// thunkAPI.dispatch(getUnreadEnquiry());
		// 	return newData;
		// } else {
		// 	const message = "Something Went Wrong";

		// 	return thunkAPI.rejectWithValue(message);
		// }
		// return await enquiryService.getUnReadEnquiry(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeEnquiry = createAsyncThunk("enquiries/remove", async (data, thunkAPI) => {
	try {
		const newData = await enquiryService.deleteEnquiry(data);
		if (newData) {
			thunkAPI.dispatch(getEnquiries());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editEnquiry = createAsyncThunk("enquiries/edit", async (data, thunkAPI) => {
	try {
		const newData = await enquiryService.updateEnquiry(data);

		if (newData) {
			thunkAPI.dispatch(getEnquiries());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteEnquries = createAsyncThunk("enquiries/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await enquiryService.bulkDeleteEnquiry(data);
		if (newData) {
			thunkAPI.dispatch(getEnquiries());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const enquirySlice = createSlice({
	name: "enquiry",
	initialState,
	reducers: {
		resetEnquiry: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getEnquiries.pending, (state) => {
				state.isEnquiryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(getEnquiries.fulfilled, (state, action) => {
				state.isEnquiryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.enquiries = action.payload.data;
				state.successMessage = ""
			})
			.addCase(getEnquiries.rejected, (state, action) => {
				state.isEnquiryLoading = false;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getUnreadEnquiry.pending, (state) => {
				state.isEnquiryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(getUnreadEnquiry.fulfilled, (state, action) => {
				state.isEnquiryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.readQEnquiries = action.payload.data;
			})
			.addCase(getUnreadEnquiry.rejected, (state, action) => {
				state.isEnquiryLoading = false;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getEnquiry.pending, (state) => {
				state.isEnquiryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(getEnquiry.fulfilled, (state, action) => {
				state.isEnquiryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.currentEnquiry = action.payload.data;
				state.successMessage = ""
			})
			.addCase(getEnquiry.rejected, (state, action) => {
				state.isEnquiryLoading = false;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(removeEnquiry.pending, (state) => {
				state.isEnquiryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeEnquiry.fulfilled, (state, action) => {
				state.isEnquiryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeEnquiry.rejected, (state, action) => {
				state.isEnquiryLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editEnquiry.pending, (state) => {
				state.isEnquiryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editEnquiry.fulfilled, (state, action) => {
				state.isEnquiryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Enquiry Closed Successfully";
			})
			.addCase(editEnquiry.rejected, (state, action) => {
				state.isEnquiryLoading = false;
				state.errorMessage = "An Error Occured While Updating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteEnquries.pending, (state) => {
				state.isEnquiryLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteEnquries.fulfilled, (state, action) => {
				state.isEnquiryLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteEnquries.rejected, (state, action) => {
				state.isEnquiryLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetEnquiry } = enquirySlice.actions;
export default enquirySlice.reducer;
