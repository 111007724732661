import axios from "../../../../utils/axios";

const createTeamMember = async (data) => {
	const response = await axios.post(`/api/admin/about/member`, data);
	return response.data;
};

const updateTeamMember = async (data) => {
	console.log('ccc', data._id);
	const response = await axios.put(`/api/admin/about/member/${data._id}`, data);
	return response.data;
};

const getAllTeamMember = async () => {
	const response = await axios.get(`/api/admin/about/member`);
	return response.data;
};

const getTeamMemberById = async (data) => {
	const response = await axios.get(`/api/admin/about/member/${data.id}`);
	return response.data;
};
const deleteTeamMember = async (data) => {
	const response = await axios.delete(`/api/admin/about/member/${data.id}`);
	return response.data;
};

const bulkDeleteTeamMember = async (data) => {
	const response = await axios.post(`/api/admin/about/member`, data);
	return response.data;
};

const aboutMemberService = {
	createTeamMember,
	updateTeamMember,
	getAllTeamMember,
	getTeamMemberById,
	deleteTeamMember,
	bulkDeleteTeamMember
};

export default aboutMemberService;
