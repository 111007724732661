import axios from "../../../../utils/axios";

const createFaq = async (data) => {
  const response = await axios.post(`/api/faq`, data);
  return response.data;
};

const updateFaq = async (data) => {
  const response = await axios.put(`/api/faq/${data._id}`, data);
  return response.data;
};

const getAllFaq = async () => {
  const response = await axios.get(`/api/faq/all`);
  return response.data;
};

const getFAQ = async (data) => {
  const response = await axios.get(`/api/faq/${data.id}`);
  return response.data;
};

const deleteFAQ = async (data) => {
  const response = await axios.delete(`/api/faq/${data.id}`);
  return response.data;
};

const bulkDeleteFAQ = async (data) => {
	const response = await axios.post(`/api/faq/bulkDelete`, data);
	return response.data;
};

const faqService = {
  createFaq,
  updateFaq,
  getAllFaq,
  getFAQ,
  deleteFAQ,
  bulkDeleteFAQ
};

export default faqService;
