import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import healthyContentService from "./healthyContentService";

const initialState = {
	healthyContent: null,
	currentHealthyContent: null,
	isHealthyContentLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	healthyContentMessage: ""
};

export const getHealthyContentById = createAsyncThunk("aboutcontentcard/getById", async (data, thunkAPI) => {
	try {
		return await healthyContentService.getHealthyContentById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllHealthyContent = createAsyncThunk("aboutcontentcard/get/all", async (_, thunkAPI) => {
	try {
		return await healthyContentService.getAllHealthyContent();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addHealthyContent = createAsyncThunk("aboutcontentcard/add", async (data, thunkAPI) => {
	try {
		return await healthyContentService.createHealthyContent(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editHealthyContent = createAsyncThunk("aboutcontentcard/edit", async (data, thunkAPI) => {
	try {
		const newData = await healthyContentService.updateHealthyContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllHealthyContent());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const removeHealthyContent = createAsyncThunk("aboutcontentcard/remove", async (data, thunkAPI) => {
	try {
		const newData = await healthyContentService.deleteHealthyContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllHealthyContent());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});
export const bulkDeleteHealthyContents = createAsyncThunk("aboutcontentcard/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await healthyContentService.bulkDeleteHealthyContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllHealthyContent());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});



const adminSlice = createSlice({
	name: "healthy_content",
	initialState,
	reducers: {
		resetHealthyContent: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addHealthyContent.pending, (state) => {
				state.isHealthyContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addHealthyContent.fulfilled, (state, action) => {
				state.isHealthyContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
			})
			.addCase(addHealthyContent.rejected, (state, action) => {
				state.isHealthyContentLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editHealthyContent.pending, (state) => {
				state.isHealthyContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editHealthyContent.fulfilled, (state, action) => {
				state.isHealthyContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
			})
			.addCase(editHealthyContent.rejected, (state, action) => {
				state.isHealthyContentLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getHealthyContentById.pending, (state) => {
				state.isHealthyContentLoading = true;
			})
			.addCase(getHealthyContentById.fulfilled, (state, action) => {
				state.isHealthyContentLoading = false;
				state.currentHealthyContent = action.payload.data;
			})
			.addCase(getHealthyContentById.rejected, (state, action) => {
				state.isHealthyContentLoading = false;
				state.isError = true;
			})
			.addCase(getAllHealthyContent.pending, (state) => {
				state.isHealthyContentLoading = true;
			})
			.addCase(getAllHealthyContent.fulfilled, (state, action) => {
				state.isHealthyContentLoading = false;
				state.healthyContent = action.payload.data;
				state.successMessage = "";
				state.currentHealthyContent = null
			})
			.addCase(getAllHealthyContent.rejected, (state, action) => {
				state.isHealthyContentLoading = false;
				state.isError = true;
			})
			.addCase(removeHealthyContent.pending, (state) => {
				state.isHealthyContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeHealthyContent.fulfilled, (state, action) => {
				state.isHealthyContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeHealthyContent.rejected, (state, action) => {
				state.isHealthyContentLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHealthyContents.pending, (state) => {
				state.isHealthyContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHealthyContents.fulfilled, (state, action) => {
				state.isHealthyContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteHealthyContents.rejected, (state, action) => {
				state.isHealthyContentLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetHealthyContent } = adminSlice.actions;
export default adminSlice.reducer;
