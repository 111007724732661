const AUTHENTICATION_IMAGE = '/authentication_image.svg';
const LOGO_CIRCLE = '/logo_circle.png';
const LOGO_ALT = '/logo_alt.png';
const LOGO = `https://gutblobdev.blob.core.windows.net/gutlive/0fd50e5a-bb23-4c50-84eb-9a5134264126_logo_17_12_53.png`;
const NO_DATA = '/no_data.svg';


const image_constants = {
	AUTHENTICATION_IMAGE,
	LOGO,
	LOGO_ALT,
	LOGO_CIRCLE,
	NO_DATA,
};

export default image_constants;
