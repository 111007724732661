import axios from "../../../utils/axios";


const getAllDashboardContent = async () => {
	const response = await axios.get(`/api/admin/dashboard/`);
	return response.data;
};

const getOrgnizationAllDashboardContent = async (data) => {
	const response = await axios.get(`/api/admin/dashboard/${data}`);
	return response.data;
};


const dashboardService = {
	getAllDashboardContent,
	getOrgnizationAllDashboardContent
};

export default dashboardService;
