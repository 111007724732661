import axios from "../../../../utils/axios";

const createHealthStep = async (data) => {
	const response = await axios.post(`/api/admin/landing/health-step`, data);
	return response.data;
};

const updateHealthStep = async (data) => {
	const response = await axios.put(`/api/admin/landing/health-step/${data._id}`, data);
	return response.data;
};

const getAllHealthStep = async () => {
	const response = await axios.get(`/api/admin/landing/health-step`);
	return response.data;
};

const getHealthStepById = async (data) => {
	const response = await axios.get(`/api/admin/landing/health-step/${data.id}`);
	return response.data;
};
const deleteHealthStep = async (data) => {
	const response = await axios.delete(`/api/admin/landing/health-step/${data.id}`);
	return response.data;
};

const bulkDeleteHealthStep = async (data) => {
	const response = await axios.post(`/api/admin/landing/health-step`, data);
	return response.data;
};

const healthStepService = {
	createHealthStep,
	updateHealthStep,
	getAllHealthStep,
	getHealthStepById,
	deleteHealthStep,
	bulkDeleteHealthStep
};

export default healthStepService;
