import axios from "../../../utils/axios";

const getAllCustomerReviews = async () => {
	const response = await axios.get(`/api/reviews`);
	return response.data;
};

const deleteCustomerReviews = async (data) => {
	const response = await axios.delete(`/api/reviews/${data.id}`);
	return response.data;
};

const bulkDeleteCustomerReview = async (data) => {
	const response = await axios.post(`/api/reviews/bulkDelete`, data);
	return response.data;
};

const customerReviewsService = {
	getAllCustomerReviews,
	deleteCustomerReviews,
	bulkDeleteCustomerReview
};

export default customerReviewsService;
