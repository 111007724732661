import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import organizationService from "./organizationService";

const initialState = {
	organization: null,
	currentOrganization: null,
	isOrganizationLoading: false,
	orgsuccessMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	organizationMessage: ""
};

export const getOrganizationById = createAsyncThunk("organization/getById", async (data, thunkAPI) => {
	try {
		return await organizationService.getOrganization(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});


export const GetOrgDetails = createAsyncThunk("organization/id", async (data, thunkAPI) => {
	try {
		return await organizationService.OrderOrganization(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllOrganization = createAsyncThunk("organization/get/all", async (_, thunkAPI) => {
	try {
		return await organizationService.getAllOrganization();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addOrganization = createAsyncThunk("organization/add", async (data, thunkAPI) => {
	try {
		return await organizationService.createOrganization(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editOrganization = createAsyncThunk("organization/edit", async (data, thunkAPI) => {
	try {
		const newData = await organizationService.updateOrganization(data);
		if (newData) {
			thunkAPI.dispatch(getAllOrganization());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const editOrganizationStatus = createAsyncThunk("organization/status", async (data, thunkAPI) => {
	try {
		const newData = await organizationService.updateOrganizationStatus(data);
		if (newData) {
			thunkAPI.dispatch(getAllOrganization());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const removeOrganization = createAsyncThunk("organization/remove", async (data, thunkAPI) => {
	try {
		const newData = await organizationService.deleteOrganization(data);

		if (newData) {
			thunkAPI.dispatch(getAllOrganization());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteOrganizations = createAsyncThunk("organization/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await organizationService.bulkDeleteOrganization(data);

		if (newData) {
			thunkAPI.dispatch(getAllOrganization());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "organization",
	initialState,
	reducers: {
		resetOrganization: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addOrganization.pending, (state) => {
				state.isOrganizationLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addOrganization.fulfilled, (state, action) => {
				state.isOrganizationLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.orgsuccessMessage = action.payload.message;
			})
			.addCase(addOrganization.rejected, (state, action) => {
				state.isOrganizationLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editOrganization.pending, (state) => {
				state.isOrganizationLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editOrganization.fulfilled, (state, action) => {
				state.isOrganizationLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.orgsuccessMessage = "Organization Successfully Updated";
			})
			.addCase(editOrganization.rejected, (state, action) => {
				state.isOrganizationLoading = false;
				state.errorMessage = "An Error Occured While Updating an Organization";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getOrganizationById.pending, (state) => {
				state.isOrganizationLoading = true;
				state.orgsuccessMessage = ""
			})
			.addCase(getOrganizationById.fulfilled, (state, action) => {
				state.isOrganizationLoading = false;
				state.currentOrganization = action.payload.organization;
				state.orgsuccessMessage = ""
			})
			.addCase(getOrganizationById.rejected, (state, action) => {
				state.isOrganizationLoading = false;
				state.isError = true;
				state.orgsuccessMessage = ""
			})
			.addCase(getAllOrganization.pending, (state) => {
				state.isOrganizationLoading = true;
				state.orgsuccessMessage = "";
				state.currentOrganization = null;
				state.orgsuccessMessage = ""
			})
			.addCase(getAllOrganization.fulfilled, (state, action) => {
				state.isOrganizationLoading = false;
				state.organization = action.payload.organization;
				state.orgsuccessMessage = "";
				state.currentOrganization = null
			})
			.addCase(getAllOrganization.rejected, (state, action) => {
				state.isOrganizationLoading = false;
				state.isError = true;
				state.orgsuccessMessage = "";
				state.currentOrganization = null
			})
			.addCase(editOrganizationStatus.pending, (state) => {
				state.isOrganizationLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editOrganizationStatus.fulfilled, (state, action) => {
				state.isOrganizationLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.orgsuccessMessage = "Organization Successfully Updated";
			})
			.addCase(editOrganizationStatus.rejected, (state, action) => {
				state.isOrganizationLoading = false;
				state.errorMessage = "An Error Occured While Updating an Organization";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(removeOrganization.pending, (state) => {
				state.isOrganizationLoading = true;
			})
			.addCase(removeOrganization.fulfilled, (state, action) => {
				state.isOrganizationLoading = false;
				state.isSuccess = action.payload.success;
				state.isError = false;
				state.orgsuccessMessage = action.payload.message;
			})
			.addCase(removeOrganization.rejected, (state, action) => {
				state.isOrganizationLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteOrganizations.pending, (state) => {
				state.isOrganizationLoading = true;
			})
			.addCase(bulkDeleteOrganizations.fulfilled, (state, action) => {
				state.isOrganizationLoading = false;
				state.isError = false;
				state.orgsuccessMessage = action.payload.message;
			})
			.addCase(bulkDeleteOrganizations.rejected, (state, action) => {
				state.isOrganizationLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetOrganization } = adminSlice.actions;
export default adminSlice.reducer;
