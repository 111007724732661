import axios from "../../../utils/axios";

const createContentManagement = async (data) => {
	const response = await axios.post(`/api/admin/contentmanagement/`, data);
	return response.data;
};

const updateContentManagement = async (data) => {
	const response = await axios.put(`/api/admin/contentmanagement/${data._id}`, data);
	return response.data;
};

const getAllContentManagement = async () => {
	const response = await axios.get(`/api/admin/contentmanagement/`);
    console.log(response.data);
	return response.data;
};

const getContentManagement = async (data) => {
	const response = await axios.get(`/api/admin/contentmanagement/${data.id}`);
	return response.data;
};

const deleteContentManagement = async (data) => {
	const response = await axios.delete(`/api/admin/contentmanagement/${data.id}`);
	return response.data;
};

const bulkDeleteContentManagement = async (data) => {
	const response = await axios.post(`/api/admin/contentmanagement/bulkDelete`, data);
	return response.data;
};

const contentManagementService = {
	createContentManagement,
	updateContentManagement,
	getAllContentManagement,
	getContentManagement,
	deleteContentManagement,
	bulkDeleteContentManagement
};

export default contentManagementService;
