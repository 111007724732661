import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import benefitsService from "./benfitsService";

const initialState = {
	benefits: null,
	currentBenefits: null,
	isBenefitsLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	benefitsMessage: ""
};

export const getBenefitsById = createAsyncThunk("benefits/getById", async (data, thunkAPI) => {
	try {
		return await benefitsService.getBenefitsById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllBenefits = createAsyncThunk("benefits/get/all", async (_, thunkAPI) => {
	try {
		return await benefitsService.getAllBenefits();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const addBenefits = createAsyncThunk("benefits/add", async (data, thunkAPI) => {
	try {
		return await benefitsService.createBenefits(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editBenefits = createAsyncThunk("benefits/edit", async (data, thunkAPI) => {
	try {
		const newData = await benefitsService.updateBenefits(data);
		if (newData) {
			thunkAPI.dispatch(getAllBenefits());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeBenefit = createAsyncThunk("benefits/remove", async (data, thunkAPI) => {
	try {
		const newData = await benefitsService.deleteBenefit(data);
		if (newData) {
			thunkAPI.dispatch(getAllBenefits());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteBenefits = createAsyncThunk("benefits/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await benefitsService.bulkDeleteBenefit(data);
		if (newData) {
			thunkAPI.dispatch(getAllBenefits());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "benefits",
	initialState,
	reducers: {
		resetBenefits: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addBenefits.pending, (state) => {
				state.isBenefitsLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addBenefits.fulfilled, (state, action) => {
				state.isBenefitsLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(addBenefits.rejected, (state, action) => {
				state.isBenefitsLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editBenefits.pending, (state) => {
				state.isBenefitsLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editBenefits.fulfilled, (state, action) => {
				state.isBenefitsLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(editBenefits.rejected, (state, action) => {
				state.isBenefitsLoading = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getBenefitsById.pending, (state) => {
				state.isBenefitsLoading = true;
			})
			.addCase(getBenefitsById.fulfilled, (state, action) => {
				state.isBenefitsLoading = false;
				state.currentBenefits = action.payload.benefit;
			})
			.addCase(getBenefitsById.rejected, (state, action) => {
				state.isBenefitsLoading = false;
				state.isError = true;
			})
			.addCase(getAllBenefits.pending, (state) => {
				state.isBenefitsLoading = true;
			})
			.addCase(getAllBenefits.fulfilled, (state, action) => {
				state.isBenefitsLoading = false;
				state.benefits = action.payload.benefits;
				state.currentBenefits = null;
				state.successMessage = ""
			})
			.addCase(getAllBenefits.rejected, (state, action) => {
				state.isBenefitsLoading = false;
				state.isError = true;
			})
			.addCase(removeBenefit.pending, (state) => {
				state.isBenefitsLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeBenefit.fulfilled, (state, action) => {
				state.isBenefitsLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeBenefit.rejected, (state, action) => {
				state.isBenefitsLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteBenefits.pending, (state) => {
				state.isBenefitsLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteBenefits.fulfilled, (state, action) => {
				state.isBenefitsLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteBenefits.rejected, (state, action) => {
				state.isBenefitsLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetBenefits } = adminSlice.actions;
export default adminSlice.reducer;
