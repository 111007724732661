import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import helpContentService from "./helpContentService";

const initialState = {
	helpContent: null,
	currentHelpContent: null,
	isLandingHelpContentLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	aboutSliderCardMessage: ""
};

export const getHelpContentById = createAsyncThunk("helpcontent/getById", async (data, thunkAPI) => {
	try {
		return await helpContentService.getHelpContentById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllHelpContent = createAsyncThunk("helpcontent/get/all", async (_, thunkAPI) => {
	try {
		return await helpContentService.getAllHelpContent();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addHelpContent = createAsyncThunk("helpcontent/add", async (data, thunkAPI) => {
	try {
		return await helpContentService.createHelpContent(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editHelpContent = createAsyncThunk("helpcontent/edit", async (data, thunkAPI) => {
	try {
		const newData = await helpContentService.updateHelpContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllHelpContent());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeHelpContent = createAsyncThunk("helpcontent/remove", async (data, thunkAPI) => {
	try {
		const newData = await helpContentService.deleteHelpContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllHelpContent());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteHelpContents = createAsyncThunk("helpcontent/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await helpContentService.bulkDeleteHelpContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllHelpContent());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

const adminSlice = createSlice({
	name: "help_content",
	initialState,
	reducers: {
		resetHelpContent: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addHelpContent.pending, (state) => {
				state.isLandingHelpContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addHelpContent.fulfilled, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addHelpContent.rejected, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editHelpContent.pending, (state) => {
				state.isLandingHelpContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editHelpContent.fulfilled, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data User Successfully Updated";
			})
			.addCase(editHelpContent.rejected, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.errorMessage = "An Error Occured While Updating.";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getHelpContentById.pending, (state) => {
				state.isLandingHelpContentLoading = true;
			})
			.addCase(getHelpContentById.fulfilled, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.currentHelpContent = action.payload.data;
			})
			.addCase(getHelpContentById.rejected, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.isError = true;
			})
			.addCase(getAllHelpContent.pending, (state) => {
				state.isLandingHelpContentLoading = true;
			})
			.addCase(getAllHelpContent.fulfilled, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.helpContent = action.payload.data;
				state.currentHelpContent = null;
				state.successMessage = ""
			})
			.addCase(getAllHelpContent.rejected, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.isError = true;
			})
			.addCase(removeHelpContent.pending, (state) => {
				state.isLandingHelpContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeHelpContent.fulfilled, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeHelpContent.rejected, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHelpContents.pending, (state) => {
				state.isLandingHelpContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteHelpContents.fulfilled, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteHelpContents.rejected, (state, action) => {
				state.isLandingHelpContentLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetHelpContent } = adminSlice.actions;
export default adminSlice.reducer;
