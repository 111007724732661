import axios from "../../../utils/axios";

const createProduct = async (data) => {
	const response = await axios.get(`/api/admin/products`, data);
	return response.data.products;
};

const getProducts = async () => {
	const response = await axios.get(`/api/admin/products`);
	return response.data.products;
};

const getProductById = async (data) => {
	const response = await axios.get(`/api/admin/products/${data.id}`);
	return response.data.product;
};

const removeProduct = async (data) => {
	const response = await axios.delete(`/api/admin/products/${data.id}`);
	return response.data;
};

const bulkDeleteProduct = async (data) => {
	const response = await axios.post(`/api/admin/products/bulkDelete`, data);
	return response.data;
};


const productService = {
	getProducts,
	getProductById,
	createProduct,
	removeProduct,
	bulkDeleteProduct
};

export default productService;
