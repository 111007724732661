import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import kitContentService from "./kitContentService";

const initialState = {
	kitContent: null,
	currentKitContent: null,
	isKitContentLoading: false,
	successMessage: "",
	errorMessage: "",
	isError: false,
	isSuccess: false,
	kitContentMessage: ""
};

export const getKitContentById = createAsyncThunk("kitcontent/getById", async (data, thunkAPI) => {
	try {
		return await kitContentService.getKitContentById(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const getAllKitContent = createAsyncThunk("kitcontent/get/all", async (_, thunkAPI) => {
	try {
		return await kitContentService.getAllKitContent();
	} catch (error) {
		const message = (error && error.response.data) || error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const addKitContent = createAsyncThunk("kitcontent/add", async (data, thunkAPI) => {
	try {
		return await kitContentService.createKitContent(data);
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const editKitContent = createAsyncThunk("kitcontent/edit", async (data, thunkAPI) => {
	try {
		const newData = await kitContentService.updateKitContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllKitContent());
			return newData;
		} else {
			const message = "Something Went Wrong";

			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const removeKitContent = createAsyncThunk("kitcontent/remove", async (data, thunkAPI) => {
	try {
		const newData = await kitContentService.deleteKitContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllKitContent());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const bulkDeleteKitContents = createAsyncThunk("kitcontent/bulkRemove", async (data, thunkAPI) => {
	try {
		const newData = await kitContentService.bulkDeleteKitContent(data);
		if (newData) {
			thunkAPI.dispatch(getAllKitContent());
			return newData;
		} else {
			const message = "Something Went Wrong";
			return thunkAPI.rejectWithValue(message);
		}
	} catch (error) {
		const message = (error && error.response.data) || error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


const adminSlice = createSlice({
	name: "kit_content",
	initialState,
	reducers: {
		resetKitContent: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(addKitContent.pending, (state) => {
				state.KitContent = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(addKitContent.fulfilled, (state, action) => {
				state.KitContent = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Created";
			})
			.addCase(addKitContent.rejected, (state, action) => {
				state.KitContent = false;
				state.errorMessage = "An Error Occured While Creating an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(editKitContent.pending, (state) => {
				state.KitContent = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(editKitContent.fulfilled, (state, action) => {
				state.KitContent = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = "Data Successfully Updated";
			})
			.addCase(editKitContent.rejected, (state, action) => {
				state.KitContent = false;
				state.errorMessage = "An Error Occured While Updating an Payment Gateway";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getKitContentById.pending, (state) => {
				state.KitContent = true;
			})
			.addCase(getKitContentById.fulfilled, (state, action) => {
				state.KitContent = false;
				state.currentKitContent = action.payload.data;
			})
			.addCase(getKitContentById.rejected, (state, action) => {
				state.KitContent = false;
				state.isError = true;
			})
			.addCase(getAllKitContent.pending, (state) => {
				state.KitContent = true;
			})
			.addCase(getAllKitContent.fulfilled, (state, action) => {
				state.KitContent = false;
				state.kitContent = action.payload.data;
				state.currentKitContent = null;
				state.successMessage = ""
			})
			.addCase(getAllKitContent.rejected, (state, action) => {
				state.KitContent = false;
				state.isError = true;
			})
			.addCase(removeKitContent.pending, (state) => {
				state.isKitContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(removeKitContent.fulfilled, (state, action) => {
				state.isKitContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(removeKitContent.rejected, (state, action) => {
				state.isKitContentLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteKitContents.pending, (state) => {
				state.isKitContentLoading = true;
				state.isError = false;
				state.isSuccess = false;
			})
			.addCase(bulkDeleteKitContents.fulfilled, (state, action) => {
				state.isKitContentLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.successMessage = action.payload.message;
			})
			.addCase(bulkDeleteKitContents.rejected, (state, action) => {
				state.isKitContentLoading = false;
				state.errorMessage = "An Error Occured While Removing an Admin";
				state.isError = true;
				state.isSuccess = false;
			});
	},
});

export const { resetKitContent } = adminSlice.actions;
export default adminSlice.reducer;
