import axios from "../../../utils/axios";

const createHomeContentCard = async (data) => {
	const response = await axios.post(`/api/admin/homecontentcard`, data);
	return response.data;
};

const updateHomeContentCard = async (data) => {
	const response = await axios.put(`/api/admin/homecontentcard/${data._id}`, data);
	return response.data;
};

const getAllHomeContentCard = async () => {
	const response = await axios.get(`/api/admin/homecontentcard`);
    console.log(response.data);
	return response.data;
};

const getHomeContentCardById = async (data) => {
	const response = await axios.get(`/api/admin/homecontentcard/${data.id}`);
	return response.data;
};

const deleteHomeContentCard = async (data) => {
	const response = await axios.delete(`/api/admin/homecontentcard/${data.id}`);
	return response.data;
};

const bulkDeleteHomeContentCard = async (data) => {
	const response = await axios.post(`/api/admin/homecontentcard/bulkDelete`, data);
	return response.data;
};

const homeContentCardService = {
	createHomeContentCard,
	updateHomeContentCard,
	getAllHomeContentCard,
	getHomeContentCardById,
	deleteHomeContentCard,
	bulkDeleteHomeContentCard
};

export default homeContentCardService;
