import axios from "../../../../utils/axios";

const createBODMember = async (data) => {
	const response = await axios.post(`/api/admin/about/bod-member`, data);
	return response.data;
};

const updateBODMember = async (data) => {
	const response = await axios.put(`/api/admin/about/bod-member/${data._id}`, data);
	return response.data;
};

const getAllBODMember = async () => {
	const response = await axios.get(`/api/admin/about/bod-member`);
	return response.data;
};

const getBODMemberById = async (data) => {
	const response = await axios.get(`/api/admin/about/bod-member/${data.id}`);
	return response.data;
};
const deleteBODMember = async (data) => {
	const response = await axios.delete(`/api/admin/about/bod-member/${data.id}`);
	return response.data;
};
const bulkDeleteBODMember  = async (data) => {
	const response = await axios.post(`/api/admin/about/bod/bulkDelete`, data);
	return response.data;
};

const aboutExecutiveMemberService = {
	createBODMember,
	updateBODMember,
	getAllBODMember,
	getBODMemberById,
	deleteBODMember,
	bulkDeleteBODMember
};

export default aboutExecutiveMemberService;
